import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimWizard from './SimWizard.jsx';
import TakeSimDetails from './TakeSimDetails.jsx';
import TakeSimResult from './TakeSimResult.jsx';
import {
  simWizard,
} from './SimWizard';
import config from '../../../config';

const TakeSim = ({ simProductType }) => {
  const useStyles = makeStyles((theme) => simWizard(theme));
  const classes = useStyles();
  const [viewState, setViewState] = React.useState(false);
  const [userResult, setUserResult] = React.useState({});

  const handleViewState = (view) => {
    setViewState(view);
  };

  const location = useLocation();

  if (location.state.viewResult === true) {
    localStorage.setItem('sims_id', localStorage.getItem('sim_id'));
    return <TakeSimResult isPost={false} config={config} userResult={userResult} setUserResult={setUserResult}/>;
  }

  if (!viewState) {
    return (
      <>
        {simProductType.includes('Internal Data') ? (
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TakeSimDetails userResponse={userResult}/>
            </Grid>
            <Grid item xs={4} className={classes.takesimGrid}>
              <SimWizard handleViewState={handleViewState} config={config} userResult={userResult} setUserResult={setUserResult}/>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={8} style={{ margin: 'auto' }}>
              <SimWizard handleViewState={handleViewState} config={config} userResult={userResult} setUserResult={setUserResult}/>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  if (viewState === true) {
    return <TakeSimResult isPost config={config} userResult={userResult} setUserResult={setUserResult}/>;
  }
  return (
    <div>
      <CircularProgress color="secondary" />
    </div>
  );
};

TakeSim.propTypes = {
  simProductType: PropTypes.string,
};
const mapStateToProps = (state, props) => ({
  simProductType: state.sim.currentSimProductType,
});

export default connect(mapStateToProps)(TakeSim);
