import React, {useEffect} from 'react';
import {
    Grid,
    Typography,
    Divider,
    Paper,
    Box, Tooltip, Button,
} from '@material-ui/core';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from './TakeSimResult';
import ProfilePic from '../../../assets/images/profile_pic.png';
import moment from "moment";
import { useLocation } from "react-router-dom";
import loadingIcon from "../../../assets/images/icons/loading-1.gif";
import {
    userFinalScoreResponse,
  } from "../../../redux/sims/actions";
import {formatElapsedTime} from "../../../utils/time";
import debug from "../../../utils/debug";

const ResultScreenAccuracies = ({
    userResult,
    setUserResult,
    user,
    elapsedTime,
    isPost,
    userFinalScoreResponse,
    caseValuesArray,
    userResponse,
    simTitle,
    simProductType,
    config,
    snowflyId,
    setSnowflyId,
    sendAudioToSnowfly,
    sendingToSnowfly,
}) => {
    const classes = useStyles();
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    let order = urlParams.get("order"); // This is the level order, zero based (order=4 means level 5)
    const assignment_id = urlParams.get("assignment_id");

    useEffect(() => {
        if (userResult && userResult.scripts && userResult.scripts.length) {
            setUserResult(userResult);
            debug("userResult = %o", userResult);
        }
    }, [userResult, setUserResult, snowflyId, setSnowflyId]);

    const callApiInInterval = () => {
        const payload = {
            ...caseValuesArray,
            isPost: true,
            ...(assignment_id ? { assignment_id } : {}),
            elapsedTime: elapsedTime,
        };
        if (isPost !== false) {
            userFinalScoreResponse(userResponse._id, payload);
        }
    };

    function CircularProgressWithLabel(props) {
        const allUserScriptsLoaded = userResult.agent_scripts &&
          userResult.agent_scripts.every((ele) => ele.status === "Completed");
        if (userResult.agent_scripts && !allUserScriptsLoaded) {
            setTimeout(() => {
                callApiInInterval();
            }, 5000);
        }
        return (
            <div>
                <Box position="relative" display="inline-flex" className={classes.prog}>
                  <CircularProgress
                    variant="determinate"
                    size="6rem"
                    thickness={2}
                    {...props}
                    className={classes.circularProg}
                  />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            className={classes.progTypo}
                        >
                        {userResult &&
                            Object.keys(userResult).length !== 0 &&
                            userResult.agent_scripts.length &&
                            userResult.agent_scripts[0].audio_url !== "" ? (
                            allUserScriptsLoaded ? (
                            `${userResult.keyword_accuracy}%`
                        ) : (
                            <img height="40px" src={loadingIcon} alt="loading..." />
                        )
                        ) : (
                            "0%"
                        )}
                        </Typography>
                    </Box>
                </Box>
            </div>
        );
    }

    console.log('ScreenAccuracies userResult = %o', userResult);

    return (
        <Grid container className={classes.girdContainer} item md={3}>
            <div className={classes.userDetails}>
                <div className={classes.userImage}>
                    <img
                        src={userResult.profile_pic_url
                            ? userResult.profile_pic_url
                            : ProfilePic
                        }
                        alt="userprofile"
                        className={classes.userImage}
                    />
                </div>
                <div>
                    <Typography className={classes.name}>
                        {userResult?.user?.first_name
                            ? `${userResult?.user?.first_name} ${userResult?.user?.last_name}`
                            : `${user.first_name} ${user.last_name}`}
                        </Typography>
                        <span className={classes.simTitle}>{simTitle ? simTitle : userResult.simTitle}</span>
                        <br/>
                        <Typography variant="caption" className={classes.details}>
                        {userResult ? (
                          moment(userResult.completed_on).format("MM-DD-YYYY h:mm A")
                        ) : (
                            <span>Loading...</span>
                        )}
                            <br />
                            Mode:{" "}
                        {userResult?.mode?.charAt(0).toUpperCase() + userResult?.mode?.slice(1)}
                    </Typography>
                </div>
                <Divider variant="middle" className={classes.divider} />

                <div className={classes.select}>
                  <Typography className={classes.details}>
                    Level {parseInt(order)+1} : {userResult?.scripts?.length &&
                      userResult?.scripts[0]?.sim_level_id?.name}
                  </Typography>
                </div>
                <div className={classes.contentDiv}>
                    <div className={classes.subDiv}>
                        <Typography className={classes.details}>Total Time</Typography>
                        <Typography className={classes.details}>:</Typography>
                        <Typography className={classes.details}>
                            {formatElapsedTime(userResult?.totalSeconds ?? 0)}
                        </Typography>
                    </div>
                    <Divider variant="middle" className={classes.dividerContent}/>
                    <div className={classes.subDiv}>
                        <Typography className={classes.details}>Wrap Up Time</Typography>
                        <Typography className={classes.details}>:</Typography>
                        <Typography className={classes.details}>
                            {userResult?.wrapSeconds ? formatElapsedTime(userResult?.wrapSeconds ?? 0) : 'N/A'}
                        </Typography>
                    </div>
                    <Divider variant="middle" className={classes.dividerContent}/>
                    <div className={classes.subDiv}>
                        <Typography className={classes.details}>Snowfly ID</Typography>
                        <Typography className={classes.details}>:</Typography>
                        <Typography className={classes.details}>
                            {(
                                config.snowFlyAudioMerge &&
                                userResult &&
                                ((userResult.level && userResult.level.send_to_snowfly) || userResult.send_to_snowfly)
                            ) ? (
                                !(userResult.snowfly_id || snowflyId) ? (
                                    <Tooltip
                                        title='Send the current SIM level audio to Snowfly'
                                        placement="bottom"
                                    >
                                    <span>
                                      <Button
                                          variant="contained"
                                          onClick={sendAudioToSnowfly}
                                          disabled={sendingToSnowfly}
                                          style={{
                                              display: (userResult?.level?.snowfly_id || userResult?.snowfly_id) ? 'none' : 'default',
                                              fontSize: '0.85em',
                                              fontWeight: '400',
                                              padding: '0.5em 0.25em',
                                              lineHeight: '1em'
                                          }}
                                      >
                                        {sendingToSnowfly && (
                                            <>
                                                <CircularProgress color="primary" size={"2em"}/>
                                                <span style={{marginLeft: '1em', color: 'white'}}>Sending...</span>
                                            </>
                                        )}
                                          Send to Snowfly
                                      </Button>
                                    </span>
                                    </Tooltip>
                                ) : (
                                    <span>{snowflyId}</span>
                                )
                            ) : (
                                <span>--------</span>
                            )}
                        </Typography>
                    </div>
                </div>
            </div>

            <Paper className={classes.accuracyPaper}>
                {(loggedUser.role).toLowerCase() === 'agent' ?
                    userResult?.scripts?.length && userResult?.scripts[0]?.sim_level_id?.keyword_score ?
                        <div className={classes.accuracySection} style={{lineHeight: '0.5rem'}}>
                            <Typography className={classes.keyTypo}>
                                Keyword Accuracy
                            </Typography>
                            {
                                userResult?.keyword_accuracy ?
                                    (order === '0' || order === '1') ?
                                        <div className={classes.keywordAccuracy}>
                                            <span className={classes.blackColor}>N/A</span>
                                        </div> :
                                        <CircularProgressWithLabel
                                            value={userResult?.keyword_accuracy ?? 0}
                                        /> :
                                        <div className={classes.keywordAccuracy}>
                                            <span className={classes.blackColor}>
                                                {(order === '0' || order === '1') ? 'N/A' : '0%'}
                                            </span>
                                        </div>
                            }
                        </div> :''
                  :
                  userResult?.scripts?.length && userResult?.scripts[0]?.sim_level_id?.keyword_score ?
                    <div className={classes.accuracySection} style={{lineHeight: '0.5rem'}}>
                      <Typography className={classes.keyTypo}>
                        Keyword Accuracy
                      </Typography>
                      {
                            userResult?.keyword_accuracy ?
                                (order === '0' || order === '1') ?
                                    <div className={classes.keywordAccuracy}>
                                        <span className={classes.blackColor}>N/A</span>
                                    </div> :
                                <CircularProgressWithLabel
                                    value={userResult?.keyword_accuracy ?? 0}
                                />
                            :
                            <div className={classes.keywordAccuracy}>
                                <span className={classes.blackColor}>{(order === '0' || order === '1') ? 'N/A' : '0%'}</span>
                            </div>
                      }
                    </div>
                  : ''
                }
                {
                    !simProductType.includes('Voice Only') &&
                    <div>
                        <Typography className={classes.accuracySection}>
                            Typing Accuracy
                            <br />
                            {userResult.auto_populate ?
                            <span className={classes.numberColor}>N/A</span> :
                            <span className={classes.numbers}>
                                <span className={classes.numberColor}>
                                    <span className={classes.blackColor}>
                                        {userResult.correct_entries}
                                    </span>
                                    {" "} of {userResult.total_entries}
                                </span>
                                <br/>
                                (Correct Entries)
                            </span>
                        }
                        </Typography>
                    </div>
                }
                {
                    !simProductType.includes('Voice Only') &&
                    <div>
                        <Typography className={classes.accuracySection}>
                            Data Selection Accuracy
                            <br />
                            {userResult.auto_populate ?
                                <span className={classes.numberColor}>N/A</span> :
                                <span className={classes.numbers}>
                                    <span className={classes.numberColor}>
                                        <span className={classes.blackColor}>
                                            {userResult.correct_selections}
                                        </span>
                                        {" "} of {userResult.total_selections}
                                    </span>
                                    <br />
                                    (Selected Accurately)
                                </span>
                            }
                        </Typography>
                    </div>
                }
                {
                    !simProductType.includes('Voice Only') &&
                    <div>
                        <Typography className={classes.accuracySection}>
                            Overall Data Accuracy
                            <br />
                            { userResult.auto_populate ?
                                <span className={classes.numberColor}>N/A</span> :
                                    <span className={classes.numbers}>
                                        <span className={classes.numberColor}>
                                            <span className={classes.blackColor}>
                                                {userResult.overall_percentage} %
                                            </span>
                                        </span>
                                    </span>
                            }
                        </Typography>
                   </div>
                }
            </Paper>
        </Grid>
    );
};

ResultScreenAccuracies.propTypes = {
    elapsedTime: PropTypes.number, //string,
    totalSeconds: PropTypes.number,
    userFinalScoreResponse: PropTypes.func,
    isPost: PropTypes.bool,
    caseValuesArray: PropTypes.object,
    userResponse: PropTypes.object,
    simProductType: PropTypes.string
};

const mapStateToProps = (state) => ({
    user: state.login.user,
    elapsedTime: state.sim.elapsedTime,
    totalSeconds: state.sim.totalSeconds,
    caseValuesArray: state.sim.caseValuesArray,
    userResponse: state.sim.userResponse,
    simTitle: state.sim.simTitle,
    simProductType: state.sim.currentSimProductType,
});

const mapDispatchToProps = {
    userFinalScoreResponse: userFinalScoreResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultScreenAccuracies);

