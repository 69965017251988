import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import SplitButtonStyle from './SplitButton';
import debug from "../../utils/debug";

/*
    TODO - Add a property that allows creating a custom Popper (eg. pass a custom Popup menu)
 */
/**
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export default function SplitButton(props) {
    const useStyles = makeStyles((theme) => SplitButtonStyle(theme));
    const classes = useStyles();

    const options = props?.options,
        onItemSelected = props?.onItemSelected,
        mainButtonIcon = props?.mainButtonIcon,
        onMainButtonClick = props?.onMainButtonClick,
        mainButtonText = props?.mainButtonText,
        extraStyles = props?.extraStyles;

    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [currentOption, setCurrentOption] = React.useState(0);
    const [itemClicked, setItemClicked] = React.useState(-1);

    useEffect(
        () => {
            if (open && (itemClicked > -1)) {
                setOpen(false);
                setCurrentOption(selectedIndex);
                onItemSelected(selectedIndex, options[selectedIndex]);

            }
        },
        [selectedIndex, options, onItemSelected, open, itemClicked, setOpen]
    );

    const handleMenuItemClick = (event, index) => {
        setItemClicked(index);
        if (index !== selectedIndex) {
            setSelectedIndex(index);
        } else {
            setOpen(false);
            onItemSelected(selectedIndex, options[selectedIndex]);
        }
    };

    const handleToggle = () => {
        setItemClicked(-1);
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    debug('Options = %o', options);

    return (
        <span className={classes.root} style={extraStyles}>
            <ButtonGroup variant="contained"
                color="primary"
                ref={anchorRef}
                aria-label="split button"
                className={classes.splitButton}
                style={{flexWrap: 'nowrap'}}
            >
                <Button variant="contained"
                    className={classes.button}
                    size="small"
                    onClick={onMainButtonClick}
                    startIcon={mainButtonIcon}
                >
                    {mainButtonText}
                </Button>
                {options && options.length ? (
                <Button variant="contained"
                    className={classes.dropdown}
                    size="small"
                    onClick={handleToggle}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                >
                    <ArrowDropDownIcon />
                </Button>
                ): null }
            </ButtonGroup>
            {options && options.length ? (
            <Popper open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="left"
                className={classes.popper}
                disablePortal={true}
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: "scrollParent",
                    },
                    arrow: {
                        enabled: false,
                    },
                }}
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                                <MenuItem
                                    key={option.url}
                                    disabled={option.disabled ?? false}
                                    selected={currentOption === index}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                    {option.callback ? option.callback(option, option.data ?? null) : option.text}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
            ) : null}
        </span>
    );
}