
export const SplitButton = (theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    splitButton: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '0',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'stretch',

        '&:hover button': {
            background: '#D5D5D5',
            borderColor: '#D5D5D5 !important',
        },
        '&:hover dropdown': {
            background: '#D5D5D5',
            borderColor: '#D5D5D5 !important',
        },
    },
    button: {
        float: 'none',
        display: 'inline-flex',
        color: 'white',
        background: '#264173',
        borderColor: '#264173 !important',
        margin: 0,
        flex: '1 1 auto',
    },
    dropdown: {
        float: 'none',
        display: 'inline-flex',
        color: 'white',
        background: '#264173',
        borderColor: '#264173 !important',
        maxWidth: '1.25em',
        margin: 0,
        flex: '0',
    },
    popper: {
        position: 'absolute',
        top: 'calc(100% + 0.25em) !important',
        left: 'calc(100% - 23ch) !important',
        transform: 'none !important',
    },
});

export default SplitButton;