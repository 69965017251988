import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    FormGroup,
    FormControlLabel,
    //Checkbox,
    FormControl,
    FormLabel,
    RadioGroup,
    Typography,
    Radio,
    Button,
    DialogActions,
    Grid,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { enableVideoRecord } from '../../../redux/sims/actions';

const ScreenRecording = ({
    capture,
    setRecPerDialog,
    dialogClose,
    recPerDialog,
    recPer,
    setRecPer,
    totalScreens,
    setTotalScreens,
    classes,
    enableVideoRecord
}) => {
    const [recordingEnabled, setRecordingEnabled] = React.useState(false);

    const enableRecording = () => {
        setRecordingEnabled(true);
    };

    const handleRecording = () => {
        enableVideoRecord(true);
        setRecPerDialog(false);
        setRecPer(true);
        capture();
    };

    return (
        <Dialog onClose={dialogClose} open={recPerDialog}>
            <DialogTitle>Screen Recording</DialogTitle>
            <DialogContent>
                {!recordingEnabled && (
                    <div>
                        <p>Do you want to start screen recording for this particular SIM?</p>
                        <FormGroup>
                            <Grid container justifyContent="space-around" spacing={2}>
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={enableRecording}
                                    >
                                        Yes
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        className={classes.cancel}
                                        onClick={dialogClose}
                                    >
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </div>
                )}
                {recordingEnabled && (
                    <div>
                        <FormControl>
                            <FormLabel component="legend">
                                How many screens do you want to record simultaneously?
                            </FormLabel>
                            <RadioGroup
                                aria-label="total screens recording"
                                value={totalScreens}
                                onChange={(e) => setTotalScreens(e.target.value)}
                                style={{marginTop: '1rem'}}
                            >
                                <Grid container spacing={2}>
                                    <FormControlLabel value="1" control={<Radio/>} label="1"/>
                                    <FormControlLabel value="2" control={<Radio />} label="2" />
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                        <Typography className={classes.warnTypo}>
                            <InfoOutlinedIcon className={classes.info} />
                            {' '}
                            The screen
                            recording cannot be disabled in between recording of the video
                        </Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                {recordingEnabled && (
                    <>
                        <Button
                            style={{ float: 'right', marginRight: '5px' }}
                            variant="outlined"
                            color="primary"
                            size="small"
                            className={classes.cancel}
                            onClick={dialogClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ float: 'right', marginRight: '5px' }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleRecording}
                        >
                            Proceed
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );

    /*return (
        <Dialog onClose={dialogClose} open={recPerDialog}>
            <DialogTitle>Screen Recording</DialogTitle>
            <DialogContent style={{ marginTop: '-19px' }}>
                <FormGroup style={{ marginBottom: '6px' }}>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={recPer}
                                onChange={(e) => setRecPer(e.target.checked)}
                            />
                        )}
                        label="Do you want to start screen recording for this particular sim"
                    />
                </FormGroup>
                {recPer && (
                    <div>
                        <FormControl>
                            <FormLabel component="legend">
                                How many screens do you want to record simultaneously
                            </FormLabel>
                            <RadioGroup
                                aria-label="total screens recording"
                                value={totalScreens}
                                onChange={(e) => setTotalScreens(e.target.value)}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="1" />
                                <FormControlLabel style={{ marginTop: '-13px' }} value="2"  control={<Radio />} label="2" />
                            </RadioGroup>
                        </FormControl>
                        <Typography className={classes.warnTypo}>
                            <InfoOutlinedIcon className={classes.info} />
                            {' '}
                            The screen
                            recording cannot be disabled in between recording of the video
                        </Typography>
                    </div>
                )}
                {recPer && (
                    <Button
                        style={{ float: 'right', marginRight: '5px' }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleRecording}
                    >
                        Start Recording
                    </Button>
                )}
                <Button
                    style={{ float: 'right', marginRight: '5px' }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={dialogClose}
                    className={classes.cancel}
                >
                    Cancel
                </Button>
            </DialogContent>

        </Dialog>

    );*/
};
ScreenRecording.propTypes = {
    capture: PropTypes.func,
    totalScreens: PropTypes.any,
    setRecPerDialog: PropTypes.func,
    setTotalScreens: PropTypes.func,
    classes: PropTypes.object,
    setRecPer: PropTypes.func,
    dialogClose: PropTypes.func,
    recPerDialog: PropTypes.bool,
    recPer: PropTypes.bool,
    enableVideoRecord: PropTypes.func,
};

const mapStateToProps = (state) => ({
    levelClips: state.sim.levelClips,
    levelClipsTemp: state.sim.levelClipsTemp,
    levels: state.sim.stats.result.levels,
    simId: state.sim.stats.result.sim_id,
    userResponse: state.sim.userResponse,
    simTitle: state.sim.simTitle,
});

const mapDispatchToProps = {
    enableVideoRecord,
};

// connect Sims component to redux store

export default connect(mapStateToProps, mapDispatchToProps)(ScreenRecording);
