import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import AddIcon from "@material-ui/icons/Add";
import {
    // Checkbox,
    // Dialog,
    // DialogContent,
    // DialogTitle,
    Modal,
    // Table,
    // TableBody,
    // TableCell,
    // TableRow,
    TextareaAutosize,
    Tooltip,
    // Typography,
    IconButton,
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    addAssignmentStatus,
    audioPlay,
    addAssessor,
    currentStatus,
} from "../../redux/sims/actions";
import { Alert } from "@material-ui/lab";
import StopIcon from "@material-ui/icons/Stop";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DashboardPermissions from '../../permissions/dashboard';
import { permissionCheck } from '../../utils/permissionCheck';
import VisibilityIcon from "@material-ui/icons/Visibility";
import ResultScreenStyle from './ResultScreen';

const ResultScreen = ({
    userResult,
    addAssignmentStatus,
    takeSimFromAssignmentSection,
    audioPlay,
    audioPlaying,
    addAssessor,
    currentStatus,
    showReviwerButtons,
    screenRecorderBlob,
    allAudioProcessed,
}) => {
    const getModalStyle = () => {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    };

    const user = JSON.parse(localStorage.getItem('user'));
    const useStyles = makeStyles((theme) => ResultScreenStyle(theme));
    const classes = useStyles();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const reviewer_come = urlParams.get("reviewer");
    const [openModal, setOpenModal] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalBody, setModalBody] = React.useState("");
    const [reviewButtonsDisable, setReviewButtonsDisable] = React.useState(false);
    const [showModalButton, setShowModalButton] = React.useState(false);
    const [redirectToDashboard, setRedirectToDashboard] = React.useState(false);
    const [
        showReviewerSubmitButton,
        setShowReviewerSubmitButton,
    ] = React.useState(false);
    const [reviewerStatus, setReviewerStatus] = React.useState("");
    const [alertMesage, setAlertMesage] = React.useState("");
    const [openAlert, setOpenAlert] = React.useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const reviewInputRef = React.useRef(null);
    const download = useRef(null);

    const sendForReview = () => {
        setModalTitle("Are You Sure");
        setModalBody(
            "Are you ready to send this sim for review? Once you click send, the sim will be locked until your trainer reviews your effort."
        );
        setShowModalButton(true);
        setOpenModal(true);
    };

    const confirmSendForReview = () => {
        const payload = {
            _id: userResult?.assignment_id,
            status: "PENDING REVIEW",
            response_id: userResult?._id,
        };
        // update assignment status to PENDING REVIEW
        addAssignmentStatus(payload);
        setAlertMesage("Assignment is send for review successfully!");
        setOpenAlert(true);
        setTimeout(() => {
            setOpenAlert(false);
            setRedirectToDashboard(true);
        }, 3000);
        setOpenModal(false);
        userResult.assignment_status = "PENDING REVIEW";
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const handlePlayCallAudio = () => {
        audioPlay("call");
        currentStatus(0);
    };

    const handlePlayAgentAudio = () => {
        audioPlay("agent");
        currentStatus(0);
    };

    const handleStop = () => {
        audioPlay("");
        currentStatus("");
    };

    const openPassReviewModal = () => {
        setShowModalButton(false);
        setReviewerStatus("PASS");
        setModalTitle("Marked the assignment as Passed");
        setModalBody("");
        setShowReviewerSubmitButton(true);
        setOpenModal(true);
    };

    const openFailedReviewModal = () => {
        setShowModalButton(false);
        setReviewerStatus("NO PASS");
        setModalTitle("Marked the assignment as No Pass");
        setModalBody("");
        setShowReviewerSubmitButton(true);
        setOpenModal(true);
    };

    const submitReviewAssignment = (status) => {
        const reviewParams = {
            _id: userResult.assignment_id,
            status: reviewerStatus,
            review_comments: reviewInputRef.current.value,
            response_id: userResult._id,
        };
        addAssignmentStatus(reviewParams);
        setOpenModal(false);
        setReviewButtonsDisable(true);
        setAlertMesage("Assignment Reviewed Successfully!");
        setOpenAlert(true);
        setTimeout(() => {
            setOpenAlert(false);
            setRedirectToDashboard(true);
        }, 3000);
    };

    if (screenRecorderBlob && (user.role).toLowerCase() !== 'agent') {
        //const url = window.URL.createObjectURL(screenRecorderBlob);
        const url = screenRecorderBlob;
        download.current.href = url;
        download.current.download = 'test.webm';
        download.current.style.display = 'block';
    }

    return (
        <div style={{ textAlign: "right", width: "70%" }}>
            {redirectToDashboard && <Redirect to="/dashboard" />}
            <Modal
                open={openModal}
                onClose={handleModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.modalPaper}>
                    <h2>{modalTitle}</h2>
                    <p>{modalBody}</p>
                    {showModalButton ? (
                        <>
                            <Button
                                onClick={confirmSendForReview}
                                color="primary"
                                variant="contained"
                                style={{ float: "right", margin: "0.5rem 1rem 0.5rem 0rem" }}
                            >
                                Continue
                            </Button>
                            <Button
                                onClick={handleModalClose}
                                variant="contained"
                                style={{ float: "right", margin: "0.5rem 1rem 0.5rem 0rem" }}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : null}
                    {showReviewerSubmitButton ? (
                        <>
                            <TextareaAutosize
                                style={{ width: "100%", height: "5rem", padding: ".4rem" }}
                                ref={reviewInputRef}
                                aria-label="minimum height"
                                rowsMax={10}
                                placeholder="Write Message to Learner..."
                                data-enable-grammarly="false"
                            />
                            <br />
                            <Button
                                onClick={submitReviewAssignment}
                                color="primary"
                                variant="contained"
                                style={{ float: "right", margin: "0.5rem 0rem 0rem 0rem" }}
                            >
                                Submit
                            </Button>
                        </>
                    ) : null}
                </div>
            </Modal>
            {(user.role).toLowerCase() !== 'agent' && showReviwerButtons &&
                userResult?.sim_level_id?.toString() ===
                userResult?.pass_fail_details?.sim_level_id?.toString() && (
                    <Button
                        variant="contained"
                        className={classes.startBtn}
                        disableElevation
                        disabled={reviewButtonsDisable}
                        color="primary"
                        size="small"
                        onClick={openPassReviewModal}
                    >
                        PASS
                    </Button>
                )}
            {(user.role).toLowerCase() !== 'agent' && showReviwerButtons &&
                userResult?.sim_level_id?.toString() ===
                userResult?.pass_fail_details?.sim_level_id?.toString() && (
                    <>
                        <Button
                            variant="contained"
                            className={classes.startBtn}
                            disableElevation
                            disabled={reviewButtonsDisable}
                            color="primary"
                            size="small"
                            onClick={openFailedReviewModal}
                        >
                            NO PASS
                        </Button>
                    </>
                )}
            {reviewer_come === "false" &&
                !["PASS", "NO PASS"].includes(userResult?.assignment_status) &&
                takeSimFromAssignmentSection &&
                userResult?.send_for_review &&
                userResult?.sim_level_id?.toString() ===
                userResult?.pass_fail_details?.sim_level_id?.toString() && (
                    <Tooltip
                        title={
                            allAudioProcessed
                                ? userResult?.assignment_status === "PENDING REVIEW"
                                    ? "Assignment is already in under review!"
                                    : userResult?.send_for_review
                                        ? "Want to send for review?"
                                        : "Enable only if all the levels completed!"
                                : "Button should be enabled only after processing the audios in all levels which are completed"
                        }
                        placement="top"
                    >
            <span>
              <Button
                  variant="contained"
                  className={classes.startBtn}
                  disableElevation
                  color="primary"
                  size="small"
                  disabled={
                      allAudioProcessed
                          ? userResult?.send_for_review &&
                          userResult?.assignment_status !== "PENDING REVIEW"
                              ? false
                              : true
                          : true
                  }
                  onClick={sendForReview}
              >
                SEND FOR REVIEW
              </Button>
            </span>
                    </Tooltip>
                )}

            {/* {reviewer_come === "false" &&
        takeSimFromAssignmentSection &&
        userResult?.sim_level_id?.toString() ===
        userResult?.pass_fail_details?.sim_level_id?.toString() && (
          <Tooltip title={"Want to see pass criteria?"} placement="top">
            <span>
              <Button
                variant="contained"
                className={classes.startBtn}
                disableElevation
                disabled={
                  userResult?.pass_fail_details?._id &&
                    takeSimFromAssignmentSection
                    ? false
                    : true
                }
                color="primary"
                size="small"
                onClick={() => handlePassDialog("open")}
              >
                PASS CRITERIA
              </Button>
            </span>
          </Tooltip>
        )} */}
            {!permissionCheck(DashboardPermissions.assessor_notes.update.task,
                DashboardPermissions.assessor_notes.update.type) && !permissionCheck(DashboardPermissions.assessor_notes.create.task,
                DashboardPermissions.assessor_notes.create.type) && permissionCheck(DashboardPermissions.assessor_notes.view.task,
                DashboardPermissions.assessor_notes.view.type) ?
                (<Tooltip title='View Assessor Notes' placement="bottom">
                    <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        className={classes.assessor}
                        onClick={() => addAssessor(true)}
                        disabled={Object.keys(userResult).length === 0}
                    >
                        <VisibilityIcon style={{ marginRight: "5px" }} />
                        ASSESSOR NOTES
                    </Button>
                </Tooltip>) :
                permissionCheck(DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type) || permissionCheck(DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type) ?
                    (<Tooltip title='Add Assessor Notes' placement="bottom">
            <span>
              <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  className={classes.assessor}
                  disabled={Object.keys(userResult).length === 0}
                  onClick={() => addAssessor(true)}
              >
                <AddIcon style={{ marginRight: "5px" }} />
                 ASSESSOR NOTES
              </Button>
            </span>
                    </Tooltip>) : null}
            {
                (user.role).toLowerCase() !== 'agent' &&
                <Tooltip title="Download recorded video">
                    <IconButton size="small" color="primary" className={classes.downloadBtn}>
                        <a ref={download} style={{ display: "none" }} href="#df">
                            <GetAppIcon />
                        </a>
                    </IconButton>
                </Tooltip>
            }

            {audioPlaying === "call" ? (
                <Button
                    onClick={handleStop}
                    variant="contained"
                    size="small"
                    disableElevation
                    className={classes.audioButtons}
                >
                    <StopIcon style={{ marginRight: "5px" }} />
                    Stop
                </Button>
            ) : (
                <Button
                    onClick={handlePlayCallAudio}
                    variant="contained"
                    size="small"
                    disableElevation
                    className={classes.audioButtons}
                >
                    <PlayArrowIcon style={{ marginRight: "5px" }} />
                    CALL AUDIO
                </Button>
            )}
            {audioPlaying === "agent" ? (
                <Button
                    onClick={handleStop}
                    variant="contained"
                    size="small"
                    disableElevation
                    className={classes.audioButtons}
                    style={{ marginLeft: "0px" }}
                >
                    <StopIcon style={{ marginRight: "5px" }} />
                    Stop
                </Button>
            ) : (
                <Button
                    onClick={handlePlayAgentAudio}
                    variant="contained"
                    size="small"
                    disableElevation
                    className={classes.audioButtons}
                    style={{ marginLeft: "0px" }}
                >
                    <PlayArrowIcon style={{ marginRight: "5px" }} />
                    AGENT AUDIO
                </Button>
            )}
            {/* <Dialog
        open={passDialog}
        onClose={() => handlePassDialog("close")}
        aria-labelledby="form-dialog-title"
        fullWidth={false}
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 style={{ margin: "0px" }}>Pass Criteria</h3>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ float: "right", margin: "0" }}
              onClick={() => handlePassDialog("close")}
            >
              X
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography>
            <strong>Value is NA if criteria not applicable</strong>
          </Typography>
          <Table>
            <TableBody>
              <TableRow key="">
                <TableCell component="th" scope="row">
                  Threshold Keyword Accuracy :
                </TableCell>
                <TableCell align="right">
                  {userResult?.pass_fail_details?.threshold_keyword_accuracy ??
                    "N/A"}
                </TableCell>
              </TableRow>
              <TableRow key="">
                <TableCell component="th" scope="row">
                  Threshold Typing Accuracy :
                </TableCell>
                <TableCell align="right">
                  {userResult?.pass_fail_details?.threshold_typing_accuracy ??
                    "N/A"}
                </TableCell>
              </TableRow>
              <TableRow key="">
                <TableCell component="th" scope="row">
                  Threshold Data Selection Accuracy :
                </TableCell>
                <TableCell align="right">
                  {userResult?.pass_fail_details
                    ?.threshold_data_selection_accuracy ?? "N/A"}
                </TableCell>
              </TableRow>
              <TableRow key="">
                <TableCell component="th" scope="row">
                  Threshold OverAll Accuracy :
                </TableCell>
                <TableCell align="right">
                  {userResult?.pass_fail_details?.threshold_overall_accuracy ??
                    "N/A"}
                </TableCell>
              </TableRow>
              <TableRow key="">
                <TableCell component="th" scope="row">
                  Allow Learner to skip levels :
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={
                      userResult?.pass_fail_details?.allow_skip_levels ?? false
                    }
                    style={{ paddingRight: "0px" }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog> */}
            {openAlert ? (
                <Alert className={classes.alert} variant="filled" severity="success">
                    {alertMesage}
                </Alert>
            ) : null}
        </div>
    );
};

ResultScreen.propTypes = {
    userResult: PropTypes.object,
    addAssignmentStatus: PropTypes.func,
    audioPlay: PropTypes.func,
    addAssessor: PropTypes.func,
    currentStatus: PropTypes.func,
    // assessorNotes: PropTypes.bool,
    takeSimFromAssignmentSection: PropTypes.bool,
    audioPlaying: PropTypes.string,
    showReviwerButtons: PropTypes.bool,
    screenRecorderBlob: PropTypes.string,
    allAudioProcessed: PropTypes.bool,
};
const mapStateToProps = (state) => ({
    userResult: state.sim.userResult,
    audioPlaying: state.sim.audioPlaying,
    takeSimFromAssignmentSection: state.sim.takeSimFromAssignmentSection,
    showReviwerButtons: state.sim.showReviwerButtons,
    screenRecorderBlob: state.sim.screenRecorderBlob,
    allAudioProcessed: state.sim.allAudioProcessed,
});

const mapDispatchToProps = {
    addAssignmentStatus,
    audioPlay,
    addAssessor,
    currentStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultScreen);
