/**
 * @date Dec 9 2024
 * @author Gonzalo Nuñez <griboni@snowfly.com>
 *
 * Class that uses {@link StreamRecorder} instances to produce the "Screen Share" video for RLC SIM attempts.
 */
import {uploadScreenShareStream} from "../services/result.service";
import {AudioRecorder, ScreenRecorder} from "./mediaRecorders";

export class RLCScreenRecorder {
    recorders = [];
    fileNames = [];
    videoSources = [];
    maxScreens = 1;
    options = {
        audioDeviceId: '',
        maxScreens: 1
    };
    #ready = false;

    constructor(options) {
        Object.assign(this.options, options);
        this.maxScreens = parseInt(this.options.maxScreens);

        this.recorders.push(new AudioRecorder(this.options.audioDeviceId));

        for (let screen = 0; screen < this.maxScreens; screen++) {
            this.recorders.push(new ScreenRecorder());
        }
    }

    /**
     * Prepares all the recorders that will be used.
     */
    async prepare() {
        let self = this;
        let promises = [];

        this.recorders.forEach((recorder) => {
            promises.push(
                new Promise((resolve, reject) => {
                    recorder.prepare().then((res) => {
                        if (res) {
                            self.#ready = true;
                            self.fileNames.push({
                                name: recorder.fileName,
                                type: recorder.streamType
                            });
                            resolve(true);
                        } else {
                            reject({error: 'Recorder failed initialization', recorder: recorder});
                        }
                    });
                })
            );
        });

        return await Promise.all(promises);
    }

    async start() {
        for (const recorder of this.recorders) {
            await recorder.start();
        }
    }

    async stop() {
        for (const recorder of this.recorders) {
            await recorder.stop();
            await recorder.close();
        }
        this.recorders = [];
    }

    async pause() {
        for (const recorder of this.recorders) {
            await recorder.pause();
        }
    }

    async resume() {
        for (const recorder of this.recorders) {
            await recorder.resume();
        }
    }

    async uploadStreams(progressCB) {
        const fsRoot = await navigator.storage.getDirectory();
        let sources = []

        let files = [],
            totalSize = 0;
        for (const recordingInfo of this.fileNames) {
            try {
                let fileName = recordingInfo.name;
                let fileType = recordingInfo.type === 'audio' ? 'audio/webm' : 'video/webm';
                let fileHandle = await fsRoot.getFileHandle(fileName);
                let file = await fileHandle.getFile();
                if (file.size === 0) {
                    fileName = recordingInfo.name + '.crswap'; // Not sure why this extension is needed in Chrome
                    fileHandle = await fsRoot.getFileHandle(fileName);
                    file = await fileHandle.getFile();
                }

                files.push({name: fileName, type: fileType, size: file.size});
                totalSize += file.size;
                file = null;
                fileHandle = null;
            } catch (e) {
                console.error(e);
            }
        }

        let totalChunks = totalSize / (512*1024),
            curChunk = 0
        for await (const recordingInfo of files) {
            try {
                let fileName = recordingInfo.name;
                let fileType = recordingInfo.type;
                let fileHandle = await fsRoot.getFileHandle(fileName);
                let file = await fileHandle.getFile();

                let response = await uploadScreenShareStream(file, fileType, fileName, (data, response) => {
                    console.log('CB Data = %o', data);
                    console.log('CB Response = %o', response);
                    let percent = curChunk * 100 / totalChunks;
                    progressCB && progressCB(percent);
                    curChunk += 1;
                });
                if (response && response.complete) {
                    sources.push({
                        name: response.remotePath,
                        type: fileType
                    });
                }
            } catch (err) {
                console.error(err);
            }
        }

        this.videoSources = sources.slice(0);

        return this.videoSources;
    }

    async clearStreams() {
        let fsRoot = await navigator.storage.getDirectory();
        for await (let value of Object.values(fsRoot)) {
            await fsRoot.removeEntry(value, { recursive: true });
        }
    }

    get ready() {
        let result = true;
        for (const recorder of this.recorders) {
            result &= recorder.ready;
        }
        return result;
    }

}
