import axios from 'axios';
import {
    FETCH_REQUEST,
    FETCH_SUCCESS,
    FETCH_FAILURE,
    FETCH_STATS,
    ADD_BASIC_INFORMATION,
    INCREASE_NEWSIM_STEP,
    DECREASE_NEWSIM_STEP,
    ADD_AVATAR_DETAILS,
    INITIALIZE_NEWSIM,
    ADD_KEYWORD,
    FETCH_CURRENT,
    FETCH_KEYWORD,
    FETCH_KBCA,
    ADD_LEVELS,
    FETCH_CHARACTERS,
    FETCH_LEVEL_CLIPS,
    FETCH_TAKESIM,
    FETCH_SCREENING_KEYWORD,
    FETCH_INSURANCES,
    FETCH_PHYSICIAN_MAP_FORM,
    SELECTED_KBCA,
    FETCH_DISPOSITIONS,
    ADD_DISPOSITIONS,
    FETCH_ARTICLES,
    SELECTED_ARTICLE,
    SAVE_ADDRESS,
    FETCH_OPPORTUNITY,
    SAVE_OPPORTUNITY,
    FETCH_OPPBYNAME,
    FETCH_USERDATA,
    FETCH_TOTAL_ASSIGNMENTS,
    FETCH_EMPLOYEE_LIST,
    FETCH_MEMBERS_LIST,
    FETCH_ASSIGNMENTS,
    FETCH_GROUPS_LIST,
    FETCH_SELECTION_DATA,
    ADD_ASSIGNMENT,
    CHANGE_PRODUCT_ACCESS,
    FETCH_PRODUCTS,
    FETCH_SIM_DETAILS,
    SET_EDIT_SIM_STEP,
    FETCH_PERMISSIONS_LIST,
    UPLOAD_LEVELS,
    SAVE_ELAPSED_TIME,
    GENERATE_SIM,
    FETCH_USERS,
    ADD_NEW_LEVELS,
    ADD_CLIP_AUDIO,
    FETCH_SIM_LEVEL_USER_RESPONSE,
    FETCH_USER_FINAL_SCORE_RESPONSE,
    FETCH_SAVED_KEYWORDS,
    RESET,
    SAVE_CASE_VALUES,
    SAVE_USER,
    SET_PASS_FAIL_CRITERIEA,
    FETCH_AUTO_POPULATE,
    FETCH_GROUP_LIST,
    FETCH_USER_DATA,
    NOTES_SAVE,
    FETCH_CURRENT_STATUS,
    DELETE_PREVIOUS_ASSIGNMENT_DETAILS,
    TOGGLE_RESULT_BUTTONS,
    SAVE_SIM_TITLE,
    SELECTED_SIM,
    CURRENT_AUDIO_PLAYING,
    AUDIO_PLAY,
    ADD_ASSESSOR_NOTES,
    SAVE_ASSESSOR_NOTES,
    SHOW_REVIEWER_BUTTON,
    FETCH_SCORES,
    ALL_LEVEL_AGENT_AUDIO_PROCESS,
    ADD_SCREEN_RECORDER_BLOB,
    SET_EDIT_SIM_DETAILS_STEP,
    RESET_STATS,
    DELETE_ASSIGNMENT,
    UPDATE_CURRENT_CLIP,
    RESTART_LEVEL_IN_TAKE_SIM,
    ENABLE_VIDEO_RECORDING,
    RESET_VIDEO_STATS,
    VIDEO_UPLOAD_STATUS,
    FETCH_ASSIGNMENT_COUNTS,
    FETCH_TRASHED_SIMS,
    SET_VIDEO_BLOB,
    CLOSE_CAPTIVATE_WINDOW,
    RESET_PASS_FAIL,
    RESET_PASSFAIL_MODAL,
    SIM_PRODUCT_TYPE,
    SAVE_WRAP_SECONDS,
} from './simTypes';
import sims from '../../permissions/sims';
import admin from '../../permissions/admin';
import dashboard from '../../permissions/dashboard';
import config from '../../config';

export const fetchRequest = () => ({
    type: FETCH_REQUEST,
});

export const fetchSuccess = (data) => ({
    type: FETCH_SUCCESS,
    payload: data,
});

export const fetchSuccessStats = (data) => ({
    type: FETCH_STATS,
    payload: data,
});
export const fetchAssignment = (data) => ({
    type: FETCH_ASSIGNMENTS,
    payload: data,
});

export const fetchCharacters = (data) => ({
    type: FETCH_CHARACTERS,
    payload: data,
});

export const fetchClips = (data) => ({
    type: FETCH_LEVEL_CLIPS,
    payload: data,
});

export const fetchKbcaData = (data) => ({
    type: FETCH_KBCA,
    payload: data,
});

export const fetchCurrent = (data) => ({
    type: FETCH_CURRENT,
    payload: data,
});

export const fetchFailure = (data) => ({
    type: FETCH_FAILURE,
    payload: data,
});

export const fetchInsurances = (data) => ({
    type: FETCH_INSURANCES,
    payload: data,
});

export const fetchPhysicianMap = (data) => ({
    type: FETCH_PHYSICIAN_MAP_FORM,
    payload: data,
});

export const fetchData = (limit = 10, page = 0, product = null,
                          title = null, sortBy = null, order = null) => async (dispatch) => {
    const skip = limit * page;
    dispatch(fetchRequest());

    await axios(
        `${config.API_BASE_URL}/api/v1/sims/listing?skip=${skip}&limit=${limit}${product ? `&product=${product}` : ''
        }${title ? `&title=${title}` : ''}&sortBy=${sortBy}&order=${order}`, {
            headers: {
                Authorization: config.getToken(),
                task: sims.sim_list.view.task,
                type: sims.sim_list.view.type,
            },
        },
    )
        .then((res) => dispatch(fetchSuccess(res)))
        .catch((error) => {
            dispatch(fetchFailure(error));
        });
};
// fetch stats for simId clicked from listing
export const fetchStats = (simId, userId) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.API_BASE_URL}/api/v1/sims/stats?sim_id=${simId}${userId ? `&user_id=${userId}` : ''}`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchSuccessStats(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const updateStats = (
    simId,
    simLevelId,
    status,
    mode,
    activeIndex = 0,
    levelStart,
    updateIndexBool = false,
) => async (dispatch) => {
    const body = {
        simId,
        simLevelId,
        mode,
        status,
        activeIndex,
        levelStart,
        updateIndexBool,
    };
    dispatch(fetchRequest);
    await axios
        .post(`${config.API_BASE_URL}/api/v1/sims/stats`, body, {
            headers: {
                Authorization: config.getToken(),
                task: null,
                type: null,
            },
        })
        .then((res) => dispatch(fetchSuccess(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchUser = (data) => ({
    type: FETCH_USERDATA,
    payload: data,
});

export const fetchSelectionData = (data) => ({
    type: FETCH_SELECTION_DATA,
    payload: data,
});

export const fetchAssignmentTotal = (data) => ({
    type: FETCH_TOTAL_ASSIGNMENTS,
    payload: data,
});

export const fetchLevelClips = (simLevelId, recordings = []) => async (dispatch) => {
    dispatch(fetchRequest);
    let url =`${config.API_BASE_URL}/api/v1/sims/take-sim/details?simLevelId=${simLevelId}`;
    if (recordings && recordings.length) {
        url += '&recordings='+recordings.join(',');
    }
    await axios(
        url, {
            headers: {
                Authorization: config.getToken(),
                task: null,
                type: null,
            },
        },
    )
        .then((res) => dispatch(fetchClips(res)))
        .catch((error) => {
            dispatch(fetchFailure(error));
            dispatch(fetchClips({
                data: {},
            }));
        });
};

// add recorded audio to clip
export const addAudioToClip = (data) => ({
    type: ADD_CLIP_AUDIO,
    payload: data,
});

export const fetchKbca = (word) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/kbca?q=${word}`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchKbcaData(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchCurrentSim = (id) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.get(`${config.API_BASE_URL}/api/v1/sims/current-sim?id=${id}`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchCurrent(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchCharacterList = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.API_BASE_URL}/characters`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchCharacters(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchProducts = (data) => ({
    type: FETCH_PRODUCTS,
    payload: data,
});
export const fetchProductList = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.get(`${config.API_BASE_URL}/api/v1/product`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchProducts(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const addBasicInfoRedux = (data, res) => ({
    type: ADD_BASIC_INFORMATION,
    result: res.data.id,
    payload: data,
});

export const addAssignmentIntoToRedux = (res) => ({
    type: ADD_ASSIGNMENT,
    result: res.data,
});

export const addBasicInfo = (data, requestData) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.post(`${config.API_BASE_URL}/api/v1/sims/basicInfo`, data, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(addBasicInfoRedux(requestData, res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const logError = (data, requestData) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.post(`${config.API_BASE_URL}/api/v1/sims/log-error`, data, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .catch((error) => dispatch(fetchFailure(error)));
};

export const increaseStep = () => ({
    type: INCREASE_NEWSIM_STEP,
});
export const decreaseStep = () => ({
    type: DECREASE_NEWSIM_STEP,
});

export const addAvatarDetails = (response) => ({
    type: ADD_AVATAR_DETAILS,
    payload: response,
});
export const fetchKeyword = (data) => ({
    type: FETCH_KEYWORD,
    payload: data,
});
export const fetchCreateKeywords = (simId = null) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.get(`${config.API_BASE_URL}/api/v1/layoutData/fetch${simId ? `?sim_id=${simId}` : ''}`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchKeyword(res.data.layoutString)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchAutoPopulateArray = (data) => ({
    type: FETCH_AUTO_POPULATE,
    payload: data,
});
export const fetchAutoPopulate = (sim_id) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.get(`${config.API_BASE_URL}/api/v1/layoutData/fetch?sim_id=${sim_id}`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchAutoPopulateArray(res.data.layoutString)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const saveKeywords = (data) => ({
    type: FETCH_SAVED_KEYWORDS,
    payload: data,
});
export const fetchSavedKeywords = (id) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.get(`${config.API_BASE_URL}/api/v1/sims/get-keywords?sim_id=${id}`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(saveKeywords(res.data.layoutString[0])))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const reset = () => ({
    type: RESET,
});
export const resetStats = () => ({
    type: RESET_STATS,
});
export const addKeywords = (data) => ({
    type: ADD_KEYWORD,
    payload: data,
});
// <---------------------- fetch keywords ----------------->
export const takeSim = (data) => ({
    type: FETCH_TAKESIM,
    payload: data,
});

// <--------------- fetch default keywords --------------->
export const fetchDefaultDemographics = () => (dispatch) => {
    // dispatch(fetchRequest);
    axios(`${config.API_BASE_URL}/api/v1/layoutData/fetch`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(takeSim(res.data.layoutString)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchDemographics = (simId) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.API_BASE_URL}/api/v1/sims/get-keywords?sim_id=${simId}`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => {
            if (res.data.layoutString.length) {
                dispatch(
                    takeSim(res.data.layoutString),
                );
            } else {
                dispatch(fetchFailure({}));
                dispatch(fetchDefaultDemographics());
            }
        })
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchTakeSim = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.API_BASE_URL}/takeSim`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchCurrent(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const initializeNewSim = () => ({
    type: INITIALIZE_NEWSIM,
});

export const addLevels = (data) => ({
    type: ADD_LEVELS,
    payload: data,
});

export const fetchScreeningKeyword = (data) => ({
    type: FETCH_SCREENING_KEYWORD,
    payload: data,
});

export const saveSelectedKbca = (data) => ({
    type: SELECTED_KBCA,
    payload: data,
});

export const fetchScreening = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/screening`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchScreeningKeyword(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchPhysicianMapForm = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/physicianMapForm`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchPhysicianMap(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchInsurancesLookup = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/insurances`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchInsurances(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchDispositions = (data) => ({
    type: FETCH_DISPOSITIONS,
    payload: data,
});

export const fetchDispositionKeywords = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/dispositions`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchDispositions(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const addDispositions = (data) => ({
    type: ADD_DISPOSITIONS,
    payload: data,
});

export const fetchArticle = (data) => ({
    type: FETCH_ARTICLES,
    payload: data,
});

export const fetchArticles = (searchData) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/articles?q=${searchData}`)
        .then((res) => dispatch(fetchArticle(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const saveSelectedArticle = (data) => ({
    type: SELECTED_ARTICLE,
    payload: data,
});

export const saveAddress = (payload) => ({
    type: SAVE_ADDRESS,
    payload,
});
// OPPORTUNITY TAB

export const loadOpportunity = (data) => ({
    type: FETCH_OPPORTUNITY,
    payload: data,
});

export const saveOpportunity = (data) => ({
    type: SAVE_OPPORTUNITY,
    payload: data,
});
export const fetchOppByNameData = (data) => ({
    type: FETCH_OPPBYNAME,
    payload: data,
});

export const fetchOppByName = (word) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/opportunitiesByName?q=${word}`)
        .then((res) => dispatch(fetchOppByNameData(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchOpportunity = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/opportunities`)
        .then((res) => dispatch(loadOpportunity(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchEmployees = (data) => ({
    type: FETCH_EMPLOYEE_LIST,
    payload: data,
});

export const fetchMembers = (data) => ({
    type: FETCH_MEMBERS_LIST,
    payload: data,
});

export const fetchPermissions = (data) => ({
    type: FETCH_PERMISSIONS_LIST,
    payload: data,
});

export const fetchEmployeeList = (pageNumber,
                                  textSearch = null, sortBy = null, order = null) => async (dispatch) => {
    const limit = 10;
    const skip = limit * pageNumber;
    dispatch(fetchRequest);
    await axios(`${config.API_BASE_URL}/api/v1/employee/list?skip=${skip}&limit=${limit}${textSearch ? `&textSearch=${textSearch}` : ''}&sortBy=${sortBy}&order=${order}`, {
        headers: {
            Authorization: config.getToken(),
            task: admin.employee_list.view.task,
            type: admin.employee_list.view.type,
        },
    })
        .then((res) => dispatch(fetchEmployees(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchMembersList = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/membersList`)
        .then((res) => dispatch(fetchMembers(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchAssignments = (pageNumber = 0,
                                 textSearch = null, myAssignment, tab = null,
                                 orderBy = null, order = null, skip) => async (dispatch) => {
    dispatch(fetchRequest());
    const limit = 10;
    let task;
    let type;
    if (tab === 'MY_ASSIGNMENT' || tab === 'COMPLETED') {
        task = sims.assignment.view.task;
        type = sims.assignment.view.type;
    } else if (tab === 'PENDING_REVIEW') {
        task = dashboard.review_assignments.view.task;
        type = dashboard.review_assignments.view.type;
    } else {
        task = dashboard.all_assignments.view.task;
        type = dashboard.all_assignments.view.type;
    }
    await axios(`${config.API_BASE_URL}/api/v1/sims/assignment/list?skip=${skip}&limit=${limit}${textSearch ? `&textSearch=${textSearch}` : ''}
  &my_assignment=${myAssignment}${tab ? `&tab=${tab}` : ''}&orderBy=${orderBy}&order=${order}`, {
        headers: {
            Authorization: config.getToken(),
            task,
            type,
        },
    })
        .then((res) => dispatch(fetchAssignment(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchUserData = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.API_BASE_URL}/api/v1/users/count`, {
        headers: {
            Authorization: config.getToken(),
            task: dashboard.user_account.view.task,
            type: dashboard.user_account.view.type,
        },
    })
        .then((res) => dispatch(fetchUser(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchTotalAssignments = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.MOCK_URL}/totalAssignments`)
        .then((res) => dispatch(fetchAssignmentTotal(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchGroups = (data) => ({
    type: FETCH_GROUPS_LIST,
    payload: data,
});

export const fetchGroupsList = (pageNumber = 0,
                                textSearch = null) => async (dispatch) => {
    dispatch(fetchRequest);
    const limit = 10;
    const skip = limit * pageNumber;
    await axios(`${config.API_BASE_URL}/api/v1/groups/listing?skip=${skip}&limit=${limit}${textSearch ? `&textSearch=${textSearch}` : ''}`, {
        headers: {
            Authorization: config.getToken(),
            task: admin.groups.view.task,
            type: admin.groups.view.type,
        },
    })
        .then((res) => dispatch(fetchGroups(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const changeProductAccess = (data) => ({
    type: CHANGE_PRODUCT_ACCESS,
    payload: data,
});
export const fetchPermissionList = (roles) => async (dispatch) => {
    dispatch(fetchRequest);
    const companyId = JSON.parse(localStorage.getItem('user')).company_id;
    await axios.post(`${config.API_BASE_URL}/api/v1/permissions/details?company_id=${companyId}`, roles, {
        headers: {
            Authorization: config.getToken(),
            task: roles && roles.length ? null : admin.permissions.view.task,
            type: roles && roles.length ? null : admin.permissions.view.type,
        },
    })
        .then((res) => dispatch(fetchPermissions(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const addSimDetailsRedux = (data) => ({
    type: FETCH_SIM_DETAILS,
    payload: data,
});

export const fetchSimDetails = (id) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.get(`${config.API_BASE_URL}/api/v1/sims/full-details?sim_id=${id}`, {
        headers: {
            Authorization: config.getToken(),
            task: sims.sim_list.update.task,
            type: sims.sim_list.update.type,

        },
    })
        .then((res) => dispatch(addSimDetailsRedux(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const setEditSimStep = () => ({
    type: SET_EDIT_SIM_STEP,
});

export const setEditSimDetailsStep = () => ({
    type: SET_EDIT_SIM_DETAILS_STEP,
});

export const uploadLevelsResponse = (data) => ({
    type: UPLOAD_LEVELS,
    payload: data,
});

export const uploadLevels = (data, id, levelCount, order) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.post(`${config.API_BASE_URL}/api/v1/sims/upload-levels?sim_id=${id}&levelCount=${levelCount}&order=${order}`, data, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(uploadLevelsResponse(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

// SAVE elapsed time in take sim / per level

export const saveElapsedTime = (data) => ({
    type: SAVE_ELAPSED_TIME,
    payload: data,
});

export const saveWrapSeconds = (data) => ({
    type: SAVE_WRAP_SECONDS,
    payload: data,
});

export const generateSimResponse = (data) => ({
    type: GENERATE_SIM,
    payload: data,
});

export const generateSim = (data, levelId) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.post(`${config.API_BASE_URL}/api/v1/sims/generate-sim?levelId=${levelId}`, data, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(generateSimResponse(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchUsers = (data) => ({
    type: FETCH_USERS,
    payload: data,
});

export const fetchGroup = (data) => ({
    type: FETCH_GROUP_LIST,
    payload: data,
});
export const fetchUserList = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.get(`${config.API_BASE_URL}/api/v1/users/list`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchUsers(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchGroupList = () => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.get(`${config.API_BASE_URL}/api/v1/sims/group/listing`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchGroup(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const addAssignment = (data) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.post(`${config.API_BASE_URL}/api/v1/sims/assignment/add`, data, {
        headers: {
            Authorization: config.getToken(),
            task: sims.assignment.create.task,
            type: sims.assignment.create.type,
        },
    })
        .then((res) => dispatch(addAssignmentIntoToRedux(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const addAssignmentStatus = (data) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.post(`${config.API_BASE_URL}/api/v1/sims/assignment/add`, data, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(addAssignmentIntoToRedux(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const addNewLevels = (data) => ({
    type: ADD_NEW_LEVELS,
    payload: data,
});
//  call user respinse API endpoint
export const userResponseSuccess = (data) => ({
    type: FETCH_SIM_LEVEL_USER_RESPONSE,
    payload: data,
});

export const fetchUserResponse = (data) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.post(`${config.API_BASE_URL}/api/v1/sims/save-take-sim-details`, data, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(userResponseSuccess(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

// <------------------------ Fetch final score response ------------------------->

export const userFinalScoreResponseSuccess = (payload) => ({
    type: FETCH_USER_FINAL_SCORE_RESPONSE,
    payload,
});

export const userFinalScoreResponse = (id, payload = {}) => async (dispatch) => {
    dispatch(fetchRequest);
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/get-scorecard/${id}`,
        method: 'POST',
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
        data: payload,
    })
        .then((res) => dispatch(userFinalScoreResponseSuccess(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const addUserData = (data, res) => ({
    type: SAVE_USER,
    result: res.data,
});
export const saveUser = (data) => async (dispatch) => {
    const task = data._id ? admin.employee_list.update.task : admin.employee_list.create.task;
    const type = data._id ? admin.employee_list.update.type : admin.employee_list.create.type;
    dispatch(fetchRequest);
    await axios.post(`${config.API_BASE_URL}/api/v1/users/create`, data, {
        headers: {
            Authorization: config.getToken(),
            task,
            type,
        },
    })
        .then((res) => dispatch(addUserData(data, res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const saveUserSettings = (data) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios.post(`${config.API_BASE_URL}/api/v1/users/create`, data, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(addUserData(data, res)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const viewResult = (data, agent_user_id) => async (dispatch) => {
    dispatch(fetchRequest);
    await axios({
        url: `${config.API_BASE_URL}/api/v1/sims/result${agent_user_id ? `?user_id=${agent_user_id}` : ''}`,
        method: 'POST',
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
        data,
    })
        .then((res) => dispatch(userFinalScoreResponseSuccess(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};

// <------------- Save case input & target vaule in store -------------->

export const saveCaseVaules = (
    payload,
    textCount,
    otherCount,
    resultScreenInputData,
) => ({
    type: SAVE_CASE_VALUES,
    payload,
    textCount,
    otherCount,
    resultScreenInputData,
});

// <---------------- Set paas / fail criteria for sim ------------------>

export const setPassFailCriteriaSuccess = (payload) => ({
    type: SET_PASS_FAIL_CRITERIEA,
    payload,
});

export const setPassFailCriteriea = (payload) => (dispatch) => {
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/set-criteria`,
        method: 'POST',
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
        data: payload,
    })
        .then((res) => dispatch(setPassFailCriteriaSuccess(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchUserDataRedux = (data) => ({
    type: FETCH_USER_DATA,
    payload: data,
});
export const fetchCurrentUserData = (userId = '') => async (dispatch) => {
    dispatch(fetchRequest);
    await axios(`${config.API_BASE_URL}/api/v1/users/info?${userId ? `&user_id=${userId}` : ''}`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchUserDataRedux(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const saveNotes = (data) => ({
    type: NOTES_SAVE,
    payload: data,
});

export const notesSave = (data) => async (dispatch) => {
    const task = data.id ? dashboard.my_notepad.update.task : dashboard.my_notepad.create.task;
    const type = data.id ? dashboard.my_notepad.update.type : dashboard.my_notepad.create.type;
    dispatch(fetchRequest);
    await axios({
        url: `${config.API_BASE_URL}/api/v1/sims/notes`,
        method: 'POST',
        headers: {
            Authorization: config.getToken(),
            task,
            type,
        },
        data,
    })
        .then((res) => dispatch(saveNotes(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const fetchNotes = (data) => async (dispatch) => {
    dispatch(fetchRequest);
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/notes`,
        method: 'GET',
        headers: {
            Authorization: config.getToken(),
            task: dashboard.my_notepad.view.task,
            type: dashboard.my_notepad.view.type,
        },
        data,
    })
        .then((res) => dispatch(saveNotes(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const deleteNotes = (id) => async (dispatch) => {
    dispatch(fetchRequest);
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/notes/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: config.getToken(),
            task: dashboard.my_notepad.delete.task,
            type: dashboard.my_notepad.delete.type,
        },
    })
        .then((res) => dispatch(saveNotes(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};
// <------------------ Empty assignment previous details ---------------------->
export const deleteAssignmentDetails = () => ({
    type: DELETE_PREVIOUS_ASSIGNMENT_DETAILS,
});

// <------------------------- Get Assignment status -------------------------->
export const getAssignmentStatusSuccess = (payload, isReviewer) => ({
    type: FETCH_CURRENT_STATUS,
    payload,
    isReviewer,
});

export const getAssignmentStatus = (assignmentId, payload) => async (dispatch) => {
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/assignment/status/${assignmentId}`,
        method: 'GET',
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => {
            dispatch(getAssignmentStatusSuccess(res.data, payload));
        })
        .catch((error) => dispatch(fetchFailure(error)));
};

// <--------------- toggle result buttons -------------------->

export const toggleResultButton = (payload) => ({
    type: TOGGLE_RESULT_BUTTONS,
    payload,
});

export const saveSimTitle = (data) => ({
    type: SAVE_SIM_TITLE,
    payload: data,
});
export const saveSelectedSim = (data) => ({
    type: SELECTED_SIM,
    payload: data,
});

export const currentStatus = (data) => ({
    type: CURRENT_AUDIO_PLAYING,
    payload: data,
});

export const audioPlay = (data) => ({
    type: AUDIO_PLAY,
    payload: data,
});

export const addAssessor = (data) => ({
    type: ADD_ASSESSOR_NOTES,
    payload: data,
});

export const saveAssessor = (data) => ({
    type: SAVE_ASSESSOR_NOTES,
    payload: data,
});

export const getAssessorNotes = (id) => async (dispatch) => {
    dispatch(fetchRequest);
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/get-assessor-notes/${id}`,
        method: 'GET',
        headers: {
            Authorization: config.getToken(),
            task: dashboard.assessor_notes.view.task,
            type: dashboard.assessor_notes.view.type,

        },
    })
        .then((res) => dispatch(saveAssessor(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};

export const SaveAssessorNotes = (id, payload = {}) => async (dispatch) => {
    dispatch(fetchRequest);
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/save-assessor-notes/${id}`,
        method: 'POST',
        headers: {
            Authorization: config.getToken(),
            task: dashboard.assessor_notes.view.task,
            type: dashboard.assessor_notes.view.type,
        },
        data: payload,
    })
        .then((res) => dispatch(saveAssessor(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};
// <---------------- show reviewer button ------------------->

export const handleShowReviewerButtons = (payload) => ({
    type: SHOW_REVIEWER_BUTTON,
    payload,
});
export const fetchScore = (data) => ({
    type: FETCH_SCORES,
    payload: data,
});
export const fetchScoreList = (pageNumber = 0,
                               textSearch = null, finalResult, highestLevel, filterData,
                               sortBy = null, sort = null, skip) => async (dispatch) => {
    dispatch(fetchRequest());
    const limit = 10;
    await axios.post(`${config.API_BASE_URL}/api/v1/sims/level/listing?skip=${skip}&limit=${limit}${textSearch ? `&textSearch=${textSearch}` : ''}
  &final_result=${finalResult}&highest_level=${highestLevel}&sortBy=${sortBy}&sort=${sort}`, filterData, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(fetchScore(res)))
        .catch((error) => dispatch(fetchFailure(error)));
};

// <------------ Check all level agent audio status ------------>

export const checkAllLevelAgentAudioSuccess = (payload) => ({
    type: ALL_LEVEL_AGENT_AUDIO_PROCESS,
    payload,
});

export const checkAllLevelAgentAudio = (simId, assignmentId, user_id) => (dispatch) => {
    dispatch(fetchRequest);
    const delaySomeTime = (sec) => new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, sec);
    });
    const getStatus = (simId, assignmentId, user_id) => {
        axios({
            url: `${config.API_BASE_URL
            }/api/v1/sims/agent/audio/status?simId=${simId}${assignmentId ? `&assignmentId=${assignmentId}` : ''
            }${user_id ? `&user_id=${user_id}` : ''
            }`,
            method: 'GET',
            headers: {
                Authorization: config.getToken(),
                task: null,
                type: null,
            },
        })
            .then((res) => {
                if (res.data.allAudioProcessed) {
                    dispatch(checkAllLevelAgentAudioSuccess(res.data.allAudioProcessed));
                } else {
                    dispatch(checkAllLevelAgentAudioSuccess(false));
                    delaySomeTime(6000).then(() => {
                        getStatus(simId, assignmentId, user_id);
                    });
                }
            })
            .catch((error) => dispatch(fetchFailure(error)));
    };
    getStatus(simId, assignmentId, user_id);
};

export const addScreenRecorderBlob = (data) => ({
    type: ADD_SCREEN_RECORDER_BLOB,
    payload: data,
});

export const enableVideoRecord = (data) => ({
    type: ENABLE_VIDEO_RECORDING,
    payload: data,
});

export const saveAssignmentNotes = (id, payload = {}, isUpdate) => async (dispatch) => {
    dispatch(fetchRequest);
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/save-assignment-notes/${id}`,
        method: 'POST',
        headers: {
            Authorization: config.getToken(),
            task: isUpdate
                ? dashboard.notes_to_user.update.task
                : dashboard.notes_to_user.create.task,
            type: isUpdate
                ? dashboard.notes_to_user.update.type
                : dashboard.notes_to_user.create.type,
        },
        data: payload,
    })
        .then((res) => dispatch(saveAssessor(res.data)))
        .catch((error) => {
            dispatch(fetchFailure(error));
        });
};

export const assignmentDelete = (data) => ({
    type: DELETE_ASSIGNMENT,
    payload: data,
});

export const deleteAssignment = (id) => async (dispatch) => {
    dispatch(fetchRequest);
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/delete-assignment/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: config.getToken(),
            task: sims.assignment.delete.task,
            type: sims.assignment.delete.type,
        },
    })
        .then(() => dispatch())
        .catch((error) => dispatch(fetchFailure(error)));
};
export const updateCurrentClip = (payload) => ({
    type: UPDATE_CURRENT_CLIP,
    payload,
});

export const restartLevelInTakeSim = () => ({
    type: RESTART_LEVEL_IN_TAKE_SIM,
});

export const resetVideoStats = () => ({
    type: RESET_VIDEO_STATS,
});

export const saveVideoStatus = (data) => ({
    type: VIDEO_UPLOAD_STATUS,
    payload: data,
});

export const uploadRecordedVideo = (data) => async (dispatch) => {
    dispatch(fetchRequest);
    axios({
        url: `${config.API_BASE_URL}/api/v1/sims/upload-video`,
        method: 'POST',
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
        data,
    })
        .then((res) => dispatch(saveVideoStatus(res.data)))
        .catch((error) => dispatch(saveVideoStatus(error.response.data)));
};

export const getAssignmentsCount = (data) => ({
    type: FETCH_ASSIGNMENT_COUNTS,
    payload: data.data,
});
export const fetchAssignmentsCount = () => async (dispatch) => {
    dispatch(fetchRequest());
    await axios.get(`${config.API_BASE_URL}/api/v1/sims/assignment/count`, {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
        },
    })
        .then((res) => dispatch(getAssignmentsCount(res.data)))
        .catch((error) => dispatch(fetchFailure(error)));
};
export const fetchTrashedSims = (data) => ({
    type: FETCH_TRASHED_SIMS,
    payload: data,
});
export const fetchDeletedSims = (limit = 10, page = 0) => async (dispatch) => {
    const skip = limit * page;
    dispatch(fetchRequest());
    await axios(
        `${config.API_BASE_URL}/api/v1/sims/deleted-sims?skip=${skip}&limit=${limit}`, {
            headers: {
                Authorization: config.getToken(),
            },
        },
    )
        .then((res) => dispatch(fetchTrashedSims(res)))
        .catch((error) => {
            dispatch(fetchFailure(error));
        });
};

export const saveVideoBlob = (data) => ({
    type: SET_VIDEO_BLOB,
    payload: data,
});

export const saveCaptivateWindowRef = (data) => ({
    type: CLOSE_CAPTIVATE_WINDOW,
    payload: data,
});
export const resetPassFail = () => ({
    type: RESET_PASS_FAIL,
});
export const resetPassFailModal = () => ({
    type: RESET_PASSFAIL_MODAL,
});
export const saveCurrentSimProductType = (data) => ({
    type: SIM_PRODUCT_TYPE,
    payload: data,
});
