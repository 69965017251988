import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import React, {useEffect, useState} from "react";
import FileSaver from "file-saver";
import {deleteLevel, deleteScript, downloadExcelLevel} from "../../../services/result.service";

const LevelActions = ({
    classes,
    data,
    levelIndex,
    //deleted,
    setdeleted,
    setData,
    setIndex
}) => {
    const [level, setLevel] = useState(null);
    const [deleteLastLevel, setDeleteLastLevel] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    useEffect(() => {
        setLevel(data?.levels[levelIndex] ?? null);
    }, [data, levelIndex, level, setLevel]);

    const downloadLevel = async () => {
        if (!level) {
            return;
        }

        downloadExcelLevel(level._id).then((response) => {
            const fileName = level.name.replace(/\//g, '_');
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, `${fileName}.xlsx`);
        });
    };

    const handleDeleteLevel = async () => {
        const sim = { ...data };
        if (sim.levels.length === 1) {
            setDeleteLastLevel(true);
            setDeleteOpen(true);
        } else {
            setDeleteOpen(true);
        }
    };

    const deleteConfirm = async () => {
        setdeleted(true);
        const sim = { ...data };

        if(sim.is_pass_fail_criteria_set) {
            sim.is_pass_fail_criteria_set = false;
            sim.pass_fail_id = {};
        }

        if (sim.levels[levelIndex]._id === undefined) {
            sim.levels.splice(levelIndex, 1);
        } else if (deleteLastLevel) {
            const data = {}
            data.sim_level_id = sim.levels[levelIndex]._id;
            await deleteScript(data);
            setDeleteLastLevel(false);
            sim.levels[levelIndex].scripts.splice(0, sim.levels[levelIndex].scripts.length);
            setData(sim);
        } else {
            const requestData = {};
            requestData.level_id = sim.levels[levelIndex]._id;
            requestData.sim_id = sim.levels[levelIndex].sim_id;
            await deleteLevel(requestData);
            sim.levels.splice(levelIndex, 1);
        }

        if (levelIndex > 0) {
            setIndex(levelIndex - 1);
        }

        setData(sim);
        setDeleteOpen(false);
    };

    const deleteCancel = async() => {
        setDeleteLastLevel(false);
        setDeleteOpen(false);
    }

    return (
        <>
            <div>
                <Box className={classes.iconBox} style={{verticalAlign: "middle"}}>
                    <Tooltip title={'Download level '+level?.name+' as a CSV'}>
                        <Button className={classes.closeButton} style={{padding: '0.25em', width: 'fit-content', minWidth: 'unset'}}>
                            <GetAppIcon
                                className={classes.iconDownload}
                                onClick={downloadLevel}
                            />
                        </Button>
                    </Tooltip>
                    <span className={classes.verticalDivider}/>
                    <Tooltip title={"Delete Level "+level?.name}>
                        <Button className={classes.closeButton} style={{padding: '0.25em', width: 'fit-content', minWidth: 'unset'}}>
                            <DeleteOutlineIcon
                                className={classes.iconDelete}
                                onClick={handleDeleteLevel}
                            />
                        </Button>
                    </Tooltip>
                </Box>
            </div>

            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Level</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Do you want to delete the level "${level?.name ?? ''}"`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => deleteCancel()}
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => deleteConfirm()}
                        className={classes.dialogDeleteBtn}
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default LevelActions;