export const debug = function() {
    let message = arguments[0],
        args = Array.prototype.slice.call(arguments, 1);
    if ('string' === typeof message) {
        message = message.replaceAll(/%o/ig, '%O');
    }
    if (process.env.REACT_APP_STAGE !== 'prod') {
        console.log(message, ...args);
    }
}

export default debug;