export const formatElapsedTime = (elapsedTime) => {
    let seconds = 0;

    if ('string' === typeof(elapsedTime)) {
        const timeParts = elapsedTime.match(/([\d]+[\D]+)/g);
        timeParts.forEach((timePart) => {
            if (timePart.indexOf('h') > -1) {
                seconds += parseFloat(timePart)*3600;
            }
            if (timePart.indexOf('m') > -1) {
                seconds += parseFloat(timePart)*60;
            }
            if (timePart.indexOf('s') > -1) {
                seconds += parseFloat(timePart);
            }
        });
    } else {
        seconds = parseFloat(elapsedTime);
    }

    let parts = [],
        hours = Math.floor(seconds / 3600),
        minutes = 0;

    if (hours) {
        parts.push(hours+'h');
        seconds -= hours*3600;
    }

    minutes = Math.floor(seconds / 60);
    if (minutes) {
        parts.push(minutes+'m');
        seconds -= minutes*60;
    }
    if (seconds) {
        parts.push(seconds+'s');

    }
    return parts.length ? parts.join(' ') : '0m 0s';
}