import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { withStyles, createTheme } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { CircularProgress, ThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { indigo } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import {
  fetchData,
  initializeNewSim,
  setEditSimStep,
  fetchProductList,
  deleteAssignmentDetails,
  saveSelectedSim,
  reset,
  resetVideoStats,
  fetchDeletedSims,
  saveCurrentSimProductType
} from "../../redux/sims/actions";
import StyledLink from "../../components/common/StyledLink";
import DetailsScreen from "../../components/sim/DetailsScreen.jsx";
import {
  getSimById,
  deleteSim,
  saveAvatarDetails,
} from "../../services/result.service";
import { sfReset} from '../../redux/sf/actions';
import { useLocation } from "react-router-dom";
import noSims from "../../assets/images/noSims.png";
import SimsPermissions from "../../permissions/sims";
import { permissionCheck } from "../../utils/permissionCheck";
import { useTableStyles, useToolbarStyles } from "./Sims";
import AddIcon from "@material-ui/icons/Add";
import { Redirect } from 'react-router-dom';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ProfilePic from '../../assets/images/profile_pic.png'
import Pagination from '@material-ui/lab/Pagination';
import {recoverSim, deleteAll} from '../../services/result.service'

const useQuery = () => new URLSearchParams(useLocation().search);
const innerTheme = createTheme({
  palette: {
    primary: indigo,
  },
});

const limit = 10;

const EnhancedTableToolbar = ({
  initializeNewSim,
  filterType,
  setFilterType,
  setTextSearch,
  products,
  setTrash,
  trash,
  setEmptyTrash,
  simsList,
}) => {
  const classes = useToolbarStyles();
  const [text, setText] = useState("");
  const search = (event) => {
    if (event.key === "Enter" || (event.type === "mousedown")) {
      setTextSearch(text);
    }
  };

  const handleChange = (event) => {
    setFilterType(event.target.value);
  };
  const trashSims = () => {
    setTrash(!trash);
  };
  const emptyTrash = () => {
    setEmptyTrash(true);
  }
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {trash ? 'Deleted Sims and Create Simulators' : 'Manage and Create Simulators'}
      </Typography>
      {!trash ?
       <div className={classes.search}>
       <InputBase
         placeholder="Search Simulators"
         classes={{
           root: classes.inputRoot,
           input: classes.inputInput,
         }}
         inputProps={{ "aria-label": "search" }}
         value={text}
         onChange={(e) => setText(e.target.value)}
         onKeyDown={(e) => search(e)}
       />
       <div>
         <Button onMouseDown={(e) => search(e)}><SearchIcon /></Button>
       </div>
     </div> : ''}
     {!trash ?
      <FormControl variant="outlined" size="small">
      <Select
        labelId="select-filter-type"
        id="simple-select-filter"
        value={filterType}
        onChange={handleChange}
        className={classes.select}
      >
        <MenuItem value="All" selected>
          All
        </MenuItem>
        {products.length > 0 &&
          products.map((product) => (
            <MenuItem key={product._id} value={product._id}>
              {product.name}
            </MenuItem>
          ))}
      </Select>
      </FormControl> : ''}
      { trash && simsList && simsList.length ?
        <Button
        className={classes.addNewButton}
        variant="contained"
        onClick={() => {
          emptyTrash();
        }}
      >
        <DeleteForeverIcon className={classes.plusIcon} />
        Empty Trash
      </Button> : ''}
      <Button
        variant="contained"
        className={classes.addNewButton}
        onClick={() => {
          trashSims();
        }}
      >
        {trash ? 'SIMS' : 'TRASH'}
      </Button>
      <Tooltip
        title={
          !permissionCheck(
            SimsPermissions.sim_list.create.task,
            SimsPermissions.sim_list.create.type
          )
            ? "You don't have permission to create"
            : ""
        }
        placement="bottom"
      >
        <span>
          <StyledLink to="/sims/createsim">
            <Button
              className={classes.addNewButton}
              variant="contained"
              disabled={
                !permissionCheck(
                  SimsPermissions.sim_list.create.task,
                  SimsPermissions.sim_list.create.type
                )
              }
              disableElevation
              onClick={() => {
                initializeNewSim();
              }}
            >
              <AddIcon className={classes.plusIcon} />
              ADD NEW SIM
            </Button>
          </StyledLink>
        </span>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  initializeNewSim: PropTypes.func,
  filterType: PropTypes.string,
  setFilterType: PropTypes.func,
  setTextSearch: PropTypes.func,
  products: PropTypes.array,
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Sims = ({
  totalSims,
  sims,
  fetchData,
  initializeNewSim,
  setEditSimStep,
  fetchProductList,
  deleteAssignmentDetails,
  saveSelectedSim,
  selectedSim,
  products,
  prop,
  reset,
  resetVideoStats,
  characters,
  sim_listing,
  userDetails,
  sfReset,
  deletedSims,
  fetchDeletedSims,
  totalDeletedSims,
  saveCurrentSimProductType
}) => {
  const query = useQuery();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(0);
  // const location = useLocation();
  const classes = useTableStyles();
  const [showDetailsScreen, setShowDetailsScreen] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [filterType, setFilterType] = useState("All");
  const [textSearch, setTextSearch] = useState("");
  const [simsList, setSimsList] = useState([]);
  const [assignmentStatus, setAssignmentStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentSim, setCurrentSim] = useState({});
  const [assignMentProps, setAssignmentProps] = useState(prop);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMesage, setAlertMessage] = React.useState("");
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = React.useState(null);
  const [order, setOrder] = React.useState(null);
  const [trash, setTrash] = useState(false);
  const [selectedRestoreSim, setSelectedRestoreSim] = useState();
  const [emptyTrash, setEmptyTrash] = useState(false);
  const [emptyListCheck, setEmptyListCheck] = useState(false);
  localStorage.setItem('sims_id', '');
  useEffect(() => {
    if (sims && sims.length > 0) {
      setSimsList(sims);
      reset();
      resetVideoStats();
    } else if (deletedSims &&  deletedSims.length > 0) {
      setSimsList(deletedSims);
      reset();
    }
  }, [sims, reset, resetVideoStats, deletedSims]);

  // get paginated sims data
  useEffect(() => {
    if (userDetails.role.toLowerCase() !== 'agent') {
      sfReset();
      if (trash) {
        fetchDeletedSims(limit, pageNumber);
      } else if (filterType === "All") {
        fetchData(limit, pageNumber, null, textSearch, sortBy, order);
      } else {
        fetchData(limit, pageNumber, filterType, textSearch, sortBy, order);
      }
    }
  }, [pageNumber, fetchData, filterType, textSearch, userDetails, sfReset, sortBy,
    order, trash, fetchDeletedSims]);

  useEffect(() => {
    setPageNumber(0);
    setSimsList([]);
    setPage(1);
  }, [filterType, textSearch, trash]);

  useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  const handleRowClick = async (row) => {
    const requestData = {}
    requestData.sim_id = row._id
    localStorage.setItem("sim_id", row._id);
    saveCurrentSimProductType(row.product_name);
    deleteAssignmentDetails();
    setAssignmentProps(null);
    setDetailsData(row);
    saveSelectedSim(row);
  };

  useEffect(() => {
    if (detailsData) {
      setShowDetailsScreen(true);
    }
  }, [detailsData]);

  useEffect(() => {
    if (!showDetailsScreen) {
      setDetailsData(null);
    }
  }, [showDetailsScreen]);

  if (prop.sim_id && !detailsData && !assignmentStatus) {
    getSimById(prop.sim_id).then((res) => {
      setDetailsData(res.data);
    });
    setAssignmentStatus(true);
  }

  const handleClick = async (row) => {
    if (!row.sim_characters.length) {
      for (const singleCharacter of characters) {
        singleCharacter.sim_id = row._id;
      }
      const requestData = { sim_characters: characters };
      await saveAvatarDetails(requestData);
    }
    saveCurrentSimProductType(row.product_name);
    await setEditSimStep();
    history.push(`/sims/createsim?simId=${row._id}`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteConfirm = async () => {
    const requestData = {};
    requestData.sim_id = currentSim._id;
    const deletesimStatus = await deleteSim(requestData);
    if (!deletesimStatus.data.success) {
      setAlertMessage(deletesimStatus.data.message);
      setOpenAlert(true);
    }
    setSimsList([]);
    setOpen(false);
    setPageNumber(0);
    if (userDetails.role.toLowerCase() !== 'agent') {
      const skip = page - 1;
      if (filterType === "All") {
        fetchData(limit, skip, null, textSearch, sortBy, order);
      } else {
        fetchData(limit, skip, filterType, textSearch, sortBy, order);
      }
    }
  };
  const restoreConfirm = async () => {
    const recoveredSimStatus = await recoverSim(selectedRestoreSim._id);
     if (recoveredSimStatus.data.success) {
         setSimsList([]);
         const skip = page - 1;
         await fetchDeletedSims(limit, skip);
         setOpen(false);
         setPageNumber(0);
     }
   };
  const handleDeleteClick = (row) => {
    setOpen(true);
    setCurrentSim(row);
  };

  useEffect(() => {
    if (query.get("exit")) {
      if (Object.keys(selectedSim).length > 0) {
        setDetailsData(selectedSim);
      }
      if (assignMentProps && assignMentProps.type === undefined) {
        setAssignmentProps({
          ...assignMentProps,
          type: query.get("type"),
        });
      }
    }
  }, [setDetailsData, query, selectedSim, assignMentProps]);

  useEffect(() => { }, [sim_listing]);
  useEffect(() =>{
    if (emptyTrash) {
      setOpen(true);
    }
    if(deletedSims && deletedSims.length === 0) {
      setTimeout(() => {
        setEmptyListCheck(true);
      }, 3000);
    }
  }, [emptyTrash, deletedSims])
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setAlertMessage("");
  };

  if (userDetails.role.toLowerCase() === 'agent' && !prop.sim_id) {
    return (
      <Redirect to={'/dashboard'} />
    );
  }
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    const skip = (newPage - 1);
    if (trash) {
      fetchDeletedSims(limit, skip);
    }else if (filterType === "All") {
      fetchData(limit, skip, null, textSearch, sortBy, order);
    } else {
      fetchData(limit, skip, filterType, textSearch, sortBy, order);
    }
  };
  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    return isAsc ? 'desc' : 'asc';
  };
  const sortProperties = (property) => {
    handleRequestSort(property);
    setPageNumber(0);
    setSortBy(property);
    setSimsList([]);
  };
  const restoreSim = (row) => {
    setSelectedRestoreSim(row);
    setOpen(true);
  }
  const deleteAllConfirm = async() => {
    const result = await deleteAll();
    if (result.data.success) {
      setOpen(false);
      setEmptyTrash(false);
      setSimsList([]);
      setTimeout(() => {
        setEmptyListCheck(true);
      }, 3000);
    }
  }
  return (
    <>
      <Paper className={userDetails.role.toLowerCase() === 'agent' ? classes.hidePage : classes.paper}>
        <EnhancedTableToolbar
          initializeNewSim={initializeNewSim}
          filterType={filterType}
          setFilterType={setFilterType}
          setTextSearch={setTextSearch}
          products={products || []}
          trash={trash}
          setTrash={setTrash}
          setEmptyTrash={setEmptyTrash}
          simsList={simsList}
        />
        {showDetailsScreen && (
          <DetailsScreen
            sim={detailsData}
            open={showDetailsScreen}
            handleClose={setShowDetailsScreen}
            assignmentData={assignMentProps}
          />
        )}
        {openAlert ? (
          <Alert variant="filled" severity="error" onClose={handleAlertClose} className={classes.errorAlert}>
            {alertMesage}
          </Alert>
        ) : null}

        {sim_listing && simsList && simsList.length === 0 ?
        emptyListCheck ?
        <h4 className={classes.noDataFound}>
          Looks empty over here!
        </h4> :(
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Paper >
            <TableContainer component={Paper} className={classes.root}>
              <Table aria-label="sims table">
                <TableHead className={classes.thead}>
                  <TableRow>
                    <TableCell className={classes.heading} sortDirection={sortBy === 'title' ? order : false}>
                      <TableSortLabel
                        direction={sortBy === 'title' ? order : 'asc'}
                        active
                        IconComponent={ArrowDropDownIcon}
                        onClick={() => sortProperties('title')}
                      >
                        CALL SIMULATOR
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading} >
                      <TableSortLabel
                        direction={sortBy === 'type' ? order : 'asc'}
                        active
                        IconComponent={ArrowDropDownIcon}
                        onClick={() => sortProperties('type')}
                      >
                        TYPE
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction={sortBy === 'created_at' ? order : 'asc'}
                        active
                        IconComponent={ArrowDropDownIcon}
                        onClick={() => sortProperties('created_at')}
                      >
                        CREATED
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction={sortBy === 'updated_at' ? order : 'asc'}
                        active
                        IconComponent={ArrowDropDownIcon}
                        onClick={() => sortProperties('updated_at')}
                      >
                        UPDATED
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction={sortBy === 'admin_note' ? order : 'asc'}
                        active
                        IconComponent={ArrowDropDownIcon}
                        onClick={() => sortProperties('admin_note')}
                      >
                        ADMIN NOTES
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading} padding="none">
                      <TableSortLabel
                        direction={sortBy === 'draft' ? order : 'asc'}
                        active
                        IconComponent={ArrowDropDownIcon}
                        onClick={() => sortProperties('draft')}
                      >
                        DRAFT
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>
                      <TableSortLabel
                        direction={sortBy === 'first_name' ? order : 'asc'}
                        active
                        IconComponent={ArrowDropDownIcon}
                        onClick={() => sortProperties('first_name')}
                      >
                        BUILT BY
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.heading}>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {simsList.length > 0
                    ? simsList.map((row, index) => (
                      <StyledTableRow
                        key={index}
                        className={classes.tableRow}
                      >
                        <TableCell className={classes.bold}>
                          {row.screen_recording && row.screen_recording.length ?
                              <Tooltip
                                  title="Has Screen Recording Enabled"
                                  placement="top"
                              >
                                <div style={{marginRight: '1em', verticalAlign: 'middle', display: 'inline-block'}}>
                                    <ScreenShareIcon fontSize="small"/>
                                </div>
                              </Tooltip> :
                              <></>
                          }
                          {row.title}
                        </TableCell>
                        <TableCell className={classes.type}>
                          {products &&
                            products.map((product) => {
                              return row.product_id === product._id
                                ? product.name
                                : null;
                            })}
                        </TableCell>
                        <TableCell className={classes.created}>
                          {moment(row.created_at).format("MMM DD YYYY")}
                        </TableCell>
                        <TableCell className={classes.type}>
                          {moment(row.updated_at).startOf("minute").fromNow()}
                        </TableCell>
                        <TableCell
                          title={
                            row.admin_note?.length > 120
                              ? row.admin_note
                              : null
                          }
                          className={classes.admin_note}
                        >
                          {row?.admin_note?.length > 120
                            ? `${row.admin_note.slice(0, 120)}  ...`
                            : row.admin_note}
                        </TableCell>
                        <TableCell padding="none">
                          {row.draft ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          <div className={classes.built}>
                            <Avatar
                              src={
                                row.profile_pic_url ? row.profile_pic_url : ProfilePic
                              }
                              alt="profile"
                              style={{ marginRight: "10px" }}
                              className={classes.small}
                            />
                            <div>{`${row.first_name} ${row.last_name}`}</div>
                          </div>
                        </TableCell>
                        <TableCell key={`actions_${row.id}`}>
                          <div className={classes.built}>
                            {trash ?
                              <Tooltip
                              title="Restore"
                              placement="top"
                              >
                                <span>
                                  <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      className={classes.buttonSqr}
                                      disableElevation
                                      onClick={() =>restoreSim(row)}
                                  >
                                      <RestoreFromTrashIcon
                                      fontSize="small"
                                      />
                                  </Button>
                                </span>
                              </Tooltip>:
                              <>
                                <ThemeProvider theme={innerTheme}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={classes.buttonSqr}
                                  disableElevation
                                >
                                  <VisibilityIcon
                                    onClick={() => handleRowClick(row)}
                                    fontSize="small"
                                  />
                                </Button>
                                </ThemeProvider>
                                <Tooltip
                                  title={
                                    !permissionCheck(
                                      SimsPermissions.sim_list.update.task,
                                      SimsPermissions.sim_list.update.type
                                    )
                                      ? "You don't have permission to update"
                                      : ""
                                  }
                                  placement="bottom"
                                >
                                  <span>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      disabled={
                                        !permissionCheck(
                                          SimsPermissions.sim_list.update.task,
                                          SimsPermissions.sim_list.update.type
                                        )
                                      }
                                      className={classes.buttonSqr}
                                      disableElevation
                                    >
                                      <EditIcon
                                        onClick={() => handleClick(row)}
                                        fontSize="small"
                                      />
                                    </Button>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    !permissionCheck(
                                      SimsPermissions.sim_list.delete.task,
                                      SimsPermissions.sim_list.delete.type
                                    )
                                      ? "You don't have permission to delete"
                                      : ""
                                  }
                                  placement="bottom"
                                >
                                  <span>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className={classes.buttonDelete}
                                      disabled={
                                        !permissionCheck(
                                          SimsPermissions.sim_list.delete.task,
                                          SimsPermissions.sim_list.delete.type
                                        )
                                      }
                                      disableElevation
                                    >
                                      <DeleteIcon
                                        onClick={() => handleDeleteClick(row)}
                                        fontSize="small"
                                      />
                                    </Button>
                                  </span>
                                </Tooltip>
                              </>
                            }
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              <div className={classes.paginationLabel}>
                Showing
                {' '}
                {(page - 1) * 10 + 1}
                {' '}
                to
                {' '}
                {(page - 1) * 10
                  + (simsList && simsList.length ? simsList.length : 0)}
                {' '}
                of
                {' '}
                {trash ? totalDeletedSims ? totalDeletedSims : 0 : totalSims ? totalSims : 0}
                {' '}
                entries
              </div>
              <Pagination
              className={classes.pagination}
              color="primary"
              count={
                trash && totalDeletedSims ?
                Math.ceil(totalDeletedSims / 10) :
                totalSims
                  ? Math.ceil(totalSims / 10)
                  : 0
              }
              shape="rounded"
              page={page}
              onChange={handlePageChange}
            />
            </div>
         </Paper>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{trash ? emptyTrash ? 'Delete All' : 'Restore Sim' : 'Delete Sim'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {trash ? emptyTrash ?
              `Are you sure you want to permanently delete all the sims from deleted list` : `Do you want to restore the sim "${selectedRestoreSim && selectedRestoreSim.title}"?` :
              `Do you want to delete the sim "${currentSim.title}"?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className={classes.cancelButton}>
              Cancel
            </Button>
            {
              trash ?
              emptyTrash ?
              <Button
              onClick={deleteAllConfirm}
              className={classes.dialogDeleteBtn}
              autoFocus
            >
              Delete All
            </Button> :
              <Button
              onClick={restoreConfirm}
              className={classes.dialogDeleteBtn}
              autoFocus
            >
              Restore
            </Button> :
            <Button
            onClick={() => deleteConfirm()}
            className={classes.dialogDeleteBtn}
            autoFocus
          >
            Delete
          </Button>
            }
          </DialogActions>
        </Dialog>
      </Paper>
      {!sim_listing && simsList.length === 0 ? (
        <div className={userDetails.role.toLowerCase() === 'agent' ? classes.hidePage : classes.noData}>
          <img src={noSims} height={55} width={54} alt="noSims" />
          <h3 className={classes.noAssign}>There are no SIMs added yet!</h3>
          <p className={classes.noAssignPara}>
            Are you up for creating new SIM now?
          </p>
          <StyledLink to="/sims/createsim">
            <Button
              variant="outlined"
              color="primary"
              className={classes.addButton}
              onClick={() => {
                initializeNewSim();
              }}
            >
              <span className={classes.plus}>+</span> ADD NEW SIM
            </Button>
          </StyledLink>
        </div>
      ) : null}
    </>
  );
};

Sims.propTypes = {
  totalSims: PropTypes.number,
  sims: PropTypes.array,
  fetchData: PropTypes.func,
  initializeNewSim: PropTypes.func,
  setEditSimStep: PropTypes.func,
  fetchProductList: PropTypes.func,
  deleteAssignmentDetails: PropTypes.func,
  saveSelectedSim: PropTypes.func,
  products: PropTypes.array,
  prop: PropTypes.object,
  selectedSim: PropTypes.object,
  reset: PropTypes.func,
  resetVideoStats: PropTypes.func,
  sim_listing: PropTypes.bool,
  userDetails: PropTypes.object,
  sfReset: PropTypes.func,
  saveCurrentSimProductType: PropTypes.func
};
const mapStateToProps = (state, props) => ({
  isLoading: state.sim.isLoading,
  totalSims: state.sim.total,
  sims: state.sim.data,
  status: state.sim.status,
  products: state.sim.products,
  prop: props && props.location.state ? props.location.state : localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : {},
  selectedSim: state.sim.selectedSim,
  characters: state.sim.newSim.sim_characters,
  sim_listing: state.sim.sim_listing,
  userDetails: state.login.user,
  deletedSims: state.sim.deletedSims && state.sim.deletedSims.sims,
  totalDeletedSims: state.sim.deletedSimsCount && state.sim.deletedSimsCount,
});

const mapDispatchToProps = {
  fetchData,
  initializeNewSim,
  setEditSimStep,
  fetchProductList,
  deleteAssignmentDetails,
  saveSelectedSim,
  reset,
  resetVideoStats,
  sfReset,
  fetchDeletedSims,
  saveCurrentSimProductType
};

// connect Sims component to redux store

export default connect(mapStateToProps, mapDispatchToProps)(Sims);
