import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import {
  useStyle,
} from './CreateLevels';
import {
  generateSim,
  fetchSimDetails,
} from '../../../redux/sims/actions';

const useQuery = () => new URLSearchParams(useLocation().search);
const GenerateSim = ({
  newSim,
  currentIndex,
  data,
  tabs,
  setTotalLevel,
  totalLevel,
  setSimGenrated,
  setLevelSelect,
  levelSelect,
  handleClose,
  setLoad,
  setFlag,
  generateSim,
  setAlertType,
  setAlertMessage,
  setOpenAlert,
}) => {
  const classes = useStyle();
  const [disableButton, setGenerateSim] = useState(true);
  const [newLevels, setNewLevels] = React.useState('');
  const [defLevels] = React.useState([
    'Watch and Learn',
    'Watch the experts',
    'Try w/ scripts',
    'Try w/ keywords',
    'Try w/o help',
  ]);
  const [generateClicked, setGenerateClicked] = useState(false);
  const query = useQuery();
  const showGenerateSim = () => {
    setGenerateSim(false);
  };

  useEffect(() => {
    if (
      newSim.levels[currentIndex]
      && newSim.levels[currentIndex].scripts.length >= 1
    ) {
      showGenerateSim();
    }
  });

  const enableIcon = () => {
    let coachid;
    let agentid;
    setGenerateClicked(false);
    newSim.sim_characters.forEach((char) => {
      if (char.type === 'agent') {
        agentid = char._id;
      }
      if (char.type === 'coach') {
        coachid = char._id;
      }
    });
    const { length } = data.levels;
    const genLevels = [];
    for (let i = 0; i < 4; i += 1) {
      const obj = {};
      obj.name = defLevels[i + 1];
      obj.order = data.levels[length - 1].order + i + 1;
      obj.sim_id = newSim.id;
      obj.hint_text = false;
      obj.keyword_score = false;
      obj.coachId = coachid;
      obj.agentId = agentid;
      obj.scripts = [];
      obj.generateLevel = true;
      if (i === 0) {
        obj.removecoach = true;
        obj.includekeywords = false;
        obj.hinttext = false;
        obj.removeagent = false;
        obj.convertagent = false;
        obj.autopopulate = true;
        obj.autopopulate_data = tabs;
      }
      if (i === 1) {
        obj.removecoach = false;
        obj.includekeywords = false;
        obj.hinttext = false;
        obj.removeagent = false;
        obj.convertagent = true;
        obj.autopopulate = false;
        obj.autopopulate_data = tabs;
      }
      if (i === 2) {
        obj.removecoach = false;
        obj.includekeywords = true;
        obj.removeagent = false;
        obj.hinttext = false;
        obj.convertagent = true;
        obj.autopopulate = false;
        obj.autopopulate_data = tabs;
      }
      if (i === 3) {
        obj.removeagent = true;
        obj.includekeywords = false;
        obj.hinttext = true;
        obj.removecoach = true;
        obj.convertagent = true;
        obj.autopopulate = false;
        obj.autopopulate_data = tabs;
      }
      genLevels.push(obj);
    }
    setTotalLevel(totalLevel + genLevels.length);
    setNewLevels(genLevels);
    setSimGenrated(true);
    setLevelSelect(true);
  };

  const handleLevelName = (event, index) => {
    const arr = [...newLevels];
    arr[index].name = event.target.value;
    setNewLevels(arr);
  };

  const handleGenerateCheckbox = (event, index) => {
    const arr = [...newLevels];
    if (event.target.checked === true) {
      arr[index].generateLevel = true;
      setTotalLevel(totalLevel + 1);
    } else {
      setTotalLevel(totalLevel - 1);
      arr[index].generateLevel = false;
    }
    setNewLevels(arr);
  };

  const handleRemCoachCheckbox = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].removecoach = true;
        setNewLevels(arr);
        return;

      case false:
        arr[index].removecoach = event.target.checked;
        setNewLevels(arr);
        return;
      default:
        setNewLevels(newLevels);
    }
  };

  const handlekeywordsCheckbox = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].scripts = [];
        arr[index].includekeywords = true;
        setNewLevels(arr);
        return;

      case false:
        arr[index].includekeywords = false;
        arr[index].scripts = [];
        setNewLevels(arr);
        return;

      default:
        setNewLevels(arr);
    }
  };

  const handleRemAgentCheckbox = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].removeagent = true;
        setNewLevels(arr);
        return;
      case false:
        arr[index].removeagent = event.target.checked;
        setNewLevels(arr);
        return;
      default:
        setNewLevels(arr);
    }
  };

  const handleCheckboxSelect = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].convertagent = true;
        setNewLevels(arr);
        return;
      case false:
        arr[index].convertagent = false;
        setNewLevels(arr);
        return;
      default:
        setNewLevels(arr);
    }
  };

  const handleHintTextCheckbox = (event, index) => {
    const arr = [...newLevels];
    const levels = data.levels.slice(0, 1);
    switch (event.target.checked) {
      case true:
        arr[index].hinttext = true;
        for (let i = 0; i < arr[index].scripts.length; i += 1) {
          arr[index].scripts[i].hint_text = levels[0].scripts[i].hint_text;
        }
        setNewLevels(arr);
        return;
      case false:
        arr[index].hinttext = false;
        for (let i = 0; i < arr[index].scripts.length; i += 1) {
          arr[index].scripts[i].hint_text = '';
        }
        setNewLevels(arr);
        return;
      default:
        setNewLevels(arr);
    }
  };
  const handleAutopopulateCheckbox = (event, index) => {
    const arr = [...newLevels];
    switch (event.target.checked) {
      case true:
        arr[index].autopopulate = true;
        setNewLevels(arr);
        return;
      case false:
        arr[index].autopopulate = false;
        setNewLevels(arr);
        return;
      default:
        setNewLevels(arr);
    }
  };

  const handleCancel = () => {
    setLevelSelect(false);
    setTotalLevel(data.levels.length);
  };
  const handleDialogGenerate = async () => {
    setLoad(true);
    const arr = [...newLevels];
    for (let i = arr.length - 1; i >= 0; i -= 1) {
      if (arr[i].generateLevel === false) {
        arr.splice(i, 1);
      }
    }
    for (let i = 0; i < arr.length; i += 1) {
      delete arr[i].generateLevel;
    }
    setGenerateClicked(true);
    setLevelSelect(false);
    setFlag(true);
    await generateSim(arr, data.levels[0]._id);
    setNewLevels('');
    const simId = query.get('simId') ? query.get('simId') : data.id;
    await fetchSimDetails(simId);
    setLoad(false);
    setAlertType('success');
    setAlertMessage('Sim levels generated successfully!!!');
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 3000);
  };

  return (
    <>
      <Button
        disabled={disableButton}
        variant="outlined"
        className={classes.generateSimBtn}
        onClick={enableIcon}
      >
        GENERATE SIM
      </Button>
      <Dialog
        open={levelSelect}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Generate Sim</DialogTitle>
        <DialogContent>
          { newLevels
            && newLevels.map((level, index) => (
              <FormControl
                key={index}
                component="fieldset"
                className={classes.dialogFormControl}
              >
                <TextField
                  fullWidth
                  label="Title"
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  value={level.name}
                  variant="outlined"
                  onChange={(event) => handleLevelName(event, index)}
                />
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(event) => handleGenerateCheckbox(event, index)}
                        name="generate"
                        color="primary"
                        checked={level.generateLevel}
                      />
                    )}
                    label="Generate this level"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(event) => handleRemCoachCheckbox(event, index)}
                        name="removecoach"
                        color="primary"
                        checked={level.removecoach === true}
                      />
                    )}
                    label="Remove coach"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(event) => handlekeywordsCheckbox(event, index)}
                        name="keywords"
                        color="primary"
                        checked={level.includekeywords === true}
                      />
                    )}
                    label="Only include keywords"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(event) => handleRemAgentCheckbox(event, index)}
                        name="removeagent"
                        color="primary"
                        checked={level.removeagent === true}
                      />
                    )}
                    label="Remove Agent Script"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(event) => handleCheckboxSelect(event, index)}
                        name="convertagent"
                        color="primary"
                        checked={level.convertagent === true}
                      />
                    )}
                    label="Convert Agent Clips to Prompts"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={(event) => handleHintTextCheckbox(event, index)}
                        name="hinttext"
                        color="primary"
                        checked={level.hinttext === true}
                      />
                    )}
                    label="Include Hint Text"
                  />
                  {data.auto_populate ? (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          onChange={(event) => handleAutopopulateCheckbox(event, index)}
                          name="autopopulate"
                          color="primary"
                          checked={level.autopopulate === true}
                        />
                        )}
                      label="Include Auto-populate"
                    />
                  ) : null}
                </FormGroup>
              </FormControl>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} className={classes.cancelButton}>
            Cancel
          </Button>
          <Tooltip
            title={totalLevel > 5
              ? 'More than 5 levels are not allowed for a sim.'
              : ''}
            enterDelay={500}
            leaveDelay={200}
          >
            <span>
              <Button
                color="primary"
                className={classes.generateButton}
                onClick={handleDialogGenerate}
                disabled={totalLevel > 5 || generateClicked}
              >
                Generate
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
};

GenerateSim.propTypes = {
  newSim: PropTypes.object,
  currentIndex: PropTypes.number,
  data: PropTypes.object,
  tabs: PropTypes.array,
  setTotalLevel: PropTypes.func,
  totalLevel: PropTypes.number,
  setSimGenrated: PropTypes.func,
  setLevelSelect: PropTypes.func,
  levelSelect: PropTypes.bool,
  handleClose: PropTypes.func,
  setLoad: PropTypes.func,
  setFlag: PropTypes.func,
  generateSim: PropTypes.func,
  setAlertType: PropTypes.func,
  setAlertMessage: PropTypes.func,
  setOpenAlert: PropTypes.func,
};
const mapStateToProps = (state) => ({
  newSim: state.sim.newSim,
  tabs: state.sim.autoPopulate,
});

const mapDispatchToProps = {
  generateSim,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateSim);
