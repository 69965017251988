/* eslint-disable no-param-reassign */
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import { connect } from 'react-redux';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Button,
} from '@material-ui/core';
import { simWizard } from './SimWizard';
import WrapUpTimer from "../WrapUpTimer";
import LinearProgress from "@material-ui/core/LinearProgress";

const WrapUpDialog = ({
    open,
    doneButtonHandler,
    uploadPercent,
}) => {
    const useStyles = makeStyles((theme) => simWizard(theme));
    const classes = useStyles();

    const [showProgressBar, setShowProgressBar] = React.useState(false);

    // Closing modal option
    const handleClose = (ev, reason) => {
        if (reason && (reason === "backdropClick")) {
            return;
        }

        setShowProgressBar(true);
        doneButtonHandler && doneButtonHandler();
    };

    return (
        <Dialog
            className={classes.modal}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="xs"
            scroll="paper"
        >
            <DialogTitle className={classes.label} id="form-dialog-title">
                Wrap Up Activities
            </DialogTitle>
            <hr className={classes.hr}/>
            <DialogContent className={classes.modalContent}>
                <WrapUpTimer />

                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.grid}>
                        <div>
                            <Typography variant="body1" align="left">
                                Please complete any wrap up tasks, then come back here and click the "All Done" button.
                            </Typography>
                        </div>
                    </Grid>
                    {showProgressBar ? (
                        <Grid item xs={12} className={classes.grid}>
                            <div>
                                <Typography variant="body1" align="left">
                                    Your screen recording is being uploaded... Please wait, this may take some time
                                    depending on the speed and quality of your internet service.
                                </Typography>
                                <br />
                                <LinearProgress value={uploadPercent} color={"primary"} variant={"determinate"}></LinearProgress>
                            </div>
                        </Grid>
                    ) : null}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                    disabled={showProgressBar}
                >
                    All Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

WrapUpDialog.propTypes = { };
const mapStateToProps = (state) => ({ });
const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(WrapUpDialog);
