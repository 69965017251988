import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    Tooltip
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, {useEffect, useState} from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import VolumeUpRoundedIcon from "@material-ui/icons/VolumeUpRounded";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PropTypes from "prop-types";
import {
    generateAudio,
    realTimeFeedback,
    sendToSnowfly,
    toggleScreenRecordingSwitch,
    updateHintText,
    updateKeywordScore,
} from "../../../services/result.service";
import PassFailCriteria from "./PassFailCriteria";
import debug from "../../../utils/debug";

const LevelOptions = ({
    data,
    setData,
    updateData,
    levelIndex,
    singleLevel,
    copyHintText,
    classes,
    passFailLevel,
    onOptionChange,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    setLoad
}) => {
    const [levelTitle, setLevelTitle] = useState('');
    const [open, setOpen] = useState(false);
    const [levelSettingsTooltip, setLevelSettingsTooltip] = useState([]);
    const [importHint, setImportHint] = useState(false);
    const [keywordScore, setKeywordScore] = useState(false);
    const [hintText, setHintText] = useState(false);
    const [realTimeFeedbackEnabled, setRealTimeFeedbackEnabled] = useState(false);
    const [sendsToSnowfly, setSendsToSnowfly] = useState(false);
    const [screenRecording, setScreenRecording] = useState(false);
    const [passDialog, setPassDialog] = useState(false);
    const [audioOpen, setAudioOpen] = useState(false);
    const [audioCount, setAudioCount] = React.useState(0);
    const [isPassFail, setIsPassFail] = React.useState(false);

    useEffect(() => {
        let level = data?.levels[levelIndex] ?? null;

        if (level) {
            debug('Level = %o', level);

            setLevelTitle(level?.name);
            setIsPassFail(passFailLevel && (passFailLevel === level?._id));
            setImportHint(level?.order !== 0 ?? false);
            setKeywordScore(level?.keyword_score ?? false);
            setHintText(level?.hint_text ?? false);
            setRealTimeFeedbackEnabled(level?.real_time_feedback ?? false);
            setSendsToSnowfly(level?.send_to_snowfly ?? false);
            setScreenRecording(level?.screenRecording ?? false);

            let tooltip = [
                {text: 'Import Hints from Level 1 is ', status: level?.order !== 0 ?? false},
                {text: 'Keyword Scores are ', status: level?.keyword_score ?? false},
                {text: 'Text Hints are ', status: level?.hint_text ?? false},
                {text: 'Real time feedback is ', status: level?.real_time_feedback ?? false},
                {text: 'Send full audio recording to SnowFly is ', status: level?.send_to_snowfly ?? false},
                {text: 'Allow screen recording is ', status: level?.screenRecording ?? false},
                {text: 'Pass/Fail is ', status: (passFailLevel && (passFailLevel === level?._id)) ?? false},
            ];

            setLevelSettingsTooltip(tooltip);
        }
    }, [data, levelIndex, singleLevel, setLevelSettingsTooltip, setLevelTitle, setImportHint, setKeywordScore, setHintText,
        setRealTimeFeedbackEnabled, setSendsToSnowfly, setScreenRecording, passFailLevel, setIsPassFail]);

    const emitOptionChanged = (optionName, value, success = false) => {
        let evt = new CustomEvent(
            'option-changed',
            {
                detail: {
                    levelIndex: levelIndex,
                    level: data?.levels[levelIndex] ?? null,
                    option: optionName,
                    value: value,
                    success: success
                }
            }
        );

        if (onOptionChange) {
            onOptionChange(evt);
        }
    }

    const handleClose = (evt) => {
        setOpen(false);
    }

    const handlePassDialog = (text) => {
        if (text === "open") {
            setPassDialog(true);
        }
        if (text === "close") {
            setPassDialog(false);
        }
    };

    const handleSettingsClick = (evt) => {
        setOpen(!open);
    }

    const handleImportHint = (evt) => {
        setImportHint(evt.target.checked);
        copyHintText(evt);
    }

    const handleKeywordChange = async (evt) => {
        const checked = evt.target.checked;
        let changeData = {};
        changeData.sim_id = data.id;
        changeData.keyword_score = checked;
        changeData.level_id = data.levels[levelIndex]._id;
        data.levels[levelIndex].keyword_score =  checked;
        try {
            let result = await updateKeywordScore(changeData);
            if (result.success) {
                setKeywordScore(checked);
                const sim = {...data};
                sim.levels[levelIndex].keyword_score = checked;
                setData(sim);
                emitOptionChanged('keyword_score', checked, true);
            } else {
                emitOptionChanged('keyword_score', checked, false);
            }
        } catch (error) {
            emitOptionChanged('keyword_score', checked, false);
        }
    }

    const handleHintTextChange = async (evt) => {
        const checked = evt.target.checked;
        let changeData = {};
        changeData.sim_id = data.id;
        changeData.hint_text =  checked;
        changeData.level_id = data.levels[levelIndex]._id;
        data.levels[levelIndex].hint_text =  checked;

        let result = await updateHintText(changeData);
        if (result.success) {
            setHintText(checked);
            let sim = {...data};
            sim.levels[levelIndex].hint_text = checked ? true : false;
            setData(sim);
            emitOptionChanged('hint_text', checked, true);
        } else {
            emitOptionChanged('hint_text', checked, false);
        }
    }

    const handleRealTimeChange = async (evt) => {
        const sim = { ...data },
            propName = 'real_time_feedback',
            requestData = {},
            selectedCharacter = sim.sim_characters.find(
                (item) => item.type === "agent"
            ),
            checked = !!evt.target.checked;
        let count = 0;

        requestData.scripts = [];
        requestData.sim_id = data.id;
        requestData.real_time_feedback = checked;
        requestData.level_id = data.levels[levelIndex]._id;
        if (data.id && data.levels[levelIndex].scripts.length > 0) {
            setLoad && setLoad(true);
            for (let i = 0; i < sim.levels[levelIndex].scripts.length; i++) {
                if (
                    sim.levels[levelIndex].scripts[i].sim_character_id ===
                    selectedCharacter._id
                ) {
                    count++;
                    requestData.scripts.push(sim.levels[levelIndex].scripts[i]._id);
                    sim.levels[levelIndex].real_time_feedback = checked;
                    sim.levels[levelIndex].scripts[i].real_time_feedback = checked;
                }
                setData(sim);
            }
            if (count === 0) {
                setLoad && setLoad(false);
                emitOptionChanged(propName, checked, false);
            } else {
                const res = await realTimeFeedback(requestData);
                if (res.success) {
                    setLoad && setLoad(false);
                    emitOptionChanged(propName, checked, true);
                } else {
                    setLoad && setLoad(false);
                    emitOptionChanged(propName, checked, false);
                }
            }
        } else {
            emitOptionChanged(propName, checked, false);
        }
    }

    const handleSendToSnowflyChange = async (evt) => {
        const checked = !!evt.target.checked,
            propName = 'send_to_snowfly';

        try {
            const res = await sendToSnowfly({
                simId: data?.levels[levelIndex]?.sim_id,
                levelId: data?.levels[levelIndex]?._id,
                sendToSnowfly: checked,
            });

            setSendsToSnowfly(checked);

            emitOptionChanged(propName, checked,res.success);
        } catch (err) {
            debug(err);
            emitOptionChanged(propName, checked, false);
        }
    }

    const handleScreenRecordingChange = async (evt) => {
        const sim = { ...data },
            checked = !!evt.target.checked,
            propName = screenRecording;

        setScreenRecording(evt.target.checked);

        sim.levels[levelIndex].screenRecording = checked;

        try {
            const res = await toggleScreenRecordingSwitch({
                levelId: sim.levels[levelIndex]._id,
                screenRecording: checked,
            });
            setData(sim);
            emitOptionChanged(propName, checked, res.success);
        } catch (err) {
            debug(err);
            emitOptionChanged(propName, checked, false);
        }
    }

    const getAudioCount = () => {
        const result = data.levels[levelIndex].scripts.filter(
            (script) => script.audio_url !== ""
        );
        if (result.length <= 0) {
            handleLevelAudioGenerate();
        } else {
            setAudioCount(result.length);
            setAudioOpen(true);
        }
    };

    const handleLevelAudioGenerate = async () => {
        setAudioOpen(false);
        setLoad && setLoad(true);
        const sim = { ...data };
        for (let i = 0; i < sim.levels[levelIndex].scripts.length; i++) {
            const character = data.sim_characters.find(
                (item) =>
                    item._id === sim.levels[levelIndex].scripts[i].sim_character_id
            );
            if (
                character.type === "agent" &&
                !sim.levels[levelIndex].scripts[i].agent_recorded_audio
            ) {
                if (sim.levels[levelIndex].order === 0 || sim.levels[levelIndex].order === 1) {
                    const req = {};
                    req.sim_script_id = sim.levels[levelIndex].scripts[i]._id;
                    req.sim_id = sim.levels[levelIndex].scripts[i].sim_id;
                    req.voice = character.voice;
                    const response = await generateAudio(req);
                    sim.levels[levelIndex].scripts[i].audio_url = response.audio_url;
                    sim.levels[levelIndex].scripts[i].expiry_date = response.expiry_date;
                    sim.levels[levelIndex].scripts[i].audio_key = response.audio_key;
                    sim.levels[levelIndex].scripts[i].agent_recorded_audio = true;
                    sim.levels[levelIndex].scripts[i].voice = character.voice;
                    setData(sim);
                    await updateData(i, sim.levels[levelIndex].scripts[i]);
                }
                // If the character type is 'agent' and enabled to prompt to record, then do not generate audio for that script
            } else {
                const req = {};
                req.sim_script_id = sim.levels[levelIndex].scripts[i]._id;
                req.sim_id = sim.levels[levelIndex].scripts[i].sim_id;
                req.voice = character.voice;
                const response = await generateAudio(req);
                sim.levels[levelIndex].scripts[i].audio_url = response.audio_url;
                sim.levels[levelIndex].scripts[i].expiry_date = response.expiry_date;
                sim.levels[levelIndex].scripts[i].audio_key = response.audio_key;
                sim.levels[levelIndex].scripts[i].voice = character.voice;
                if (sim.levels[levelIndex].order === 0 || sim.levels[levelIndex].order === 1) {
                    sim.levels[levelIndex].scripts[i].agent_recorded_audio = true
                }
                setData(sim);
                await updateData(i, sim.levels[levelIndex].scripts[i]);
            }
        }
        setLoad && setLoad(false);
    };

    const handleGenerateAudioClick = (evt) => {
        if (data?.levels[levelIndex] && data?.levels[levelIndex]?.scripts?.length) {
            getAudioCount();
        }
    }

    const settingsTooltip = () => {
        return (
            <>{levelSettingsTooltip.map((tip, localIndex) => {
                return (<p style={{fontSize: '1rem'}} key={localIndex}>
                    {tip.text} {(tip.status ? <span style={{color: 'green'}}>Enabled</span> :
                    <span style={{color: 'red'}}>Disabled</span>)} for this Level</p>);}
            )}</>
        );
    }

    return (
        <>
            {isPassFail && (
                <Tooltip style={{fontSize: '1rem'}} title={"This level is marked as the Pass/Fail for the SIM"}>
                    <Button className={classes.closeButton} style={{padding: '0.25em', width: 'fit-content', height: '100%', minWidth: 'unset'}}>
                        <AssignmentTurnedInIcon style={{color: '#2CB174', fontSize: '1rem', height: '100%', width: '1.25rem'}}></AssignmentTurnedInIcon>
                    </Button>
                </Tooltip>
            )}
            <Tooltip title={settingsTooltip()} style={{fontSize: '1rem'}}>
                <Button className={classes.closeButton} style={{padding: '0.25em', width: 'fit-content', height: '100%', minWidth: 'unset'}}>
                    <SettingsIcon onClick={handleSettingsClick} style={{fontSize: '1rem', width: '1.25rem', height: '100%'}}/>
                </Button>
            </Tooltip>
            <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
                <DialogTitle>
                    Settings for level {levelTitle}
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                        style={{float: "right"}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <Box className={classes.iconBox}>
                            {(levelIndex > 0 &&
                                <FormGroup>
                                    <FormControlLabel
                                        label={"Import Hint Text from Level 1"}
                                        control={<Switch checked={importHint}
                                                         onChange={handleImportHint}
                                        />}
                                    />
                                </FormGroup>
                            )}

                            <FormGroup>
                                <FormControlLabel
                                    label="Keyword Score in Agent Result Screen"
                                    control={<Switch checked={keywordScore}
                                                     onChange={handleKeywordChange}
                                    />}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormControlLabel label="Hint Text in level"
                                    control={<Switch checked={hintText}
                                                     onChange={handleHintTextChange}
                                    />}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormControlLabel label="Real time feedback"
                                    control={<Switch checked={realTimeFeedbackEnabled}
                                                     onChange={handleRealTimeChange}
                                    />}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormControlLabel label="Send full audio recording to SnowFly"
                                    control={<Switch checked={sendsToSnowfly}
                                                     onChange={handleSendToSnowflyChange}
                                    />}
                                />
                            </FormGroup>

                            <FormGroup>
                                <FormControlLabel label="Allow recording the Agent's screen(s)"
                                    control={<Switch checked={screenRecording}
                                                     onChange={handleScreenRecordingChange}
                                    />}
                                />
                            </FormGroup>
                        </Box>
                    </div>
                </DialogContent>
                <DialogActions>
                    <PassFailCriteria
                        handlePassDialog={handlePassDialog}
                        passDialog={passDialog}
                        data={data}
                        currentIndex={levelIndex}
                        setAlertType={setAlertType}
                        setAlertMessage={setAlertMessage}
                        setOpenAlert={setOpenAlert}
                    />

                    {audioCount ? (
                        <Button onClick={handleGenerateAudioClick}>
                            <VolumeUpRoundedIcon className={classes.iconVolume} style={{marginRight: '0.25em'}} />
                            Generate Audio
                        </Button>
                    ) : null}

                    <Button onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={audioOpen}
                onClose={() => setAudioOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Generate Audio
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`There are ${audioCount} audio clips already. Are you sure you want to record over them?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAudioOpen(false)} className={classes.cancelButton}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleLevelAudioGenerate()}
                        className={classes.dialogDeleteBtn}
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

LevelOptions.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    updateData: PropTypes.func,
    levelIndex: PropTypes.number,
    singleLevel: PropTypes.object,
    copyHintText: PropTypes.func,
    classes: PropTypes.object,
    passFailLevel: PropTypes.string,
    onOptionChange: PropTypes.func,
    setAlertType: PropTypes.func,
    setAlertMessage: PropTypes.func,
    setOpenAlert: PropTypes.func,
};

export default LevelOptions;
