/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Divider,
    Grid,
    Toolbar,
    Box,
    Typography,
    TextField,
    Radio,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    TextareaAutosize,
    InputLabel,
} from '@material-ui/core';
import TagsInput from 'react-tagsinput-special';
import 'react-tagsinput-special/react-tagsinput.css';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import LinearProgress from '@material-ui/core/LinearProgress';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from "@material-ui/icons/PlayArrow";
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded';
import { ReactMic } from 'react-mic';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from "@material-ui/core/IconButton";
import { useDrag } from 'react-dnd';
import GetAvatar from '../GetAvatars';
import {
    generateAudio,
    uploadRecordedAudio,
} from '../../../services/result.service';
import {
    uploadAudio,
    getAwsPresignedUrl,
} from "../../../services/result.service";
import MicrophoneStream from "microphone-stream";
import {
    convertAudioToBinaryMessage,
    handleEventStreamMessage,
    resetEventStreamMessage,
    getAudioEventMessage,
} from "../../../utils/convertIntoBuffer";
import { useCharacterDataStyle } from './CharacterData';
import Autopopulate from './Autopopulate.jsx';
import GenerateAudio from './GenerateAudio.jsx';
import { EventStreamCodec } from "@aws-sdk/eventstream-codec";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';

const CharacterData = ({
   index,
   data,
   characterData,
   handleChange,
   handleAudioChange,
   handleKeywordChange,
   handleTestChange,
   handleCheckboxChange,
   handleAudioGenerate,
   handleRadioChange,
   handleDeleteScript,
   handleAgentRecordedAudio,
   handleAutopopulateChange,
   handleResetAutopopulate,
   updateData,
   borderColor,
   sim,
   tabs,
   setTimePlayfun,
   timePlay,
   currentAudio,
   pauseFunction,
   levelOrder,
   newSim,
   currentVoice,
   path,
   onlyKeywords,
}) => {
    const selectedCharacter = characterData.find(
        (item) => item._id === data.sim_character_id,
    );
    const classes = useCharacterDataStyle();
    const [addTextArea, setTextArea] = useState(false);
    const [playAudio, setPlayAudio] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [testResults,           setTestResults] = useState(false);
    const [isLoadingTestResults,  setIsLoadingTestResults] = useState(false);
    const [gradeScore,            setGradeScore] = useState(0);
    const [matchTests,            setmatchTests] = useState([]);
    const [missesTests,           setmissesTests] = useState([]);
    const [finalTranscription,    setFinalTranscription] = useState('');
    const [presignedUrl, setPresignedUrl] = useState("");
    const [presignedBool, setPresignedBool] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openKeyword, setOpenKeyword] = React.useState(false);
    const [openTest, setOpenTest] = React.useState(false);
    const [recordingTest, setRecordingTest] = React.useState(false);
    const [tryTestOpen, setTryTestOpen] = React.useState(false);
    const [openTestEdit, setOpenTestEdit] = React.useState(false);
    const [hintText, setHintText] = React.useState(data.hint_text);
    const [keywords, setKeywords] = React.useState(data.keywords);
    const [hideConfidence, setHideConfidence] = React.useState(true);
    const [scriptOveralConfidence, setScriptOveralConfidence] = React.useState(data.overalConfidence || 60 );
    const [tests, setTests] = React.useState( data.tests || []);
    const [testId, setTestId] = React.useState(0);
    const [timer, setTimer] = useState(0);
    //const [record, setRecord] = useState(true);
    const [test, setTest] = React.useState( {
        name:'',
        isFlexible: false,
        keywords: [String],
        coachFeedback: '',
        confidence: 0
    } );

    const [name, setName] = useState('');
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const [isFlexible, setIsFlexible] = useState(false);
    const handleIsFlexibleChange = (event) => {
        if (event.target.value === 'flexible') {
            setIsFlexible(true);
            setHideConfidence(false);
        } else {
            setIsFlexible(false);
            setHideConfidence(true);
        }
        //setIsFlexible(event.target.checked);
    };
    const [coachFeedback, setCoachFeedback] = useState('');
    const handleCoachFeedbackChange = (event) => {
        setCoachFeedback(event.target.value);
    };
    const [testKeywords, setTestKeywords] = useState([]);
    const handleKeywordsChange = (tags) => {
        setTestKeywords(tags); // Set the extracted tag array as the new value for testKeywords
    };
    const [confidence, setConfidence] = useState(0);
    const handleConfidenceChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setConfidence(isNaN(value) ? 0 : value);
    };

    const [tags, setTags] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [errorTag, setErrorTag] = useState(false);
    const [editedTags, setEditedTags] = useState(''); // Display tags as comma-separated string in the input field

    const handleEditClick = () => {
        setEditedTags(testKeywords.join(', '));
        setEditMode(true);
    };

    const handleSaveClick = () => {
        // Split the editedTags string by commas to get the updated tags
        if (!editedTags.length) {
            setTestWordsError('Please enter at least one keyword');
            return;
        }
        const updatedTags = editedTags.split(',').map((tag) => tag.trim());
        const uniqueTags = Array.from(new Set(updatedTags));
        const andOperatorCount = updatedTags.filter((tag) => tag === '&').length;
        const orOperatorCount = updatedTags.filter((tag) => tag === '|').length;

        if (
            (uniqueTags.length === updatedTags.length &&
                (andOperatorCount === 1 || orOperatorCount === 1) &&
                andOperatorCount + orOperatorCount <= 1) || (andOperatorCount + orOperatorCount === 0)
        ) {
            setTags(updatedTags);
            setTestKeywords(updatedTags)
            setEditMode(false);
        } else {
            setErrorTag(true);
            setTimeout(() => {
                setErrorTag(false); // Clear the error message after 3 seconds
            }, 3000);
        }
    };

    const [script, setScript] = React.useState(data.script);
    const [switchGenerate, setSwitchGenerate] = useState(false);
    const [voice, setVoice] = useState(currentVoice[0]);
    const audioPlayer = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [openScriptDialogBox, setOpenScriptDialogBox] = useState(false);
    const [
        openExistingScriptDialogBox,
        setOpenExistingScriptDialogBox,
    ] = useState(false);
    const rec = useRef(null);
    const stream = useRef(null);
    let blob;
    let blobs;
    let voiceStream;
    const [caseInformationArray, setCaseInformationArray] = useState([]);
    const [patientInformationArray, setPatientInformationArray] = useState([]);
    const [acmeInformationArray, setAcmeInformationArray] = useState([]);
    const [loremIpsumInformationArray, setLoremIpsumInformationArray] = useState(
        [],
    );
    const [openAlert, setOpenAlert] = useState(false);
    const [genAudio, setGenAudio] = useState(false);
    const [bgColor, setbgColor] = useState(true);
    const node = useRef();
    const util_utf8_node = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
    //const eventStreamMarshaller = new marshaller.EventStreamMarshaller( // EventStreamMarshaller is deprecated
    const eventStreamMarshaller = new EventStreamCodec(
        util_utf8_node.toUtf8,
        util_utf8_node.fromUtf8
    );
    // To hide the voice dropdown if clicked outside it's div/section.
    const handleClick = (e) => {
        if (node && node.current && node.current.contains(e.target)) {
            setGenAudio(true);
            // inside click
            return;
        }
        // outside click
        setGenAudio(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);
    useEffect(() => {
        getAwsPresignedUrl()
            .then((res) => {
                return setPresignedUrl(res.data.url);
            })
            .catch((err) => console.log(err));
        setTimer(0);
    }, [setPresignedUrl, presignedBool]);
    useEffect(() => {
        setHintText(data.hint_text);
    }, [data.hint_text]);
    const getAutopopulateKeywords = () => {
        const array1 = [];
        const array2 = [];
        const array3 = [];
        const array4 = [];
        if (data.autopopulate_data && data.autopopulate_data.length && data.autopopulate_data[0].tabs && data.autopopulate_data[0].tabs.length > 0) {
            for (const keyword of data.autopopulate_data[0].tabs[0].sections) {
                for (const field of keyword.fields) {
                    if (keyword.section_name === 'Case Information( Header visible on edit only)') {
                        if (field.is_enabled) {
                            array1.push(field.field_title);
                        }
                    } else if (keyword.section_name === 'Additional Information') {
                        if (field.is_enabled) {
                            array2.push(field.field_title);
                        }
                    } else if (keyword.section_name === 'Description Information(Header Visible on edit only)') {
                        if (field.is_enabled) {
                            array3.push(field.field_title);
                        }
                    } else if (keyword.section_name === 'Web Information(Header Not visible on edit only)') {
                        if (field.is_enabled) {
                            array4.push(field.field_title);
                        }
                    }
                }
            }
        }
        setCaseInformationArray([...array1]);
        setPatientInformationArray([...array2]);
        setAcmeInformationArray([...array3]);
        setLoremIpsumInformationArray([...array4]);
    };
    useEffect(getAutopopulateKeywords, [data.autopopulate_data, data._id]);
    useEffect(() => {
        if (data.newScript) {
            setTimeout(() => {
                setbgColor(false);
            }, 3000);
        }
    }, [data]);
    const showTextArea = (flag) => {
        setTextArea(flag);
        if (onlyKeywords && selectedCharacter.type === 'agent') {
            setScript(`Use Keyword(s): ${keywords.join(',')}`);
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleKeywordOpen = () => {
        setKeywords(data.keywords);
        setOpenKeyword(true);
    };
    const handleKeywordCancel = () => {
        setKeywords(data.keywords);
        setOpenKeyword(false);
    };
    const handleTryTestOpen= async () => {
        let saved = true;
        if (openTestEdit)
            saved = await handleTestSave();
        //setTests(data.tests);
        if(saved)
            setTryTestOpen(true);
    };
    const handleTryTestClose= () => {
        //setTests(data.tests);
        setTryTestOpen(false);
        setFinalTranscription('');
        setTestResults(false);
    };
    const handleTestOpen = () => {
        //setTests(data.tests);
        setOpenTest(true);
    };
    const handleTestCancel = () => {
        //setTests(data.tests);
        setTestNameError('');
        setTestWordsError('');
        setOpenTestEdit(false);
    };
    const handleTestCloseModal = () => {
        setTestNameError('');
        setTestWordsError('');
        setOpenTest(false);
        setOpenTestEdit(false);
    };
    const handleTestClose = () => {
        setOpenTest(false);
        setOpenTestEdit(false);
        save();
    };
    const handleTestEdit = (idx) => {
        setTestId(idx);
        if (idx > -1) {
            setTest(data.tests[idx]);
            setName(data.tests[idx].name);
            setIsFlexible(data.tests[idx].isFlexible);
            if (data.tests[idx].isFlexible) {
                setHideConfidence(false);
            } else {
                setHideConfidence(true);
            }
            setCoachFeedback(data.tests[idx].coachFeedback);
            setTestKeywords([...data.tests[idx].keywords]);
            setConfidence(data.tests[idx].confidence);
        } else{
            setName('');
            setIsFlexible(false);
            setCoachFeedback('');
            setTestKeywords([]);
            setConfidence(0);
            setHideConfidence(true);
        }
        setOpenTestEdit(true);
    };

    const [testNameError, setTestNameError] = useState('');
    const [testWordsError, setTestWordsError] = useState('');

    const handleTestSave = async () => {
        let auxTest = {
            name: name,
            isFlexible: isFlexible,
            keywords: [...testKeywords],
            coachFeedback: coachFeedback,
            confidence: confidence,
        };

        if (!name.length) {
            setTestNameError('Please enter a name for the test');
            return false;
        }

        if (!testKeywords.length) {
            setTestWordsError('Please enter at least one keyword');
            setErrorTag(true);
            return false;
        }

        setTest(auxTest);
        let aux = [...tests]; // Create a new array using spread operator
        if (testId > -1) {
            aux[testId] = auxTest;
        } else {
            aux.push(auxTest);
        }
        setTests(aux);
        handleTestChange(index, aux);
        data.tests = aux;
        setOpenTest(false);
        setOpenTestEdit(false);
        setTestId(-1);
        await updateData(index, data);
        setName('');
        setIsFlexible(false);
        setCoachFeedback('');
        setTestKeywords([]);
        setConfidence(0);
        return true;
    };
    const handleRemoveTest = async () => {
        let aux = tests.filter((test, idx) => idx !== testId);
        setTests(aux);
        handleTestChange(index, aux);
        data.tests = aux;
        setOpenTest(false);
        setOpenTestEdit(false);
        setTestId(-1);
        await updateData(index, data);
        setName('');
        setIsFlexible(false);
        setCoachFeedback('');
        setTestKeywords([]);
        setConfidence(0);
    };
    const handleTextChange = (index, e) => {
        if (e.target.name === 'hint_text') {
            setHintText(e.target.value);
        }
        if (e.target.name === 'script') {
            setScript(e.target.value);
        }
    };
    const keywordsChange = (e) => {
        setKeywords(e);
    };
    const handleKeywordSave = async () => {
        handleKeywordChange(index, keywords);
        setOpenKeyword(false);
        if (onlyKeywords) {
            data.script = `Use Keyword(s): ${keywords.join(',')}`;
        }
        await updateData(index, data);
    };
    const hintTextSave = async () => {
        handleChange(hintText, index, 'hint_text');
        setOpen(false);
        await updateData(index, data);
    };
    const handleScriptOveralConfidenceChange = async (event) => {
        setScriptOveralConfidence(event.target.value);
        handleChange(event.target.value, index, 'overalConfidence');
        /* setOpen(false);*/
        await updateData(index, data);
    };
    const saveScript = async () => {
        handleChange(script, index, 'script');
        if (data.audio_url) {
            setOpenExistingScriptDialogBox(true);
        } else {
            setOpenScriptDialogBox(true);
        }
        await updateData(index, data);
    };
    const cancelScript = () => {
        setScript(data.script);
        showTextArea(false);
    };
    const hintTextCancel = () => {
        setHintText(data.hint_text);
        setOpen(false);
    };
    const save = () => {
        setOpenKeyword(false);
        updateData(index, data);
    };
    const handleKeywordClose = () => {
        setOpenKeyword(false);
        save();
    };
    const showPlayAudio = async () => {
        setIsLoading(true);
        const req = {};
        if (genAudio) {
            req.voice = voice;
        }
        req.sim_script_id = data._id;
        let response = {};
        if (data.agent_recorded_audio || selectedCharacter.type !== 'agent' || levelOrder === 0 || levelOrder === 1) {
            response = await generateAudio(req);
        }
        if (selectedCharacter.type === 'agent' && (levelOrder === 0 || levelOrder === 1)) {
            await handleAudioGenerate(
                index,
                response.audio_url,
                response.expiry_date,
                response.audio_key,
                true,
                voice,
            );
        } else {
            await handleAudioGenerate(
                index,
                response.audio_url,
                response.expiry_date,
                response.audio_key,
                data.agent_recorded_audio,
                voice,
            );
        }
        setGenAudio(false);
        await updateData(index, data);
        setVoice(voice);
        setIsLoading(false);
        setPlayAudio(true);
    };

    const pauseAudioUrl = (data, direct) => {
        if (direct) {
            pauseFunction(data);
        }
        currentAudio.current.pause();
    };
    const playAudioUrl = (data) => {
        setTimePlayfun(audioPlayer, data);
        const direct = false;
        if (timePlay > 0) {
            pauseAudioUrl(data, direct);
        }
        audioPlayer.current.play();
        //  when audio playback ends, set isPlaying to false
        audioPlayer.current.onended = () => {
            pauseFunction(data);
        };
    };
    const showPlayButton = (audioPlay, audio, data) => {
        if (!audio) {
            return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
        }
        if (audio && data.play) {
            return (
                <PlayArrowIcon
                    className={classes.priorityIcon}
                    onClick={() => playAudioUrl(data)}
                />
            );
        }
        if (audio && !data.play) {
            const direct = true;
            return (
                <PauseIcon
                    className={classes.priorityIcon}
                    onClick={() => pauseAudioUrl(data, direct)}
                />
            );
        }
        return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
    };

    const isUrlExpired = (url) => {
        const urlParams = new URLSearchParams(new URL(url).search);
        const amzDate = urlParams.get('X-Amz-Date');

        if (!amzDate) return true;

        const expirationTime = new Date(
            `${amzDate.slice(0, 4)}-${amzDate.slice(4, 6)}-${amzDate.slice(6, 8)}T${amzDate.slice(9, 11)}:${amzDate.slice(11, 13)}:${amzDate.slice(13, 15)}Z`
        );

        expirationTime.setMinutes(expirationTime.getMinutes() + 4);
        return new Date() > expirationTime;
    };

    const handleTestPlayRecordStart = async () => {
        let time = timer
        let transcription = "",
            partial = "",
            isFinal;

        setFinalTranscription('');
        setRecordingTest(true);
        setTestResults(false);

        let voiceStream = await navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
        });

        let micStream = new MicrophoneStream(); // ws
        stream.current = new MediaStream(voiceStream);

        let blobs = [];
        micStream.setStream(voiceStream); // ws

        let interval = setInterval(() => {
            time = time + 1
        }, 1000)

        if (isUrlExpired(presignedUrl)) {
            getAwsPresignedUrl()
                .then((res) => {
                    return setPresignedUrl(res.data.url);
                })
                .catch((err) => console.log(err));
        }
        let ws = new WebSocket(presignedUrl); // ws
        ws.binaryType = "arraybuffer"; // ws

        ws.onopen = function (event) {
            micStream.on("data", (e) => {
                const buffer = convertAudioToBinaryMessage(e);
                if (ws.readyState === 1) {
                    ws.send(buffer);
                    resetEventStreamMessage();
                }
            });
        };

        ws.addEventListener("message", function (message) {
            let messageWrapper = eventStreamMarshaller.decode(
                Buffer(message.data)
            );
            let messageBody = JSON.parse(
                String.fromCharCode.apply(String, messageWrapper.body)
            );

            switch (messageWrapper.headers[":message-type"].value) {
                case 'event':
                    //console.log('Message Body = %o', messageBody);
                    [transcription, partial, isFinal] = handleEventStreamMessage(messageBody, transcription);
                    break;

                case 'exception':
                    console.error('WS Error = %o', messageBody);
                    break;

                default:
                    console.log('MessageWrapper: %o', messageWrapper);
            }
        });

        let mediaRec = new MediaRecorder(stream.current, { mimeType: "audio/webm" });
        mediaRec.ondataavailable = (e) => blobs.push(e.data);

        mediaRec.onstop = async () => {
            micStream.stop(); // ws

            // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
            let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
            let emptyBuffer = eventStreamMarshaller.encode(emptyMessage);//marshall(emptyMessage);
            ws.send(emptyBuffer);

            // blobs.push(MediaRecorder.requestData());
            let blob = new Blob(blobs, { type: "audio/mp3" });
            const url = window.URL.createObjectURL(blob);
            // create new simScripts with this audio url added
            let result = {};
            ws.onclose = async function (event) {
                clearInterval(interval);
                setTimer(time);
                if (time > 10) {
                    setPresignedBool(!presignedBool)
                }
                if (window.IS_DEVELOPMENT) {
                    console.log(transcription);
                }
                setFinalTranscription(transcription);
                //console.log(data)
                // Call Lambda grading function here.
                // Change below to save grade and transcription to the db instead of
                // calculating it in the back end
                const formData = new FormData();
                formData.append("sim_level_user_response", 1);
                formData.append("script_id", data._id);
                formData.append("audio_blob", blob);
                formData.append("transcribed_text", transcription);
                result = await uploadAudio(formData);
                if (window.IS_DEVELOPMENT) {
                    console.log(result.data);
                }
                setIsLoadingTestResults(false);
                setGradeScore(result.data.grade_score);
                setmatchTests(result.data.tests_matched);
                setmissesTests(result.data.missed_tests);
            };
        };

        /*
          Once we've got the last message form the transcript service, close the WebSocket
         */
        let wsTimer = setInterval(() => {
            if (isFinal) {
                clearInterval(wsTimer);
                ws.close();
            }
        }, 500);

        rec.current = mediaRec;
        rec.current.start();
    };

    const handleRecordStopTest = () => {
        rec.current.stop();
        stream.current = null;
        setRecordingTest(false);
        setTestResults(true);
        setIsLoadingTestResults(true);
        setGradeScore(0);
    };

    const handleRecordStart = async () => {
        setOpenDialogBox(false);
        setIsRecording(true);
        voiceStream = await navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
        });
        stream.current = new MediaStream(voiceStream);
        blobs = [];
        rec.current = new MediaRecorder(stream.current, {
            mimeType: 'audio/webm',
        });
        rec.current.ondataavailable = (e) => blobs.push(e.data);
        rec.current.onstop = async () => {
            setIsLoading(true);
            blob = new Blob(blobs, { type: 'audio/mp3' });
            const formData = new FormData();
            formData.append('script_id', data._id);
            formData.append('audio_blob', blob);
            const result = await uploadRecordedAudio(formData);
            if (selectedCharacter.type === 'agent') {
                await handleAudioGenerate(index,
                    result.data.url,
                    result.data.expiry_date,
                    result.data.audio_key, true);
            } else {
                await handleAudioGenerate(index,
                    result.data.url,
                    result.data.expiry_date,
                    result.data.audio_key,
                    false);
            }
            await updateData(index, data);
            setIsLoading(false);
        };
        rec.current.start();
    };
    const handleRecordStop = () => {
        rec.current.stop();
        stream.current = null;
        setIsRecording(false);
    };
    const showDialogBox = () => {
        if (data.audio_url) {
            setOpenDialogBox(true);
        } else {
            handleRecordStart();
        }
    };
    const showMicrophone = (recording) => {
        if (recording) {
            return (
                <MicOffIcon className={classes.micOffIcon} onClick={handleRecordStop} />
            );
        }
        return <MicIcon className={classes.micIcon} onClick={showDialogBox} />;
    };
    const highLightKeywords = (str, arr = []) => {
        const keywordArray = [];
        arr.map((ele) => {
            const substring = new RegExp(ele, 'gi');
            const res = str.match(substring);
            if (res && res.length) {
                keywordArray.push(res[0]);
            }
            return keywordArray;
        });
        keywordArray.map((ele) => {
            str = str.replaceAll(
                ele,
                `<span title='Keyword' style='background: #ADBBD4;padding: 4px;border-radius: 3px;'>${ele}</span>`,
            );
            return str;
        });
        return str;
    };
    const getBorder = (charachter) => {
        let background = '';
        switch (charachter) {
            case 'coach':
                background = '#882647';
                break;
            case 'agent':
                background = '#EB6D20';
                break;
            case 'customer':
                background = '#2694D3';
                break;
            case 'other':
                background = '#2CB174';
                break;
            default:
                background = '#882647';
        }
        return background;
    };
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'component',
        item: { id: data._id, path },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    const opacity = isDragging ? 0 : 1;
    drag(ref);
    useEffect(() => {
        if (openAlert) {
            setTimeout(() => {
                setOpenAlert(false);
            }, 3000);
        }
    }, [openAlert]);

    return (
        <>
            <Grid
                ref={ref}
                className={classes.gridStyle}
                style={{
                    borderLeft: `3px solid ${getBorder(selectedCharacter.type)}`,
                    background: data.newScript && bgColor ? '#b5e8d175' : '#ffff',
                    opacity,
                }}
                container
            >
                <Grid item className={classes.index}>
                    <Box>{index + 1}</Box>
                </Grid>
                <Grid item className={classes.scriptGrid}>
                    <Box className={classes.topBox}>
                        {selectedCharacter.type === 'agent'
                        && !switchGenerate
                        && !data.agent_recorded_audio
                            ? null
                            : showPlayButton(playAudio, data.audio_url, data)}

                        {selectedCharacter.type === 'agent'
                        && !switchGenerate
                        && !data.agent_recorded_audio
                            ? null
                            : showMicrophone(isRecording)}
                        <Dialog
                            open={openDialogBox}
                            onClose={() => setOpenDialogBox(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Record Audio</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    There is already a clip here. Are you sure you want to record
                                    over it?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDialogBox(false)} className={classes.cancelButton}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => handleRecordStart()}
                                    className={classes.dialogDeleteBtn}
                                    autoFocus
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Divider orientation="vertical" flexItem />
                        <GetAvatar
                            avatar={selectedCharacter.avatar}
                            borderColor={borderColor}
                            classes={classes.avatarImg}
                            alt={selectedCharacter.type}
                        />
                        <Typography component="div" className={classes.nameLabel}>
                            {selectedCharacter.name}
                            ,&nbsp;
                            <em className={classes.roleLabel}>
                                The&nbsp;
                                {selectedCharacter.masked_type
                                    ? selectedCharacter.masked_type : selectedCharacter.type}
                            </em>
                            <br />
                            {addTextArea ? (
                                <>
                                    <div className={classes.txtAreaContainer}>
                                        <TextareaAutosize
                                            required
                                            rowsMax={5}
                                            minRows={5}
                                            name="script"
                                            data-enable-grammarly="false"
                                            value={script}
                                            className={classes.textarea}
                                            onChange={(e) => handleTextChange(index, e)}
                                        />
                                        <div className={classes.textFielDiv}>
                                            <Button
                                                name="script"
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className={classes.saveBtn}
                                                onClick={() => saveScript()}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="small"
                                                className={classes.cancelBtn}
                                                onClick={() => cancelScript()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                    <Dialog
                                        open={openScriptDialogBox}
                                        onClose={() => setOpenScriptDialogBox(false)}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Do you want to auto generate audio?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => {
                                                    setOpenScriptDialogBox(false);
                                                    showTextArea(false);
                                                }}
                                                className={classes.cancelButton}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    showPlayAudio();
                                                    setOpenScriptDialogBox(false);
                                                    showTextArea(false);
                                                }}
                                                className={classes.dialogDeleteBtn}
                                                autoFocus
                                            >
                                                OK
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={openExistingScriptDialogBox}
                                        onClose={() => setOpenExistingScriptDialogBox(false)}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                There is already a clip here. Are you sure you want
                                                {' '}
                                                <br />
                                                {' '}
                                                to record over it?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => {
                                                    setOpenExistingScriptDialogBox(false);
                                                    showTextArea(false);
                                                }}
                                                color="primary"
                                            >
                                                No
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    showPlayAudio();
                                                    setOpenExistingScriptDialogBox(false);
                                                    showTextArea(false);
                                                }}
                                                className={classes.dialogDeleteBtn}
                                                autoFocus
                                            >
                                                Yes
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>
                            ) : (
                                <Typography className={classes.descriptionLabel}>
                                    {data.script === '' ? (
                                        'Empty'
                                    ) : (
                                        <span>
                      <span
                          dangerouslySetInnerHTML={{
                              __html: highLightKeywords(data.script, data.keywords),
                          }}
                      />
                    </span>
                                    )}
                                    ..
                                    <EditIcon
                                        className={classes.editIcon}
                                        onClick={() => showTextArea(true)}
                                    />
                                </Typography>
                            )}
                        </Typography>
                        <Tooltip title="Delete Script">
                            <DeleteOutlineIcon
                                className={classes.deleteIcon}
                                onClick={() => handleDeleteScript(index, data)}
                            />
                        </Tooltip>
                    </Box>
                    <br />
                    <Box className={classes.bottomBox}>
                        {isLoading ? <LinearProgress color="secondary" /> : null}
                        <Divider />
                        <Toolbar className={classes.toolBar}>
                            {selectedCharacter.type === 'agent' ? (
                                <div style={{ display: 'flex' }}>
                                    {sim.natural_lenguage !== true ? (
                                        <Tooltip title={keywords.length === 0 ? 'None' : keywords.slice(0, 20).join(' , ')} arrow>
                                            <Typography
                                                className={classes.toolBarLabel}
                                                onClick={handleKeywordOpen}
                                            >
                                                Keywords :
                                                {data.keywords.length ? (
                                                    <em className={classes.toolBarValue}>
                                                        {data.keywords.length <= 1
                                                            ? data.keywords.join(',')
                                                            : `${data.keywords.slice(0, 1).join(',')} ...`}
                                                    </em>
                                                ) : (
                                                    <em className={classes.toolBarValue}>None</em>
                                                )}
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                            <Tooltip title={'Manage Tests of This Script'} arrow>
                                                <Typography
                                                    className={classes.toolBarLabel}
                                                    onClick={handleTestOpen}
                                                >
                                                    Manage Tests
                                                </Typography>
                                            </Tooltip>
                                            <Divider
                                                className={classes.verticalLine}
                                                orientation="vertical"
                                                flexItem
                                            />
                                            <Typography component={'span'} className={classes.toolBarLabel}>
                                                Confidence %
                                                <FormControlLabel
                                                    control={(
                                                        <TextField
                                                            style={{width: '50px', marginLeft: '20px', marginRight: '10px'}}
                                                            onChange={handleScriptOveralConfidenceChange}
                                                            name="scriptOveralConfidence"
                                                            value={scriptOveralConfidence}
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                            </Typography>
                                            <Divider
                                                className={classes.verticalLine}
                                                orientation="vertical"
                                                flexItem
                                            />
                                            <Tooltip title={'record your self to try the test'} arrow>
                                                <Typography
                                                    className={classes.toolBarLabel}
                                                    onClick={handleTryTestOpen}
                                                >
                                                    Try Out
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    )}
                                    <Dialog
                                        open={tryTestOpen}
                                        onClose={handleTryTestClose}
                                        aria-labelledby="test"
                                    >
                                        <DialogTitle id="test">Try Test</DialogTitle>
                                        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', minWidth: '500px', justifyContent: 'center' }}>
                                                <Typography>
                                                    You can try the tests
                                                </Typography>
                                                <Typography>
                                                    Actual Script: {script}
                                                </Typography>
                                                <Typography>
                                                    Confidence: {scriptOveralConfidence}%
                                                </Typography>
                                            </div>
                                            {recordingTest ? (
                                                <>
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="stop mic recording"
                                                        component="span"
                                                        size="medium"
                                                        className={classes.recButton}
                                                        onClick={handleRecordStopTest}
                                                    >
                                                        <MicOffIcon />
                                                    </IconButton>
                                                    {/* <div className={classes.centerElement} style={{ display: record ? 'flex' : 'none' }}>
                          <ReactMic
                            record={record}
                            className="sound-wave"
                            width={150}
                            strokeColor="#f27e7f"
                            backgroundColor="#fcfcfc"
                          />
                        </div> */}
                                                </>
                                            ) : (
                                                <IconButton
                                                    color="primary"
                                                    aria-label="start mic recording"
                                                    component="span"
                                                    size="medium"
                                                    className={classes.pauseButton}
                                                    onClick={handleTestPlayRecordStart}
                                                    disableFocusRipple={false}
                                                >
                                                    <MicIcon />
                                                </IconButton>
                                            ) }
                                            {testResults ? (
                                                (!isLoadingTestResults ) ?
                                                    (
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                                            {(gradeScore >= scriptOveralConfidence ? <CheckIcon className={classes.checkIcon} style={{color: 'green', fontSize: '4rem'}}/> : <ClearIcon className={classes.crossIcon} color="error" style={{fontSize: '4rem'}}/>)}
                                                            <Typography>
                                                                transcription: {finalTranscription}
                                                            </Typography>
                                                            <Typography>
                                                                score: {gradeScore.toFixed(2)}%
                                                            </Typography>
                                                            {(matchTests.length > 0 || missesTests.length > 0 ) ?
                                                                (<Grid item xs={12} className={classes.grid} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                                                    <InputLabel style={{color: 'black', marginBottom: '5%'}}>
                                                                        Tests
                                                                    </InputLabel>
                                                                    <ul style={{width: '70%', margin: '0', padding: '0'}}>
                                                                        {matchTests.map(
                                                                            (correct) => (
                                                                                <li className={classes.list} key={correct.name} style={{display: 'flex', justifyContent: 'space-between', borderTop: '1px solid black', paddingLeft: '5%', paddingRight: '5%'}}>
                                                                                    { correct.name }
                                                                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                                                                        <CheckIcon className={classes.checkIcon} style={{color: 'green'}} />
                                                                                        {correct.grade.toFixed(2)}%
                                                                                    </div>
                                                                                </li>
                                                                            ),
                                                                        )}
                                                                        {missesTests.map(
                                                                            (missed) => (
                                                                                <li className={classes.list} key={missed.name} style={{display: 'flex', justifyContent: 'space-between' ,borderTop: '1px solid black', paddingLeft: '5%', paddingRight: '5%'}}>
                                                                                    { missed.name }
                                                                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                                                                        <ClearIcon className={classes.crossIcon} color="error" />
                                                                                        {missed.grade.toFixed(2)}%
                                                                                    </div>
                                                                                </li>
                                                                            ),
                                                                        )}
                                                                    </ul>
                                                                </Grid>) :
                                                                (<h2>No test are present</h2>)}
                                                        </div>
                                                    ) :
                                                    <div className={classes.loader}>
                                                        <CircularProgress color="primary"/>
                                                    </div>
                                            ) : '' }
                                        </DialogContent>
                                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button onClick={handleTryTestClose} className={classes.cancelButton}>
                                                Cancel
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={openTest}
                                        onClose={handleTestClose}
                                        aria-labelledby="test"
                                    >
                                        <DialogTitle id="test">
                                            Manage Tests
                                            <Button
                                                onClick={handleTryTestOpen}
                                                style= {{ position: 'relative', left: '60%', bottom: '5px' }}
                                            >
                                                Try Out
                                            </Button>
                                        </DialogTitle>
                                        <DialogContent>
                                            <div style={{ display: 'flex', alignItems: 'center', minWidth: '500px' }}>
                                                {tests.map((val, index) => (
                                                    <div key={index} style={{ marginRight: '10px' }}>
                                                        <Button onClick={() => handleTestEdit(index)} className={classes.cancelButton}>{val.name}</Button>
                                                    </div>
                                                ))}
                                                <Button onClick={() => handleTestEdit(-1)} className={classes.cancelButton}>+</Button>
                                            </div>
                                            {openTestEdit ? (
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        label="Name"
                                                        variant="outlined"
                                                        value={name}
                                                        onChange={handleNameChange}
                                                        required
                                                        helperText={'Enter a name for the Test'}
                                                        error={testNameError.length > 0}
                                                    />
                                                    <br />
                                                    <br />
                                                    <FormControl
                                                        className={classes.radioBtnDiv}
                                                        component="fieldset"
                                                    >
                                                        <FormLabel
                                                            className={classes.radioLabels}
                                                            component="legend"
                                                        >
                                                            <Typography className={classes.toolBarLabel}>
                                                                Phrasing:
                                                            </Typography>
                                                        </FormLabel>
                                                        <RadioGroup
                                                            defaultValue="Flexible"
                                                            aria-label="Flexible"
                                                            className={classes.radioBtnDiv}
                                                        >
                                                            <FormControlLabel
                                                                className={classes.radioBtn}
                                                                value="flexible"
                                                                control={(
                                                                    <Radio
                                                                        checked={isFlexible}
                                                                        value="flexible"
                                                                        onChange={(e) => handleIsFlexibleChange(e)}
                                                                        className={classes.radio}
                                                                    />
                                                                )}
                                                                label={<span className={classes.labelFont}>Flexible</span>}
                                                            />
                                                            <FormControlLabel
                                                                className={classes.radioBtn}
                                                                value="strict"
                                                                control={(
                                                                    <Radio
                                                                        checked={!isFlexible}
                                                                        value="strict"
                                                                        onChange={(e) => handleIsFlexibleChange(e)}
                                                                        className={classes.radio}
                                                                    />
                                                                )}
                                                                label={<span className={classes.labelFont}>Strict</span>}
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <br />
                                                    <br />
                                                    <TextField
                                                        fullWidth
                                                        label="Coach Intention"
                                                        variant="outlined"
                                                        value={coachFeedback}
                                                        onChange={handleCoachFeedbackChange}
                                                    />
                                                    <br />
                                                    <br />

                                                    {!editMode && (
                                                        <div style={{display: 'flex'}}>
                                                            <TagsInput
                                                                value={testKeywords}
                                                                onlyUnique
                                                                className={classes.tagInput}
                                                                name="testKeywords"
                                                                onChange={handleKeywordsChange}
                                                                inputProps={{
                                                                    disabled: true,
                                                                    placeholder: ' ',
                                                                    style: { width: '100px' }
                                                                }}
                                                            />
                                                            <Button onClick={handleEditClick} color="primary">Edit</Button>
                                                        </div>
                                                    )}

                                                    {/* Display the regular input field in edit mode */}
                                                    {editMode && (
                                                        <div style={{display: 'flex'}}>
                                                            <TextField
                                                                type="text"
                                                                value={editedTags}
                                                                label="Keywords"
                                                                variant="outlined"
                                                                onChange={(e) => setEditedTags(e.target.value)}
                                                                helperText={'Please enter at least one keyword'}
                                                                error={testWordsError.length > 0}
                                                            />
                                                            <Button onClick={handleSaveClick} color="primary">Save</Button>
                                                        </div>
                                                    )}
                                                    {!errorTag && (
                                                        <em className={classes.toolBarValue}>
                                                            Separete the tags by comma, use only one operator & or |
                                                        </em>
                                                    )}
                                                    {errorTag && (
                                                        <em className={classes.toolBarValue} style={{color:'red'}}>
                                                            Use only one operator & or |
                                                        </em>
                                                    )}
                                                    <br />
                                                    {testWordsError && (
                                                        <em className={classes.toolBarValue} style={{color:'red'}}>
                                                            Please enter at least one keyword
                                                        </em>
                                                    )}
                                                    <br />
                                                    <br />
                                                    {!hideConfidence && (
                                                        <TextField
                                                            fullWidth
                                                            label="Confidence %"
                                                            variant="outlined"
                                                            value={confidence}
                                                            onChange={handleConfidenceChange}
                                                        />
                                                    )}
                                                </div>
                                            ) : null }
                                        </DialogContent>
                                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            {openTestEdit ? (
                                                    <>
                                                        <Button onClick={handleRemoveTest} className={classes.cancelButton}>
                                                            Delete
                                                        </Button>
                                                        <div>
                                                            <Button onClick={handleTestCancel} className={classes.cancelButton}>
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={handleTestSave} color="primary">
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </>
                                                ) :
                                                <Button onClick={handleTestCloseModal} className={classes.cancelButton}>
                                                    Cancel
                                                </Button>
                                            }
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={openKeyword}
                                        onClose={handleKeywordClose}
                                        aria-labelledby="keywords"
                                    >
                                        <DialogTitle id="keywords">Add Keyword</DialogTitle>
                                        <DialogContent>
                                            <TagsInput
                                                onlyUnique
                                                value={keywords}
                                                className={classes.tagInput}
                                                name="keywords"
                                                onChange={(e) => keywordsChange(e)}
                                                inputProps={{
                                                    placeholder: 'Add Keyword',
                                                    style: { width: '100px' },
                                                }}
                                            />
                                            <em className={classes.toolBarValue}>
                                                Click on &apos;Enter&apos; button after adding each keyword
                                                and then save.
                                            </em>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleKeywordCancel} className={classes.cancelButton}>
                                                Cancel
                                            </Button>
                                            <Button onClick={handleKeywordSave} color="primary">
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Divider
                                        className={classes.verticalLine}
                                        orientation="vertical"
                                        flexItem
                                    />
                                </div>
                            ) : null }
                            <Tooltip title={data.hint_text.length === 0 ? 'None' : data.hint_text} arrow>
                                <Typography
                                    className={classes.toolBarLabel}
                                    onClick={handleClickOpen}
                                >
                                    Hint Text:
                                    {data.hint_text.length ? (
                                        <em className={classes.toolBarValue}>
                                            {data.hint_text.length > 10
                                                ? `${data.hint_text.slice(0, 2)} ...`
                                                : data.hint_text}
                                        </em>
                                    ) : (
                                        <em className={classes.toolBarValue}>None</em>
                                    )}
                                </Typography>
                            </Tooltip>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="hint_text"
                                fullWidth
                                maxWidth="sm"
                            >
                                <DialogTitle id="hint_text">Add Hint Text</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        autoFocus
                                        id="hint_texts"
                                        margin="dense"
                                        name="hint_text"
                                        value={hintText}
                                        multiline
                                        rows={3}
                                        onChange={(e) => handleTextChange(index, e)}
                                        fullWidth
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={hintTextCancel} className={classes.cancelButton}>
                                        Cancel
                                    </Button>
                                    <Button onClick={hintTextSave} color="primary">
                                        Save
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Divider
                                className={classes.verticalLine}
                                orientation="vertical"
                                flexItem
                            />
                            <FormControl component="fieldset">
                                <FormGroup
                                    className={classes.formGroup}
                                    aria-label="position"
                                    row
                                >
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                value="hide_text"
                                                name="hide_text"
                                                checked={!!data.hide_text}
                                                onBlur={save}
                                                // size="small"
                                                color="primary"
                                                onChange={(e) => handleCheckboxChange(index, e)}
                                                className={classes.formControlLabel}
                                            />
                                        )}
                                        label={(
                                            <Typography
                                                className={classes.toolBarLabel}
                                            >
                                                Hide Text
                                            </Typography>
                                        )}
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </FormControl>
                            <Divider
                                className={classes.verticalLine}
                                orientation="vertical"
                                flexItem
                            />
                            {(selectedCharacter.type === 'agent' && sim.real_time_feedback) && (
                                <>
                                    <div className={classes.radioBtnDiv}>
                                        <Toolbar className={classes.toolbar}>
                                            <FormControl
                                                className={classes.radioBtnDiv}
                                                component="fieldset"
                                            >
                                                <FormLabel
                                                    className={classes.radioLabels}
                                                    component="legend"
                                                >
                                                    <Typography className={classes.toolBarLabel}>
                                                        Real-time feedback
                                                    </Typography>
                                                </FormLabel>
                                                <RadioGroup
                                                    defaultValue="yes"
                                                    aria-label="feedback"
                                                    className={classes.radioBtnDiv}
                                                >
                                                    <FormControlLabel
                                                        className={classes.radioBtn}
                                                        value="yes"
                                                        control={(
                                                            <Radio
                                                                checked={data.real_time_feedback}
                                                                value="yes"
                                                                onBlur={save}
                                                                onChange={(e) => handleRadioChange(index, e)}
                                                                className={classes.radio}
                                                            />
                                                        )}
                                                        label={<span className={classes.labelFont}>Yes</span>}
                                                    />
                                                    <FormControlLabel
                                                        className={classes.radioBtn}
                                                        value="no"
                                                        control={(
                                                            <Radio
                                                                checked={!data.real_time_feedback}
                                                                value="no"
                                                                onBlur={save}
                                                                onChange={(e) => handleRadioChange(index, e)}
                                                                className={classes.radio}
                                                            />
                                                        )}
                                                        label={<span className={classes.labelFont}>No</span>}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Toolbar>
                                    </div>
                                </>
                            )}
                            {selectedCharacter.type === 'agent' && sim.real_time_feedback ? (
                                <Divider
                                    className={classes.verticalLine}
                                    orientation="vertical"
                                    flexItem
                                />
                            ) : null}
                            <GenerateAudio
                                data={data}
                                selectedCharacter={selectedCharacter}
                                setSwitchGenerate={setSwitchGenerate}
                                switchGenerate={switchGenerate}
                                handleAgentRecordedAudio={handleAgentRecordedAudio}
                                index={index}
                                updateData={updateData}
                                handleAudioChange={handleAudioChange}
                                genAudio={genAudio}
                                setVoice={setVoice}
                                currentVoice={currentVoice}
                                setGenAudio={setGenAudio}
                                node={node}
                                voice={voice}
                                newSim={newSim}
                                showPlayAudio={showPlayAudio}
                                audioPlayer={audioPlayer}
                            />
                        </Toolbar>
                    </Box>
                    {sim.auto_populate ? (
                        <Autopopulate
                            data={data}
                            tabs={tabs}
                            handleResetAutopopulate={handleResetAutopopulate}
                            index={index}
                            updateData={updateData}
                            handleAutopopulateChange={handleAutopopulateChange}
                            caseInformationArray={caseInformationArray}
                            patientInformationArray={patientInformationArray}
                            acmeInformationArray={acmeInformationArray}
                            loremIpsumInformationArray={loremIpsumInformationArray}
                        />
                    ) : null}
                </Grid>
            </Grid>
        </>
    );
};

CharacterData.propTypes = {
    index: PropTypes.number,
    data: PropTypes.object,
    characterData: PropTypes.array,
    handleChange: PropTypes.func,
    handleAudioChange: PropTypes.func,
    handleKeywordChange: PropTypes.func,
    handleTestChange: PropTypes.func,
    handleCheckboxChange: PropTypes.func,
    handleAudioGenerate: PropTypes.func,
    handleRadioChange: PropTypes.func,
    handleDeleteScript: PropTypes.func,
    handleAgentRecordedAudio: PropTypes.func,
    handleAutopopulateChange: PropTypes.func,
    handleResetAutopopulate: PropTypes.func,
    updateData: PropTypes.func,
    borderColor: PropTypes.string,
    sim: PropTypes.object,
    tabs: PropTypes.array,
    currentAudio: PropTypes.object,
    setTimePlayfun: PropTypes.func,
    timePlay: PropTypes.number,
    pauseFunction: PropTypes.func,
    levelOrder: PropTypes.number,
    newSim: PropTypes.object,
    currentVoice: PropTypes.any,
    path: PropTypes.any,
    onlyKeywords: PropTypes.bool,
};
export default CharacterData;
