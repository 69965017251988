import React, {useRef, useEffect} from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import loadingIcon from "../../../assets/images/icons/loading-1.gif";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { currentStatus, audioPlay } from "../../../redux/sims/actions";
import {Grid, Tooltip} from '@material-ui/core';
import { useStyles } from "./AgentScript";

const AgentScript = ({
    singleScript,
    currentAgentScript,
    latestAgentScript,
    index,
    currentStatus,
    audioPlaying,
    currentAudioPlaying,
    totalLength,
    audioPlay,
    simCharacter,
    setLastFive,
    value,
    callTranscript,
    setCallTranscript,
    setTimePlayfun,
    timePlay,
    currentAudio,
    pauseFunction,
}) => {
    const classes = useStyles();
    let audioPlayer = useRef(null);
    
    useEffect(() => {
        if (
            latestAgentScript &&
            latestAgentScript.audio_url === "" &&
            currentAudioPlaying === index
        ) {
            const val = currentAgentScript.findIndex(
                (agentId) => agentId.id === latestAgentScript.id
            );
            setLastFive(currentAgentScript[val + 1]);
        } else if (currentAudioPlaying === index && !latestAgentScript) {
            if (totalLength === currentAudioPlaying && callTranscript <= 0) {
                if (singleScript.audio_url) return;
                currentStatus(0);
                audioPlay("");
                setCallTranscript(callTranscript + 1);
            } else {
                if (singleScript.audio_url) return;
                currentStatus(index + 1);
            }
        }
    }, [latestAgentScript, setLastFive, currentAudioPlaying]); // eslint-disable-line react-hooks/exhaustive-deps

    const pauseAudioUrl = (data, direct) => {
        if (direct) {
            const type = 'agent';
            pauseFunction(data, type);
        }
        currentAudio.current.pause();
        if ((audioPlaying === 'agent' || audioPlaying === 'call') && currentAudioPlaying >= 0) {
            audioPlay("");
            currentStatus("");
        }
    };

    const playAudioUrl = (data) => {
        let type = 'agent';
        setTimePlayfun(audioPlayer, data, type);
        const direct = false;
        if (timePlay > 0) {
            pauseAudioUrl(data, direct);
        }
        audioPlayer.current.play();
        //  when audio playback ends, set isPlaying to false
        audioPlayer.current.onended = () => {
            const indexValue = currentAgentScript.findIndex(
                (agentAudio) => agentAudio.audio_url === latestAgentScript.audio_url
            );
            if (audioPlaying === "agent") {
                if (indexValue + 1 === currentAgentScript.length) {
                    setLastFive("");
                    currentStatus(index + 1);
                } else {
                    if (indexValue + 1 === 1) {
                        currentStatus(index + 1);
                        setLastFive("");
                    } else {
                        setLastFive(currentAgentScript[indexValue + 1]);
                    }

                }
            } else if (audioPlaying === "call") {
                setLastFive("");
                currentStatus(index + 1);
            }
            pauseFunction(data, type);
            if (
                totalLength === currentAudioPlaying &&
                indexValue + 1 === currentAgentScript.length
            ) {
                audioPlayer = null;
                currentStatus(0);
                audioPlay("");
            }
        };
    };

    const audioChange = () => {
        if (currentAudioPlaying === index && simCharacter === "agent") {
            playAudioUrl(latestAgentScript ? latestAgentScript : singleScript);
        }
        // if (audioPlaying === 'call') {
        //   currentStatus(index + 1);
        //   if (totalLength === currentAudioPlaying) {
        //     audioPlayer = null;
        //     currentStatus(0);
        //     audioPlay('');
        //   }
        // }
    };

    /**
     * Highlights keywords in the script text.
     * @param str
     * @param arr
     * @returns {*}
     */
    const highLightKeywords = (str, arr = []) => {
        const keywordArray = [];
        arr.map((ele) => {
            const substring = new RegExp(ele, 'gi');
            const res = str.match(substring);
            if (res && res.length) {
                keywordArray.push(res[0]);
            }
            return keywordArray;
        });
        keywordArray.map((ele) => {
            str = str.replaceAll(
                ele,
                `<span title='Keyword' style='background: #ADBBD4;padding: 4px;border-radius: 3px;'>${ele}</span>`,
            );
            return str;
        });
        return str;
    };

    /**
     * Highlight keywords in the agent's transcription text.
     * @param str
     * @param match
     * @returns {*}
     */
    const highLightMatchKeywords = (str, match = []) => {
        let keywordArray = [];
        match.map((ele) => {
            const substring = new RegExp(ele, "gi");
            const res = str.match(substring);
            if (res && res.length) {
                keywordArray.push(res[0]);
            }
            return keywordArray;
        });
        keywordArray.map((ele) => {
            str = str.replaceAll(
                ele,
                `<span title='Matched keyword' style='background: #c4e89b;display: inline-block;padding: 0rem .3rem;border-radius: 3px;'>${ele.toLowerCase()}</span>`
            );
            return str;
        });
        return str;
    };

    const showPlayButton = (audio, isProcessed, data) => {
        if (audio && data && data.play) {
            return (
                <PlayArrowIcon
                    className={classes.priorityIcon}
                    onClick={() => playAudioUrl(data)}
                />
            );
        }
        if (audio && data && !data.play) {
            const direct = true;
            return (
                <PauseIcon
                    className={classes.priorityIcon}
                    onClick={() => pauseAudioUrl(data, direct)}
                />
            );
        }
        if (isProcessed === "Processing" && !audio) {
            return (
                <img
                    className={classes.priorityIcon}
                    src={loadingIcon}
                    alt="loading..."
                />
            );
        }
        if (!audio) {
            return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
        }
        if (audio) {
            return (
                <PlayArrowIcon
                    className={classes.priorityIcon}
                    onClick={() => playAudioUrl()}
                />
            );
        }
        return <PriorityHighRoundedIcon className={classes.priorityIcon} />;
    };

    return (
        <Grid container key={singleScript._id} className={classes.agent}>
            {
                ((latestAgentScript && latestAgentScript.id === 0) || latestAgentScript === undefined )&&
                <Grid xs={1} item className={classes.index}>{index + 1}</Grid>
            }
            <Grid xs={11} item className={classes.scriptGrid}>
                <div className={classes.checkCircle}>
                    <CheckCircleIcon className={classes.checkCircleIcon} />
                    {latestAgentScript?.myCount
                        ? latestAgentScript.myCount
                        : 0}{" "}
                    take
                    <div>
                        {latestAgentScript?.tests_matched ? (
                            <Tooltip title={latestAgentScript?.tests_matched?.length
                                ? latestAgentScript?.tests_matched?.map((ele, i) => (
                                    <span key={i}>
                    {ele.name}
                  </span>
                                ))
                                : ""} arrow>
                                <p className={classes.script} style={{ textAlign: "right" }}>
                                    {latestAgentScript?.tests_matched?.length ? (
                                        <span className={classes.bold} style={{ color: "green" }}>{latestAgentScript.tests_matched.length} Passed Tests</span>
                                    ) : null}
                                </p>
                            </Tooltip>
                        ) : null}
                        {latestAgentScript?.missed_tests ? (
                            <Tooltip title={latestAgentScript?.missed_tests?.length
                                ? latestAgentScript?.missed_tests?.map((ele, i) => (
                                    <span key={i}>
                    {ele.name}
                  </span>
                                ))
                                : ""} arrow>
                                <p className={classes.script} style={{ textAlign: "right" }}>
                                    {latestAgentScript?.missed_tests?.length ? (
                                        <span className={classes.bold} style={{ color: "red" }}>{latestAgentScript.missed_tests.length} Failed Tests</span>
                                    ) : null}
                                </p>
                            </Tooltip>
                        ) : null}
                    </div>
                </div>
                {currentAudioPlaying === index &&
                    !value &&
                    (audioPlaying === "call" || audioPlaying === "agent") && (
                        <audio onLoadedData={audioChange} id="1" ref={audioPlayer}>
                            <source
                                src={index ? latestAgentScript?.audio_url : singleScript?.audio_url
                                    /*singleScript?.audio_url
                                        ? singleScript?.audio_url
                                        : latestAgentScript?.audio_url*/
                                }
                                type="audio/mp3"
                            />
                        </audio>
                    )}
                {currentAudioPlaying === index && value && audioPlaying && (
                    <audio onLoadedData={audioChange} id="2" ref={audioPlayer}>
                        <source
                            src={index ? latestAgentScript?.audio_url : singleScript?.audio_url
                                /*singleScript?.audio_url
                                    ? singleScript?.audio_url
                                    : latestAgentScript?.audio_url*/
                            }
                            type="audio/mp3"
                        />
                    </audio>
                )}
                {latestAgentScript?.audio_url || singleScript?.audio_url
                    ? showPlayButton(
                        latestAgentScript?.audio_url || singleScript?.audio_url,
                        latestAgentScript?.status || "Completed", latestAgentScript ? latestAgentScript : singleScript
                    )
                    : showPlayButton(null, null, latestAgentScript ? latestAgentScript : singleScript)}
                <div className={`${classes.scriptTypo} ${classes.scriptStyle}`}>
          <span className={`${classes.bold}`}>
            {singleScript.sim_character_id.name},
          </span>
                    {' '}
                    The
                    {' '}
                    {singleScript.sim_character_id.masked_type
                        ? singleScript.sim_character_id.masked_type : singleScript.sim_character_id.type}
                    &nbsp;
                    <span className={classes.script}>
            {latestAgentScript?._id ? (
                latestAgentScript.status === "Completed" ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: highLightMatchKeywords(
                                latestAgentScript.transcribed_text,
                                latestAgentScript.keywords_matched
                            ),
                        }}
                    ></span>
                ) : (
                    <img
                        height="20px"
                        className={classes.loadingIcon}
                        src={loadingIcon}
                        alt="loading..."
                    />
                )
            ) : singleScript?.audio_url ? (
                ""
            ) : (
                "( Not Generated! )"
            )}
          </span>
                    <br />
                    <span className={classes.script}>
            <span className={classes.bold}>SCRIPT</span>:{" "}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: highLightKeywords(
                                    singleScript.script,
                                    singleScript.keywords
                                ),
                            }}
                        ></span>
            <br/>
            <br/>
                        {latestAgentScript?._id ? (
                            <span>
                <strong>Confidence: </strong>{Number(latestAgentScript?.grade_score).toFixed(2)}%
              </span>
                        ) : ''}
          </span>

                    {/*Correct Tests*/}
                    {latestAgentScript?.has_tests && latestAgentScript?.tests_matched ? (
                        <p className={classes.script} style={{ textAlign: "right" }}>
                            {latestAgentScript?.tests_matched.length ? (
                                <span className={classes.bold}>Correct Tests: </span>
                            ) : null}
                            {latestAgentScript?.tests_matched?.length
                                ? latestAgentScript?.tests_matched?.map((ele, i) => (
                                    <span key={i}>
                    {i !== 0 ? (
                        <span style={{ color: "green" }}>, {ele.name}</span>
                    ) : (
                        <span style={{ color: "green" }}>{ele.name}</span>
                    )}
                  </span>
                                ))
                                : null}
                        </p>
                    ) : null}

                    {/*Missed Tests*/}
                    {latestAgentScript?.has_tests && latestAgentScript?.missed_tests ? (
                        <p className={classes.script} style={{ textAlign: "right" }}>
                            {latestAgentScript?.missed_tests?.length ? (
                                <span className={classes.bold}>Missed Tests : </span>
                            ) : null}
                            {latestAgentScript?.missed_tests?.length
                                ? latestAgentScript?.missed_tests?.map((ele, i) => {
                                    // console.log('Test = %o', ele);
                                    return (
                                        <span key={i}>
                        {i !== 0 ? (
                            <span style={{color: "red"}}>, {ele.name}</span>
                        ) : (
                            <span style={{color: "red"}}>{ele.name}</span>
                        )}
                      </span>
                                    )
                                })
                                : null}
                        </p>
                    ) : null}

                    {/*Correct Keywords*/}
                    {latestAgentScript?.has_keywords && latestAgentScript?.keywords_matched ? (
                        <p className={classes.script} style={{ textAlign: "right" }}>
                            {latestAgentScript?.keywords_matched?.length ? (
                                <span className={classes.bold}>Correct Keywords: </span>
                            ) : null}
                            {latestAgentScript?.keywords_matched?.length
                                ? latestAgentScript?.keywords_matched?.map((ele, i) => (
                                    <span key={i}>
                    {i !== 0 ? (
                        <span style={{ color: "green" }}>, {ele}</span>
                    ) : (
                        <span style={{ color: "green" }}>{ele}</span>
                    )}
                  </span>
                                ))
                                : null}
                        </p>
                    ) : null}

                    {/*Missed Keywords*/}
                    {latestAgentScript?.has_keywords && latestAgentScript?.missed_keywords ? (
                        <p className={classes.script} style={{ textAlign: "right" }}>
                            {latestAgentScript?.missed_keywords?.length ? (
                                <span className={classes.bold}>Missed Keywords : </span>
                            ) : null}
                            {latestAgentScript?.missed_keywords?.length
                                ? latestAgentScript?.missed_keywords?.map((ele, i) => (
                                    <span key={i}>
                    {i !== 0 ? (
                        <span style={{ color: "red" }}>, {ele}</span>
                    ) : (
                        <span style={{ color: "red" }}>{ele}</span>
                    )}
                  </span>
                                ))
                                : null}
                        </p>
                    ) : null}
                </div>

                {!audioPlaying && !currentAudioPlaying && (
                    <audio
                        key={
                            latestAgentScript
                                ? latestAgentScript?.audio_url
                                : singleScript?.audio_url
                        }
                        id="3"
                        ref={audioPlayer}
                    >
                        <source
                            src={
                                latestAgentScript?.audio_url ?
                                    latestAgentScript?.audio_url :
                                    singleScript?.audio_url
                                /*
                                singleScript?.audio_url
                                    ? singleScript?.audio_url
                                    : latestAgentScript?.audio_url*/
                            }
                            type="audio/mp3"
                        />
                    </audio>
                )}
                <div style={{ clear: "both" }}></div>
            </Grid>
        </Grid>
    );
};

AgentScript.propTypes = {
    singleScript: PropTypes.object,
    currentAgentScript: PropTypes.array,
    latestAgentScript: PropTypes.object,
    index: PropTypes.number,
    currentStatus: PropTypes.func,
    currentAudioPlaying: PropTypes.any,
    audioPlaying: PropTypes.string,
    totalLength: PropTypes.number,
    audioPlay: PropTypes.func,
    setLastFive: PropTypes.func,
    setTimePlayfun: PropTypes.func,
    pauseFunction: PropTypes.func,
};

const mapStateToProps = (state) => ({
    // return {
    //   audioPlaying: state.sim.audioPlaying,
    // }
});

const mapDispatchToProps = {
    currentStatus,
    audioPlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentScript);
