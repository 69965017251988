import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Box,
    Paper,
    Typography,
    Button,
    TextField,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useLevelsStyle } from './CreateLevels';
import LevelOptions from "./LevelOptions";
import LevelActions from "./LevelActions";
import {addLevel} from "../../../services/result.service";
//import passFailCriteria from "./PassFailCriteria";

const Levels = ({
    simGenerated,
    Level,
    levels,
    passFailLevel,
    data,
    setData,
    deleted,
    flag,
    setFlag,
    setdeleted,
    setIndex,
    onOptionChange,
    setAlertType,
    setAlertMessage,
    setOpenAlert
}) => {
    const classes = useLevelsStyle();
    const [insertLevel, setInsertLevel] = useState(false);
    const [levelName, setlevelName] = useState('');
    const [levelOrder, setLevelOrder] = React.useState(0);
    const [activeLevel, setActiveLevel] = React.useState(0);

    const handleTabs = (level) => {
        const index = levels.findIndex((lvl) => lvl.order === level.order);
        setIndex(index);
        setActiveLevel(index);
        Level(level);
    };

    useEffect(() => {
        if (levels.length && flag) {
            const simData = { ...data };
            const { length } = simData.levels;
            const latestOrder = simData.levels[length - 1].order;
            setLevelOrder(latestOrder);
            setFlag(false);
        }
    }, [levels.length, flag, setLevelOrder, data, setFlag]);

    useEffect(() => {
        if (deleted) {
            setIndex(levelOrder - 1);
            setdeleted(false);
        }
    }, [deleted, setdeleted, setIndex, levelOrder]);

    const save = async () => {
        let result = await addLevel({
            sim_id: data.id,
            name: levelName,
            order: Math.max.apply(Math, levels.map((o) => o.order + 1)),
            hint_text: true,
            keyword_score: true,
            send_to_snowfly: false
        });
        if (result.data.success) {
            const simData = { ...data };
            simData.levels.push({...result.data.level, _id: result.data.level.id, scripts: []});
            setLevelOrder(levelOrder + 1);
            setData(simData);
        }

        setlevelName('');
        setInsertLevel(false);
    };

    const cancel = () => {
        setInsertLevel(false);
        setlevelName('');
    };

    const createNewLevel = () => {
        setInsertLevel(true);
    };

    return (
        <Grid item key="card" className={classes.firstGrid}>
            <Paper elevation={0}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid className={classes.levelHeader}>
                        <Typography className={classes.levelLabel}>
                            Levels
                            <span className={classes.simGeneratedLabel}>
                                <CheckCircleIcon className={classes.checkCircleIcon} />
                                SIM GENERATED
                            </span>
                        </Typography>
                    </Grid>
                    <Grid className={classes.levels} item xs>
                        {levels.map((level, levelIndex) => (
                            <Box
                                onClick={() => handleTabs(level)}
                                className={activeLevel === levelIndex ? classes.activeCard : classes.inactiveCard}
                                key={levelIndex}
                            >
                                <Typography className={classes.label} component={'div'}>
                                    {simGenerated ? (
                                        <CheckCircleIcon className={classes.checkCircleIcon} />
                                    ) : null}
                                    LEVEL
                                    {' '}
                                    {levelIndex + 1}
                                    :
                                    <div style={{float: "right", marginRight: "-1em", marginTop: "-1em", marginLeft: "0.5em", width: "fit-content", textAlign: "right"}}>
                                        <LevelOptions
                                            data={data}
                                            singleLevel={level}
                                            levelIndex={levelIndex}
                                            passFailLevel={passFailLevel}
                                            setData={setData}
                                            classes={classes}
                                            onOptionChange={onOptionChange}
                                            setAlertType={setAlertType}
                                            setAlertMessage={setAlertMessage}
                                            setOpenAlert={setOpenAlert}
                                        />
                                        <br />
                                        <LevelActions classes={classes}
                                            data={data}
                                            levelIndex={levelIndex}
                                            deleted={deleted}
                                            setdeleted={setdeleted}
                                            setData={setData}
                                            setIndex={setIndex}
                                        />
                                    </div>
                                </Typography>
                                <Typography className={classes.levelName}>
                                    {level.name}
                                </Typography>
                            </Box>
                        ))}
                        {insertLevel ? (
                            <Box className={classes.inactiveCard}>
                                <Typography className={classes.label}>
                                    {simGenerated ? (
                                        <CheckCircleIcon className={classes.checkCircleIcon} />
                                    ) : null}
                                    LEVEL
                                    {' '}
                                    {data.levels.length + 1}
                                    :
                                </Typography>
                                <TextField
                                    placeholder="Enter Level name"
                                    className={classes.text}
                                    value={levelName}
                                    onChange={(e) => setlevelName(e.target.value)}
                                />
                                <div className={classes.textFielDiv}>
                                    <Button
                                        name="script"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.saveBtn}
                                        onClick={() => save()}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        className={classes.cancelBtn}
                                        onClick={() => cancel()}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Box>
                        ) : null}
                        <Typography className={classes.addLevel} onClick={createNewLevel}>
                            + ADD A LEVEL
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};
Levels.propTypes = {
    simGenerated: PropTypes.bool,
    levels: PropTypes.array,
    Level: PropTypes.func,
    data: PropTypes.object,
    setData: PropTypes.func,
    levelOrder: PropTypes.number,
    setLevelOrder: PropTypes.func,
    deleted: PropTypes.bool,
    flag: PropTypes.bool,
    setFlag: PropTypes.func,
    setdeleted: PropTypes.func,
};
export default Levels;
