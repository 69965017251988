import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import {
      Dialog,
      DialogContent,
      DialogTitle,
      TextField,
      Button,
      Tooltip,
      CircularProgress,
      Paper,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { SaveAssessorNotes } from "../../services/result.service";
import DashboardPermissions from "../../permissions/dashboard";
import { permissionCheck } from "../../utils/permissionCheck";
import noAssignments from "../../assets/images/noAssignments.png";
import ScoreTableStyle from "./scoreTable";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import VideocamIcon from "@material-ui/icons/Videocam";
import { formatElapsedTime } from "../../utils/time";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ScoreTable = ({
  scoreList,
  totalScore,
  handleScore,
  assignment_loading,
  sortBy,
  sort,
  sorting,
  handleSortPageChange,
  sortPage,
}) => {
  const useStyles = makeStyles((theme) => ScoreTableStyle(theme));
  const classes = useStyles();
  const [assessor, setAssessor] = useState(false);
  const [responseId, setResponseId] = useState("");
  const [notes, setNotes] = React.useState("");
  const [noteStatus, setNoteStatus] = React.useState("view");
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    document.getElementById("tableDiv").scrollLeft = scrollLeft;
  }, [scrollLeft]);

  const openDialog = (row) => {
    setResponseId(row.response_id);
    setNotes(row.assessor_notes);
    setAssessor(true);
    if (
      !permissionCheck(
        DashboardPermissions.assessor_notes.update.task,
        DashboardPermissions.assessor_notes.update.type,
      ) &&
      !permissionCheck(
        DashboardPermissions.assessor_notes.create.task,
        DashboardPermissions.assessor_notes.create.type,
      ) &&
      permissionCheck(
        DashboardPermissions.assessor_notes.view.task,
        DashboardPermissions.assessor_notes.view.type,
      )
    ) {
      setNoteStatus("view");
    } else if (
      permissionCheck(
        DashboardPermissions.assessor_notes.update.task,
        DashboardPermissions.assessor_notes.update.type,
      ) ||
      permissionCheck(
        DashboardPermissions.assessor_notes.create.task,
        DashboardPermissions.assessor_notes.create.type,
      )
    ) {
      setNoteStatus(row.assessor_notes ? "edit" : "add");
    }
  };

  const handleClose = () => {
    setAssessor(false);
    setResponseId("");
  };

  const handleAdd = async () => {
    await SaveAssessorNotes(responseId, { assessor_notes: notes });
    setAssessor(false);
    handleScore();
  };

  const handleEdit = async () => {
    await SaveAssessorNotes(responseId, { assessor_notes: notes }, true);
    setAssessor(false);
    handleScore();
  };

  const scroll = (event) => {
    setScrollLeft(event.nativeEvent.target.scrollLeft);
  };

  const LoadTable = () => {
    document.getElementById("tableDiv").scrollLeft = scrollLeft;
  };

  return (
    <>
      {assignment_loading && scoreList.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
          
        >
          <CircularProgress color="primary"  />
        </div>
      ) : (
        <Paper >
          <TableContainer
            ref={LoadTable}
            id="tableDiv"
            className={classes.tableContainer}
            onScroll={scroll}
            
          >
            <Table
              aria-label="assignment table"
              className={classes.tableContainer}
              
            >
              <TableHead className={classes.thead} >
                <TableRow >
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "sim" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "sim" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("sim")}
                      
                    >
                      SIM NAME
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "level" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "level" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("level")}
                      
                    >
                      LEVEL
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "member" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "member" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("member")}
                      
                    >
                      MEMBER
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "keywordAccuracy" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "keywordAccuracy" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("keywordAccuracy")}
                      
                    >
                      KEYWORD ACCURACY
                    </TableSortLabel>
                  </TableCell>

                  <TableCell
                    className={classes.heading}
                    sortDirection={
                      sortBy === "correct_selections" ? sort : false
                    }
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "correct_selections" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("correct_selections")}
                      
                    >
                      DATA SELECTION
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "correct_entries" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "correct_entries" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("correct_entries")}
                      
                    >
                      TYPING ACCURACY
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={
                      sortBy === "overall_percentage" ? sort : false
                    }
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "overall_precentage" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("overall_percentage")}
                      
                    >
                      OVERALL DATA ACCURACY
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "total_time" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "total_time" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("total_time")}
                      
                    >
                      TOTAL TIME
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "wrapSeconds" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "wrapSeconds" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("wrapSeconds")}
                      
                    >
                      WRAP-UP TIME
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "start_date" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "start_date" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("start_date")}
                      
                    >
                      START DATE
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "completed_date" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "completed_date" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("completed_date")}
                      
                    >
                      COMPLETED DATE
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={
                      sortBy === "sent_for_review_date" ? sort : false
                    }
                    
                  >
                    <TableSortLabel
                      direction={
                        sortBy === "sent_for_review_date" ? sort : "asc"
                      }
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("sent_for_review_date")}
                      
                    >
                      SEND FOR REVIEW DATE
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "review_status" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "review_status" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("review_status")}
                      
                    >
                      REVIEW STATUS
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "reviewed_on" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "reviewed_on" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("reviewed_on")}
                      
                    >
                      REVIEWED ON
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    className={classes.heading}
                    sortDirection={sortBy === "reviewed_by" ? sort : false}
                    
                  >
                    <TableSortLabel
                      direction={sortBy === "reviewed_by" ? sort : "asc"}
                      active
                      IconComponent={ArrowDropDownIcon}
                      onClick={() => sorting("reviewed_by")}
                      
                    >
                      REVIEWER NAME
                    </TableSortLabel>
                  </TableCell>
                  {permissionCheck(
                    DashboardPermissions.assessor_notes.view.task,
                    DashboardPermissions.assessor_notes.view.type,
                  ) ? (
                    <TableCell
                      className={classes.heading}
                      sortDirection={sortBy === "assessor_notes" ? sort : false}
                      
                    >
                      <TableSortLabel
                        direction={sortBy === "assessor_notes" ? sort : "asc"}
                        active
                        IconComponent={ArrowDropDownIcon}
                        onClick={() => sorting("assessor_notes")}
                        
                      >
                        ASSESSOR NOTES
                      </TableSortLabel>
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody >
                {scoreList.map((row, index) => (
                  <StyledTableRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    
                  >
                    <TableCell >
                      {row.screen_recording && row.screen_recording.length ? (
                        <Tooltip
                          title="Has Screen Recording Enabled"
                          placement="top"
                          
                        >
                          <div
                            style={{
                              marginRight: "1em",
                              verticalAlign: "middle",
                              display: "inline-block",
                            }}
                            
                          >
                            <ScreenShareIcon
                              fontSize="small"
                              
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                      {row.screen_recording_urls.length ? (
                        <Tooltip
                          title={`User has ${row.screen_recording_urls.length} screen recordings in one or more levels of this SIM`}
                          placement="top"
                          
                        >
                          <div
                            style={{
                              marginRight: "1em",
                              verticalAlign: "middle",
                              display: "inline-block",
                            }}
                            
                          >
                            <VideocamIcon fontSize="small"  />
                          </div>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                      {row.sim}
                    </TableCell>
                    <TableCell >{row.level}</TableCell>
                    <TableCell >{row.member}</TableCell>
                    <TableCell className={classes.txtAlign} >
                      {row.keywordAccuracy
                        ? row.keywordAccuracy === "N/A"
                          ? "N/A"
                          : `${row.keywordAccuracy}%`
                        : "------"}
                    </TableCell>
                    <TableCell className={classes.txtAlign} >
                      {row.correct_selections
                        ? row.correct_selections === "N/A"
                          ? "N/A"
                          : `${row.correct_selections} of ${row.total_selections}`
                        : "------"}
                    </TableCell>
                    <TableCell className={classes.txtAlign} >
                      {row.correct_entries
                        ? row.correct_entries === "N/A"
                          ? "N/A"
                          : `${row.correct_entries} of ${row.total_entries}`
                        : "------"}
                    </TableCell>
                    <TableCell >
                      {row.overall_percentage
                        ? row.overall_percentage === "N/A"
                          ? "N/A"
                          : row.overall_percentage
                        : "------"}
                    </TableCell>
                    {/*<TableCell>{row.total_time}</TableCell>*/}
                    <TableCell >
                      {formatElapsedTime(row.total_seconds)}
                    </TableCell>
                    <TableCell >
                      {formatElapsedTime(row.wrapSeconds)}
                    </TableCell>
                    <TableCell className={classes.date} >
                      {row.start_date
                        ? moment(row.start_date)
                            .local()
                            .format("YYYY-MMM-DD h:mm A")
                        : moment(row.createdAt)
                            .local()
                            .format("YYYY-MMM-DD h:mm A")}
                    </TableCell>
                    <TableCell className={classes.date} >
                      {!row.exit_or_restart && row.completed_date
                        ? moment(row.completed_date)
                            .local()
                            .format("YYYY-MMM-DD h:mm A")
                        : "------"}
                    </TableCell>
                    <TableCell className={classes.date} >
                      {row.sent_for_review_date
                        ? moment(row.sent_for_review_date)
                            .local()
                            .format("YYYY-MMM-DD h:mm A")
                        : "------"}
                    </TableCell>
                    <TableCell className={classes.txtAlign} >
                      {row.status === "PASS" || row.status === "NO PASS"
                        ? row.review_status
                          ? "PASS"
                          : "NO PASS"
                        : "------"}
                    </TableCell>
                    <TableCell >
                      {row.status === "PASS" || row.status === "NO PASS"
                        ? moment(row.reviewed_date)
                            .local()
                            .format("YYYY-MMM-DD h:mm A")
                        : "------"}
                    </TableCell>
                    <TableCell >
                      {row.status === "PASS" || row.status === "NO PASS"
                        ? row.reviewed_by
                        : "------"}
                    </TableCell>
                    {!permissionCheck(
                      DashboardPermissions.assessor_notes.update.task,
                      DashboardPermissions.assessor_notes.update.type,
                    ) &&
                    !permissionCheck(
                      DashboardPermissions.assessor_notes.create.task,
                      DashboardPermissions.assessor_notes.create.type,
                    ) &&
                    permissionCheck(
                      DashboardPermissions.assessor_notes.view.task,
                      DashboardPermissions.assessor_notes.view.type,
                    ) ? (
                      <TableCell >
                        <div
                          onClickCapture={() => openDialog(row)}
                          className={classes.assessor}
                          style={{
                            ...(row.assessor_notes
                              ? {
                                  borderRadius: "5%",
                                  background: "inherit",
                                  color: "#636AD1",
                                }
                              : {}),
                          }}
                          
                        >
                          {row.assessor_notes ? "view" : null}
                        </div>
                      </TableCell>
                    ) : permissionCheck(
                        DashboardPermissions.assessor_notes.update.task,
                        DashboardPermissions.assessor_notes.update.type,
                      ) ||
                      permissionCheck(
                        DashboardPermissions.assessor_notes.create.task,
                        DashboardPermissions.assessor_notes.create.type,
                      ) ? (
                      <TableCell >
                        <div
                          onClickCapture={() => openDialog(row)}
                          className={classes.assessor}
                          style={{
                            ...(row.assessor_notes
                              ? {
                                  borderRadius: "5%",
                                  background: "inherit",
                                  color: "#636AD1",
                                }
                              : {}),
                          }}
                          
                        >
                          {row.assessor_notes ? "view" : "+"}
                        </div>
                      </TableCell>
                    ) : null}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div >
            <div className={classes.paginationLabel} >
              Showing {(sortPage - 1) * 10 + 1} to{" "}
              {(sortPage - 1) * 10 +
                (scoreList && scoreList.length ? scoreList.length : 0)}{" "}
              of {totalScore || 0} entries
            </div>
            <Pagination
              className={classes.pagination}
              color="primary"
              count={totalScore ? Math.ceil(totalScore / 10) : 0}
              shape="rounded"
              page={sortPage}
              onChange={handleSortPageChange}
              
            />
          </div>
        </Paper>
      )}
      <Dialog
        onClose={handleClose}
        open={assessor}
        classes={{ paper: classes.dialog }}
        fullWidth
        maxWidth="sm"
        
      >
        <DialogTitle >Assessor Notes</DialogTitle>
        <DialogContent >
          <div
            style={{ padding: "0.5rem", width: "27vw", paddingLeft: 2 }}
            
          >
            <TextField
              InputProps={{
                disableUnderline: true,
                style: { fontSize: 14, paddingLeft: 8 },
              }}
              fullWidth
              multiline
              rows={5}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className={classes.textArea}
              placeholder="Type your note here"
              disabled={
                !permissionCheck(
                  DashboardPermissions.assessor_notes.update.task,
                  DashboardPermissions.assessor_notes.update.type,
                ) &&
                !permissionCheck(
                  DashboardPermissions.assessor_notes.create.task,
                  DashboardPermissions.assessor_notes.create.type,
                ) &&
                permissionCheck(
                  DashboardPermissions.assessor_notes.view.task,
                  DashboardPermissions.assessor_notes.view.type,
                )
              }
              
            />

            <Button
              size="small"
              style={{
                fontWeight: 600,
                padding: "3px 11px",
                backgroundColor: "#F8DEE7",
                float: "right",
                color: "#A55B73",
                margin: "8px 0px",
              }}
              onClick={handleClose}
              
            >
              Cancel
            </Button>
            {noteStatus === "edit" ? (
              <Tooltip
                title={
                  !permissionCheck(
                    DashboardPermissions.assessor_notes.update.task,
                    DashboardPermissions.assessor_notes.update.type,
                  )
                    ? "You don't have permission to update"
                    : ""
                }
                placement="top"
                
              >
                <span className={classes.editSpan} >
                  <Button
                    size="small"
                    className={classes.button}
                    disabled={
                      !permissionCheck(
                        DashboardPermissions.assessor_notes.update.task,
                        DashboardPermissions.assessor_notes.update.type,
                      )
                    }
                    onClick={handleEdit}
                    
                  >
                    Edit
                  </Button>
                </span>
              </Tooltip>
            ) : noteStatus === "add" ? (
              <Tooltip
                title={
                  !permissionCheck(
                    DashboardPermissions.assessor_notes.create.task,
                    DashboardPermissions.assessor_notes.create.type,
                  )
                    ? "You don't have permission to create"
                    : ""
                }
                placement="top"
                
              >
                <span className={classes.editSpan} >
                  <Button
                    size="small"
                    className={classes.button}
                    disabled={
                      !permissionCheck(
                        DashboardPermissions.assessor_notes.create.task,
                        DashboardPermissions.assessor_notes.create.type,
                      )
                    }
                    onClick={handleAdd}
                    
                  >
                    Add
                  </Button>
                </span>
              </Tooltip>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
      {!assignment_loading && scoreList.length === 0 ? (
        <div className={classes.noData} >
          <img
            src={noAssignments}
            height={55}
            width={54}
            alt="noAssignments"
            
          />

          <h3 className={classes.noAssign} >
            There are no Assignments.
          </h3>
        </div>
      ) : null}
    </>
  );
};
ScoreTable.propTypes = {
  scoreList: PropTypes.array,
  totalScore: PropTypes.number,
  handleScore: PropTypes.func,
  assignment_loading: PropTypes.bool,
  sorting: PropTypes.func,
  sortBy: PropTypes.string,
  sort: PropTypes.string,
  handleSortPageChange: PropTypes.func,
  sortPage: PropTypes.number,
};

export default ScoreTable;
