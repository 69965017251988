import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import { saveWrapSeconds } from '../../redux/sims/actions';

const WrapUpTimer = ({ saveWrapSeconds }) => {
    useEffect(() => {
        const startTime = new Moment();
        return () => {
            const endTime = new Moment();
            const duration = Moment.duration(endTime.diff(startTime));
            saveWrapSeconds(duration.asSeconds());
        };
    }, [saveWrapSeconds]);
    return (
        <div>
            {}
        </div>
    );
};

WrapUpTimer.propTypes = {
    saveWrapSeconds: PropTypes.func,
};

const mapDispatchToProps = {
    saveWrapSeconds,
};
export default connect(null, mapDispatchToProps)(WrapUpTimer);
