const sims = {
    sim_list: {
        view: {
            task: 'Sim Admin access',
            type: 'view',
        },
        create: {
            task: 'Sim Admin access',
            type: 'create',
        },
        update: {
            task: 'Sim Admin access',
            type: 'update',
        },
        delete: {
            task: 'Sim Admin access',
            type: 'delete',
        },
    },
    assignment: {
        view: {
            task: 'Sim Assignment access',
            type: 'view',
        },
        create: {
            task: 'Sim Assignment access',
            type: 'create',
        },
        delete: {
            task: 'Sim Assignment access',
            type: 'delete',
        },
    },
    download_video: {
        view: {
            task: 'Download Sim Screen Recording Button', // This has to be the EXACT SAME string as the name field of collection permission_tasks
            type: 'view',
        },/*
        create: {
            task: 'Download Sim Screen Recording Button',
            type: 'create',
        },
        delete: {
            task: 'Download Sim Screen Recording Button',
            type: 'delete',
        },*/
    }
};
export default sims;
